import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import style from './index.module.scss';
import { CloseOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Form, Button, Divider, Watermark } from 'antd';
import { state } from '@/store';

import UnderLineInput from '../../../../../../component/Input/UnderLineInput/underLineInput';

function PreviewPopup({
  handBackToList,
  waterMarkStatus,
  type,
  previewObj,
}: {
  handBackToList: () => void;
  waterMarkStatus?: string;
  type: string;
  previewObj: any;
}) {
  return (
    <div className={style['container']}>
      <div className={style.closeButton}>
        <CloseOutlined
          style={{ fontSize: 'var(--text-font13)', color: 'var(--background1)' }}
          onClick={() => {
            handBackToList();
          }}
        />
      </div>
      {type === '1' && (
        <div className={style.loginPopup} style={{ backgroundColor: 'var(--background1)' }}>
          <div className="lz_login-container" style={{ backgroundImage: `url(${previewObj.backgroundPic})` }}>
            <div className="lz_login-wel" style={{ backgroundImage: `url(${previewObj.loginTitle})` }}></div>
            <div className="lz_login-card">
              <div className="lz_login-logo" style={{ backgroundImage: `url(${previewObj.loginLogo})` }}></div>
              <div className="lz_login-title">登录</div>
              <Form initialValues={{ remember: true }} style={{ width: '336px', marginTop: '32px' }}>
                <Form.Item>
                  <UnderLineInput
                    isPrevent={true}
                    editFinish={(e: any) => e.preventDefault()}
                    placeholder="请输入账号"
                    prefix={<UserOutlined />}
                    label="username"
                    isLoginPage={true}
                  />
                </Form.Item>
                <Form.Item>
                  <UnderLineInput isPrevent={true} placeholder="请输入密码" prefix={<LockOutlined />} isLoginPage={true} />
                </Form.Item>
                <Form.Item name="remember" valuePropName="checked">
                  <div className="lz_login-remember-container">
                    <div></div>
                    <Button type="link" className="lz_login-forget-button">
                      忘记密码?
                    </Button>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div>
                    <Button type="primary" block className="lz_login-button">
                      登录
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
            {previewObj.brandStatus === '1' && (
              <Divider className="lz_login-provider-divider" plain style={{ color: 'var(--text-font-color4)' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ color: 'var(--text-font-color1)' }}>技术支持·陆泽科技有限公司</div>
                </div>
              </Divider>
            )}
            {previewObj.recordStatus === '1' && (
              <div className="lz_login-industrial">
                <span>{previewObj.recordCode}</span>
              </div>
            )}
          </div>
        </div>
      )}
      {type === '2' && (
        <div className={style.loginPopup}>
          {(() => {
            const content = (
              <div className={style['ground-container']}>
                <img src={previewObj.groundLogo} className={style.groundLogo} />
                <span className={style.groundTitle}>Hi，xxx，欢迎使用{previewObj.groundTitle}</span>
              </div>
            );

            return waterMarkStatus === '0' ? (
              <Watermark content={[state.user.userName, '保密信息，请勿外传']} font={{ fontSize: 14 }} gap={[200, 150]} zIndex={112}>
                {content}
              </Watermark>
            ) : (
              content
            );
          })()}
        </div>
      )}
    </div>
  );
}

export default PreviewPopup;
