import React, { useState, useEffect } from 'react';
import { Watermark } from 'antd';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import busEvent from "@/utils/bus";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

(window as any).DOWNLOAD_SUCCESS_TEXT = '导出中，请到任务中心查看进度'

const RootComponent: React.FC = () => {
  const [userName, setUserName] = useState([]);
  useEffect(() => {
    const handleUserInfo = (data: any) => {
      setUserName(data);
    };
    busEvent.on('userInfo', handleUserInfo);
    // 清理事件监听器
    return () => {
      busEvent.off('userInfo', handleUserInfo);
    };
  }, []);

  return (
    <div>
      {userName.length > 0 && userName[0] && userName[0] !== '' ? (
        <Watermark content={userName} font={{ fontSize: 14 }} gap={[200, 150]} zIndex={112}>
          <App />
        </Watermark>
      ) : (
        <App />
      )}
    </div>
  );
};

root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();