import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Input, Tree, Button, Table, Select, Dropdown, Space, message, Popconfirm, Alert, Modal, Skeleton, notification, Switch, Tooltip } from 'antd';
import UnderLineInput from '../../../../component/Input/UnderLineInput/underLineInput';
import {
    FilterOutlined, CopyOutlined, EllipsisOutlined
} from '@ant-design/icons';
import CreateAccountDrawer from './component/CreateAccount';
import FilterView from '../../../../component/FilterView/filterView';

import type { TreeDataNode } from 'antd';
import type { TableColumnsType } from 'antd';
import type { NotificationArgsProps } from 'antd';
import ButtonPermissions from '../../../../component/Button/SystemButton';
import { SYS_KEY } from '../../../../router/Permissions';
import { motion } from 'framer-motion';

import busEvent from '../../../../utils/bus';
import { removeElementByKey } from '../../../../utils/tools';

import { DeptVO } from '../../../../api/dept/types';
import { getDeptTreeApi, editDeptApi, addDeptApi, addDeptVisAVis, deptMoveApi, removeApi } from '../../../../api/dept/dept';
import { changeStatus, getUserListApi, resetPwd, deleteAccountApi } from '../../../../api/account/account';
import { checkHasRoleApi } from '../../../../api/role/role';
import { getUserGroupListApi } from "../../../../api/group/group";
import { getUserDetailApi } from "../../../../api/auth/auth";

// import './index.css'
import style from './index.module.scss'
import { AccountQuery, AccountVO } from '../../../../api/account/types';
import { AccountSourceEnum, AccountSourceTextEnum, AccountStatusEnum, AccountStatusTextEnum } from '../../../../enums/AccountStatusEnum';

import SearchIconInput from '../../../../component/Input/SearchIconInput/searchIconInput';
import { state } from '../../../../store';
import { checkPermissions } from '@/utils/utils';
import copyToClipboard from '@/crm/assets/public/clipboard';
import { getTableScroll } from '@/store/index';

const { Search } = Input;
const defaultData: TreeDataNode[] = [];
const { TreeNode } = Tree

const pageQuery: PageQuery = {
    pageSize: 10,
    pageNum: 0
}

const sortValue: { orderByColumn: string, isAsc: string } = {
    orderByColumn: '',
    isAsc: ''
}



let deptConstList = [] as DeptVO[]

let inputType = '';//输入框类型 add添加部门  edit编辑部门名称
let oldDept = '';//修改之前的部门名
let currentSelectKey = ''//新增部门 新部门临时key
let addParentId = ''//新增部门父id
let pos = ''//当前选中层级
let targetNodeId = ''//添加部门的目标部门
const AccountManagement = () => {
    const [userGroupList, setUserGroupList] = useState([] as any);
    //搜索条件
    const [serchData, setSerchData] = useState({} as any);
    //获取所有用户组
    const getUserGroupList = async () => {
        const res = await getUserGroupListApi()
        if (res) {
            const list = res.data
            list.map(item => {
                item.label = item.name;
                item.value = item.id;
            });
            setUserGroupList(list)
        }
    };

    const [userId, setUserId] = useState(0 as number)
    const [selectedStatus, setSelectedStatus] = useState(999 as any)

    const columns: TableColumnsType<AccountVO> = [
        {
            title: '账号ID',
            dataIndex: 'userId',
            key: 'userId',
            width: 100,
            fixed: 'left',
        }, {
            title: '账号名称',
            dataIndex: 'userName',
            key: 'userName',
            width: 127,
            fixed: 'left',
            render: (_: any, record: any) => (
                <div className={style['userName']} onClick={() => checkAccount(record, 'CHECK')}>{record.userName}</div>
            )
        }, {
            title: '姓名',
            dataIndex: 'nickName',
            key: 'nickName',
            width: 127
        }, {
            title: '账号状态',
            dataIndex: 'status',
            key: 'status',
            width: 127,
            render: (_: any, record: any) => (
                <div className={style[`accountStatus`]}>
                    {/* <Select
                        disabled={record.userId === 1}
                        value={getAccountStatus(parseInt(_))}
                        style={{ width: '60px', height: '22px' }}
                        className={style[`accountStatusType${_}`]}
                        onSelect={(value, options) => { handleAccountStatusOnSelect(value, record); }}
                        options={[
                            { value: AccountStatusEnum.NORMAL, label: '启用', disabled: (_ === '0') || (selectedStatus === 0 && userId === record.userId) },
                            { value: AccountStatusEnum.LOCKED, label: '锁定', disabled: true },
                            { value: AccountStatusEnum.DISABLE, label: '停用', disabled: (_ === '1') || (selectedStatus === 1 && userId === record.userId) || record.userId == 1 },
                        ]}
                    /> */}
                    {parseInt(_) === AccountStatusEnum.NORMAL && <Switch checkedChildren="启用" unCheckedChildren={parseInt(_) === AccountStatusEnum.DISABLE ? '停用' : '锁定'} value={parseInt(_) === AccountStatusEnum.NORMAL} disabled={parseInt(_) === AccountStatusEnum.LOCKED || !checkPermissions(SYS_KEY.USER_EDIT)} onChange={(checked: boolean) => { handleAccountStatusOnSelect(parseInt(_) === AccountStatusEnum.NORMAL ? AccountStatusEnum.DISABLE : AccountStatusEnum.NORMAL, record) }} />}
                    {parseInt(_) !== AccountStatusEnum.NORMAL && <Switch checkedChildren="启用" unCheckedChildren={parseInt(_) === AccountStatusEnum.DISABLE ? '停用' : '锁定'} value={parseInt(_) === AccountStatusEnum.NORMAL} disabled={parseInt(_) === AccountStatusEnum.LOCKED || !checkPermissions(SYS_KEY.USER_EDIT)} onChange={(checked: boolean) => { handleAccountStatusOnSelect(parseInt(_) === AccountStatusEnum.NORMAL ? AccountStatusEnum.DISABLE : AccountStatusEnum.NORMAL, record) }} />}
                    {/* <Switch checkedChildren="启用" unCheckedChildren={parseInt(_) === AccountStatusEnum.DISABLE ? '停用' : '锁定'} value={parseInt(_) === AccountStatusEnum.NORMAL} disabled={parseInt(_) === AccountStatusEnum.LOCKED || !checkPermissions(SYS_KEY.USER_EDIT)} onChange={(checked: boolean) => { handleAccountStatusOnSelect(parseInt(_) === AccountStatusEnum.NORMAL ? AccountStatusEnum.DISABLE : AccountStatusEnum.NORMAL, record) }} /> */}
                    <Popconfirm
                        placement="top"
                        title={tableConfig.title}
                        okText={tableConfig.okText}
                        cancelText={tableConfig.cancelText}
                        description={tableConfig.description}
                        onConfirm={() => handleAccountStatusChange(tablePopStatus, record)}
                        onCancel={handleCancelClickPop}
                        open={selectedTableKey === record.userId && tablePopConfirm}
                        onPopupClick={(e) => {
                            e.stopPropagation();
                        }}
                        okButtonProps={{ loading: false }}
                    ></Popconfirm>
                </div>
            )
        }, {
            title: '所属组织',
            dataIndex: 'deptName',
            key: 'deptName',
            width: 127,
        },
        // {
        //     title: '所属品牌',
        //     dataIndex: 'brandName',
        //     key: 'brandName',
        //     width: 250,
        //     render: (_: any, record: any) => (
        //         <Tooltip title={_} placement="topLeft">
        //             <span className='line-text-two'>{_}</span>
        //         </Tooltip>
        //     )
        // }, 
        {
            title: '所属店铺',
            dataIndex: 'shopName',
            key: 'shopName',
            width: 250,
            // ellipsis: true,
            render: (_: any, record: any) => (
                <Tooltip title={_} placement="topLeft">
                    <span className='line-text-two'>{_}</span>
                </Tooltip>
            )
        }, 
        // {
        //     title: '所属产品线',
        //     dataIndex: 'productLineName',
        //     key: 'productLineName',
        //     width: 250,
        //     render: (_: any, record: any) => (
        //         <Tooltip title={_} placement="topLeft">
        //             <span className='line-text-two'>{_}</span>
        //         </Tooltip>
        //     )
        // }, 
        {
            title: '所属用户组',
            dataIndex: 'userGroupName',
            key: 'userGroupName',
            width: 250,
            render: (_: any, record: any) => (
                <Tooltip title={_} placement="topLeft">
                    <span className='line-text-two'>{_}</span>
                </Tooltip>
            )
        }, {
            title: '功能角色',
            dataIndex: 'role',
            key: 'role',
            width: 250,
            render: (_: any, record: any) => (
                <Tooltip title={_} placement="topLeft">
                    <span className='line-text-two'>{_}</span>
                </Tooltip>
            )
        }, {
            title: '账号来源',
            dataIndex: 'accountSource',
            key: 'accountSource',
            width: 127,
            render: (_: any, record: any) => (
                <Tooltip title={getAccountSource(parseInt(_))} placement="topLeft">
                    <span className='line-text-two'>{getAccountSource(parseInt(_))}</span>
                </Tooltip>
            )
        }, {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 200,
            sorter: true,
        }, {
            title: '更新时间',
            dataIndex: 'updateTime',
            key: 'updateTime',
            width: 200,
            sorter: true,
        }, {
            title: '最近登录时间',
            dataIndex: 'lastLoginTime',
            key: 'loginDate',
            width: 200,
            sorter: true,

        }, {
            title: '手机号',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: 127,
        }, {
            title: '邮箱',
            dataIndex: 'email',
            key: 'email',
            width: 177,
        }, {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 88,
            align: 'center',
            render: (_: any, render: AccountVO) => (
                <Space>
                    {render.userId !== 1 && <Dropdown menu={{ items: getTableOperationButtonList(render) }} trigger={['click']}>
                        <Popconfirm
                            placement="right"
                            title={tableConfig.title}
                            okText={tableConfig.okText}
                            cancelText={tableConfig.cancelText}
                            description={tableConfig.description}
                            onConfirm={() => handleConfirmClick(render, tableConfig.type)}
                            onCancel={() => handleCancelClick(render, tableConfig.type)}
                            open={selectedTableKey === render.userId && resetConfirm}
                            onPopupClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <div
                                onMouseLeave={handleMouseLeavePoint}
                                onMouseEnter={() => handleMouseEnterPoint(render)}
                                className={style[`line-three-point`]}
                                style={{ backgroundColor: checkUserId == render.userId ? iconColorPointBack : '' }}
                            >
                                <EllipsisOutlined
                                    style={{
                                        color: checkUserId == render.userId ? 'var(--text-font-color33)' : 'var(--text-font-color34)',
                                        cursor: 'pointer'
                                    }}
                                />
                            </div>

                        </Popconfirm>

                    </Dropdown>}

                </Space >
            )
        }
    ]

    const textRef: any = useRef(null);

    const deptNameRef: any = useRef(null);

    const createAccountDrawerRef: any = useRef(null);
    const filterViewRef: any = useRef(null)
    const [deptSearchText, setDeptSearchText] = useState('')
    //组织集合
    const [deptTreeData, setDeptTreeData] = useState(defaultData)
    //已展开树节点
    const [hasExpanedKeys, setHasExpanedKeys] = useState([] as string[])
    //已选择树节点
    const [hasSelectedKeys, setSelectedKeys] = useState([] as any[])
    //是否在编辑节点
    const [isEditing, setIsEditing] = useState(false)
    //当前选择部门名称
    const [currentSelectName, setCurrentSelectName] = useState('')
    //部门操作框是否显示
    const [deptOperationShow, setDeptOperationShow] = useState(false)
    const [dropKey, setDropKey] = useState(null as unknown as number)
    //菜单是否收回
    const [thin, setThin] = useState(false)

    //警告是否显示
    const [alertShow, setAlertShow] = useState(false)

    //表单数据
    const [accountList, setAccountList] = useState([] as AccountVO[])
    const [accountTotalCount, setAccountTotalCount] = useState(0)
    //列表中 修改账号状态
    const [tablePopConfirm, setTablePopConfirm] = useState(false)
    const [tablePopStatus, setTablePopStatus] = useState(0)

    const [operationType, setOperationType] = useState("")

    const [selectedTableKey, setSelectedTableKey] = useState(null as unknown as number)
    const [resetConfirm, setResetConfirm] = useState(false)

    const [tableConfig, setTableConfig] = useState({} as any)

    const [firstLoad, setFirstLoad] = useState(false)

    const [currentHoverTreeKey, setCurrentHoverTreeKey] = useState('')

    const searchList: searchItem[] = [
        {
            searchType: 'text',
            searchComponentType: 'input',
            placeHolder: '请输入账号名称或者账号ID',
            title: '账号名称/ID',
            key: 'userIdOrName'
        }, {
            searchType: 'text',
            searchComponentType: 'input',
            placeHolder: '请输入手机号',
            title: '手机号',
            key: 'phoneNumber'
        }, {
            searchType: 'number',
            searchComponentType: 'select',
            placeHolder: '请选择',
            title: '账号状态',
            key: 'status',
            options: [{
                value: AccountStatusEnum.NORMAL,
                label: AccountStatusTextEnum.NORMAL_TEXT
            }, {
                value: AccountStatusEnum.DISABLE,
                label: AccountStatusTextEnum.DISABLE_TEXT
            }, {
                value: AccountStatusEnum.LOCKED,
                label: AccountStatusTextEnum.LOCKED_TEXT
            }]
        }, {
            searchType: 'number',
            searchComponentType: 'select',
            placeHolder: '请选择',
            title: '账号来源',
            key: 'accountSource',
            options: [{
                value: AccountSourceEnum.MANUAL,
                label: AccountSourceTextEnum.MANUAL_TEXT
            }, {
                value: AccountSourceEnum.THIRDPART,
                label: AccountSourceTextEnum.THIRDPART_TEXT
            }, {
                value: AccountSourceEnum.EXPORT,
                label: AccountSourceTextEnum.EXPORT_TEXT
            }]
        }, {
            searchType: 'number',
            searchComponentType: 'select',
            placeHolder: '请选择',
            title: '所属用户组',
            key: 'userGroupId',
            options: userGroupList
        }, {
            searchType: 'text',
            searchComponentType: 'dateRange',
            placeHolder: '',
            title: '创建时间',
            key: 'createTimeStart-createTimeEnd'
        }
    ]


    //创建用户
    const createAccount = async () => {
        setOperationType('CREATE')
        const res = await checkHasRoleApi()
        if (res && res.code == 200) {
            createAccountDrawerRef.current?.createDrawerOpen(hasSelectedKeys)
            // 
        } else if (res.code == 500) {
            setAlertShow(true)
        }
    }

    //展开菜单
    const arrowSubMenu = (e: boolean) => {
        setThin(!e)
    }
    useEffect(() => {
        console.log(state.permissions);

        busEvent.on('subMenuArrow', arrowSubMenu)
        busEvent.on('updateZHList', loadData)
        //获取部门列表
        getDeptTree()
        getUserGroupList()
        pageQuery.pageNum = 1;
        sortValue.orderByColumn = '';
        sortValue.isAsc = '';
        loadData()

        // setFirstLoad(false)
        document.addEventListener('click', hidden)

        return () => {
            document.removeEventListener('click', hidden)
        }
    }, [])

    const hidden = () => {
        setResetConfirm(false)
        setDropKey(null as unknown as number)
    }


    const loadData = () => {
        getUserList('', '', {})
    }

    //获取部门列表
    const getDeptTree = async (key?: string) => {
        let { data } = await getDeptTreeApi();
        data = setTreeData(data, [], 0, '0').data
        setHasExpanedKeys(setTreeData(data, [], 0, '0').expanedKeys)
        setDeptTreeData(data)
        if (key) {
            setSelectedKeys([key])
            getUserList(key, '', {})
        }
        deptConstList = JSON.parse(JSON.stringify(data))
    }

    //处理部门列表数据
    const setTreeData = (treeData: DeptVO[], expanedKeys: string[], floor: number, position: string) => {
        treeData.map((item, index) => {
            item.key = item.id
            item.title = item.label || ''
            item.floor = floor
            item.index = index
            item.sameFloorCount = treeData.length
            item.position = `${position}-${index}`
            expanedKeys.push(item.key.toString())
            if (item.children && item.children.length > 0) {
                item.children = setTreeData(item.children, expanedKeys, floor + 1, item.position).data
            } else {
                item.children = []
            }
        })
        return { data: treeData, expanedKeys }
    }

    //展开/收起部门
    const handleExpand = (expandedKeys: any) => {
        setHasExpanedKeys(expandedKeys)
    }
    //点击搜索按钮
    const onSearch = (e: string) => {
        if (e && e !== '') {
            setDeptTreeData(checkTreeItem(deptConstList, e, []).data)
            setHasExpanedKeys(checkTreeItem(deptConstList, e, []).expanedKeys)
        } else {
            setHasExpanedKeys(setTreeData(deptConstList, [], 0, '0').expanedKeys)
            setDeptTreeData(setTreeData(deptConstList, [], 0, '0').data)
        }
    }
    //构造搜索后的树
    const checkTreeItem = (list: DeptVO[], str: string, expanedKeys: string[]) => {
        let arr = [] as DeptVO[]
        const deptArr = JSON.parse(JSON.stringify(list)) as DeptVO[];
        deptArr.map(item => {
            let childrenArr = [] as DeptVO[]
            if (item.children && item.children.length > 0) {
                childrenArr = checkTreeItem(item.children, str, expanedKeys).data
            }
            if (!(item.title.indexOf(str) === -1 && childrenArr.length === 0)) {
                item.children = childrenArr
                arr.push(item)
                expanedKeys.push(item.key.toString())
            }
        })
        return { data: arr || [], expanedKeys }
    }
    //部门后加...按钮
    const renderTreeNodes = (data: any) => {
        return data.map((node: any) => {
            const { key, title, children, position } = node;
            const opertion = (
                <Space style={{ position: 'absolute', right: '12px' }}>
                    <Dropdown open={key === dropKey} menu={{ items: getOperationList(node) }} trigger={['click']} placement='bottom'>
                        <Popconfirm
                            placement="right"
                            title='确定删除此部门么？'
                            okText="确定"
                            cancelText="取消"
                            onConfirm={onConfirm}
                            onCancel={onCancel}
                            open={hasSelectedKeys[0] && key.toString() === hasSelectedKeys[0].toString() && deptOperationShow || false}
                        >
                            <a onClick={(e) => {

                            }}>

                                <Space onClick={(e) => { setDropKey(key); handleTreeSelect([key], { node: { key: key, pos: `0-${position}` } }); e.stopPropagation() }}>
                                    <EllipsisOutlined style={{ color: 'var(--text-font-color3)', opacity: '0.45' }} />
                                </Space>
                            </a>
                        </Popconfirm>
                    </Dropdown>
                </Space>
            );
            const inputBox = (
                <UnderLineInput ref={deptNameRef} placeholder='输入部门名' lostBlur={lostBlur} handleEdit={((e: string) => { setCurrentSelectName(e) })} editFinish={editFinish} value={currentSelectName}></UnderLineInput>
            )

            if (children && children.length > 0) {
                return (
                    <TreeNode key={key} title={<span
                        onMouseLeave={() => { setCurrentHoverTreeKey('') }}
                        onMouseEnter={() => setCurrentHoverTreeKey(key.toString())}
                        style={{ display: 'flex', flex: 1 }}
                    >
                        {isEditing && key.toString() === hasSelectedKeys[0].toString() ? inputBox : <Tooltip title={title}><span className='line-text' style={{ width: node.floor === 0 ? '110px' : node.floor === 1 ? '98px' : node.floor === 2 ? '86px' : node.floor == 3 ? '74px' : '62px' }}>{title}</span></Tooltip>}
                        {!isEditing && getOperationList(node).length > 0 && (currentHoverTreeKey === key.toString() || (hasSelectedKeys[0] && key.toString() === hasSelectedKeys[0].toString() && deptOperationShow) || dropKey === key) && opertion}</span>}>
                        {renderTreeNodes(children)}
                    </TreeNode>
                );
            }

            return <TreeNode key={key} title={
                <span
                    style={{ display: 'flex', flex: 1 }}
                    onMouseLeave={() => { setCurrentHoverTreeKey('') }}
                    onMouseEnter={() => setCurrentHoverTreeKey(key.toString())}>
                    {isEditing && getOperationList(node).length > 0 && key.toString() === hasSelectedKeys[0].toString() ? inputBox : <Tooltip title={title}><span className='line-text' style={{ width: node.floor === 0 ? '110px' : node.floor === 1 ? '98px' : node.floor === 2 ? '86px' : node.floor == 3 ? '74px' : '62px' }} title={title}>{title}</span></Tooltip>}
                    {!isEditing && (currentHoverTreeKey === key.toString() || (hasSelectedKeys[0] && key.toString() === hasSelectedKeys[0].toString() && deptOperationShow) || dropKey === key) && opertion}</span>} />;
        });
    };
    //输入框编辑结束
    const editFinish = async (str: string) => {
        if (str === '') {
            message.error({
                content: '请输入部门名称'
            })
            return
        }
        if (inputType === 'edit') {
            const id = operationDeptData(deptTreeData as DeptVO[], 'get', hasSelectedKeys[0], '', []).id
            const param = { deptId: id, deptName: str, id, key: id, title: '' } as DeptVO
            const res = await editDeptApi(param)
            if (res) {
                setCurrentSelectName('')
                inputType = ''
                operationDeptData(deptTreeData as DeptVO[], 'edit', hasSelectedKeys[0], str, ['label', 'title'])
                setIsEditing(false)
                deptConstList = deptTreeData as DeptVO[]

            }
        } else if (inputType === 'add') {
            const parentId = addParentId;
            const deptName = str;
            const param = { parentId, deptName, id: '', key: '', title: '', children: [] } as DeptVO
            const res = await addDeptApi(param)
            if (res) {
                getDeptTree(res.data.toString())
                setDeptSearchText('')
                setIsEditing(false)
                inputType = ''
                // setSelectedKeys()
            }
        } else if (inputType.indexOf('add') !== -1) {
            const parentId = addParentId;
            const deptName = str;
            const deptId = targetNodeId
            const moveType = inputType.indexOf('Up') !== -1 ? 0 : 1
            const param = { moveType, deptId, parentId, deptName, id: '', key: '', title: '', children: [], } as DeptVO
            const res = await addDeptVisAVis(param)
            if (res) {
                operationDeptData(deptTreeData as DeptVO[], 'edit', hasSelectedKeys[0], str, ['label', 'title'])
                operationDeptData(deptTreeData as DeptVO[], 'edit', hasSelectedKeys[0], res.data, ['id', 'key'])
                setIsEditing(false)
                inputType = ''
                const data = setTreeData(deptTreeData as DeptVO[], [], 0, '0').data
                setDeptTreeData(data)
                deptConstList = data
            }
        }
    }
    //修改或获取部门节点对象
    const operationDeptData = (deptArr: DeptVO[], type: string, key: React.Key, value: string, keyString: string[]) => {
        if (type === 'get') {
            let deptItem = null as DeptVO | any
            deptArr.map(item => {
                if (item.key.toString() === key.toString()) {
                    deptItem = item as DeptVO
                } else if (item.children && item.children.length > 0 && !deptItem) {
                    deptItem = operationDeptData(item.children, type, key, value, keyString)
                }
            })
            return deptItem
        } else if (type === 'edit') {
            deptArr.map(item => {
                if (item.key == key) {
                    const a = item as any
                    keyString.map(keystr => {
                        a[keystr] = value
                    })
                    item = a;
                }
                if (item.children && item.children.length > 0) {
                    operationDeptData(item.children, type, key, value, keyString)
                }
            })
        } else if (type.indexOf('add') != -1) {
            deptArr.map(item => {
                if (item.key.toString() === key.toString()) {
                    const deptItem = {} as DeptVO
                    deptItem.key = Math.floor(100000 + 200000 * Math.random())
                    deptItem.children = []
                    currentSelectKey = deptItem.key.toString()
                    deptItem.title = ''

                    const indexList = pos.split('-')
                    if (type === 'add') {
                        item.children?.push(deptItem)
                    } else if (type === 'addUp') {
                        item.children?.splice(Number(indexList[indexList.length - 1]), 0, deptItem)
                    } else if (type === 'addDown') {
                        item.children?.splice(Number(indexList[indexList.length - 1]) + 1, 0, deptItem)
                    }
                } else if (item.children && item.children.length > 0) {
                    operationDeptData(item.children, type, key, value, keyString)
                }
            })
        } else if (type === 'delete') {
            setDeptTreeData(removeElementByKey(deptTreeData, currentSelectKey.toString()))
            setIsEditing(false)
        }
    }

    //选择树节点
    const handleTreeSelect = (selectedKeys: any, e: any) => {
        if (!isEditing && inputType.indexOf('add') === -1) {
            pos = e.node.pos
            setSelectedKeys([e.node.key])
            getUserList(e.node.key.toString(), '', {})
        }

    }


    //部门编辑/添加失去节点
    const lostBlur = () => {
        if (inputType === 'edit') {
            operationDeptData(deptTreeData as DeptVO[], 'edit', hasSelectedKeys[0], oldDept, ['label', 'title'])
            setIsEditing(false)
        } else if (inputType.indexOf('add') !== -1) {
            operationDeptData(deptTreeData as DeptVO[], 'delete', hasSelectedKeys[0], '', [])
            setIsEditing(false)
        }
        inputType = ''
    }

    //添加子部门
    const addDept = (key: string) => {
        setDeptOperationShow(false)
        inputType = 'add'
        setCurrentSelectName('')
        addParentId = key;
        operationDeptData(deptTreeData as DeptVO[], 'add', addParentId, '', []);
        setDeptTreeData(deptTreeData)
        setSelectedKeys([currentSelectKey])
        setIsEditing(true)
        setTimeout(() => {
            deptNameRef.current!.getFocus()
        }, 10);
    }

    //指定位置添加部门
    const assignAddDept = (type: string) => {
        setDeptOperationShow(false)
        inputType = `add${type}`
        const indexList = pos.split('-')
        let arr = deptTreeData as DeptVO[]
        let parentObj = {} as DeptVO
        for (let i = 2; i < indexList.length - 1; i++) {
            parentObj = getDeptByIndex(arr as DeptVO[], Number(indexList[i]))
            arr = parentObj.children as DeptVO[]
            console.log(parentObj);
            console.log(arr);


        }
        targetNodeId = arr[Number(indexList[indexList.length - 1])].key.toString()
        setCurrentSelectName('')
        addParentId = parentObj.key.toString()
        operationDeptData(deptTreeData as DeptVO[], inputType, addParentId, '', [])
        setDeptTreeData(deptTreeData)
        setSelectedKeys([currentSelectKey])
        setIsEditing(true)
        setTimeout(() => {
            deptNameRef.current!.getFocus()
        }, 10);
    }
    //根据下标获取部门
    const getDeptByIndex = (arr: DeptVO[], index: number) => {
        return arr[index] as DeptVO
    }

    //上移下移部门
    const move = async (type: string) => {
        setDeptOperationShow(false)

        const indexList = pos.split('-');
        const moveType = type === 'up' ? 0 : 1
        let arr = deptTreeData as DeptVO[]
        let parentObj = {} as DeptVO
        for (let i = 2; i < indexList.length - 1; i++) {
            parentObj = getDeptByIndex(arr as DeptVO[], Number(indexList[i]))
            arr = parentObj.children as DeptVO[]
        }
        targetNodeId = arr[Number(indexList[indexList.length - 1])].key.toString()
        const param = { moveType, deptId: targetNodeId, id: '', key: '', title: '', children: [], } as DeptVO
        const res = await deptMoveApi(param);
        if (res) {
            message.success(type === 'up' ? '上移成功' : '下移成功');
            const targetIndex = arr[Number(indexList[indexList.length - 1])].index as number
            const anotherIndex = type === 'up' ? targetIndex - 1 : targetIndex + 1
            const a = arr[anotherIndex]
            arr[anotherIndex] = arr[targetIndex]
            arr[targetIndex] = a

            const data = setTreeData(deptTreeData as DeptVO[], [], 0, '0').data
            setDeptTreeData([])
            setTimeout(() => {
                setDeptTreeData(data)
            }, 10);
            deptConstList = data
        }

    }

    const getOperationList = (node: any) => {
        if (node.floor === 0) {
            if (checkPermissions(SYS_KEY.USER_ADD)) {
                return [
                    { key: 'add', label: (<a onClick={() => { addDept(node.key) }}>添加子部门</a>) },
                ]
            } else {
                return []
            }
        } else {
            let operationList = []

            if (checkPermissions(SYS_KEY.USER_EDIT)) {
                operationList.push({
                    key: 'edit', label: (<a onClick={(e) => {
                        setDeptOperationShow(false);
                        setSelectedKeys([node.key]);
                        setCurrentSelectName(operationDeptData(deptTreeData as DeptVO[], 'get', node.key, '', [])?.title || '');
                        oldDept = operationDeptData(deptTreeData as DeptVO[], 'get', node.key, '', [])?.title || '';
                        setIsEditing(true);
                        setTimeout(() => {
                            deptNameRef.current!.getFocus()
                        }, 10); inputType = 'edit'
                    }} >编辑</a>)
                })
            }
            if (checkPermissions(SYS_KEY.USER_ADD)) {
                if (node.floor !== 3) {
                    operationList.push({ key: 'add', label: (<a onClick={() => { addDept(node.key) }}>添加子部门</a>) },)
                }
                operationList.push({ key: 'addPeerDown', label: (<a onClick={() => { assignAddDept('Down') }}>下方添加部门</a>) })
                operationList.push({ key: 'addPeerUp', label: (<a onClick={() => { assignAddDept('Up') }}>上方添加部门</a>) })
            }
            if (node.index !== 0 && checkPermissions(SYS_KEY.USER_EDIT)) {
                operationList.push({ key: 'up', label: (<a onClick={() => { move('up') }}>上移</a>) })
            }
            if (node.index !== node.sameFloorCount - 1 && checkPermissions(SYS_KEY.USER_EDIT)) {
                operationList.push({ key: 'down', label: (<a onClick={() => { move('down') }}>下移</a>) },)
            }
            if (checkPermissions(SYS_KEY.USER_REMOVE)) {
                operationList.push({
                    key: 'delete', label: (
                        <a onClick={() => { setDeptOperationShow(true) }}>删除</a>

                    )
                })
            }
            return operationList
        }

    }

    const onConfirm = async () => {
        setDeptOperationShow(false)
        const indexList = pos.split('-');
        let arr = deptTreeData as DeptVO[]
        let parentObj = {} as DeptVO
        for (let i = 1; i < indexList.length - 1; i++) {
            parentObj = getDeptByIndex(arr as DeptVO[], Number(indexList[i]))
            arr = parentObj.children as DeptVO[]
        }
        targetNodeId = arr[Number(indexList[indexList.length - 1])].key.toString()
        const res = await removeApi(targetNodeId)
        if (res) {
            arr.splice(Number(indexList[indexList.length - 1]), 1)
            const data = setTreeData(deptTreeData as DeptVO[], [], 0, '0').data
            setDeptTreeData([])
            setTimeout(() => {
                setDeptTreeData(data)
            }, 10);
            deptConstList = data
        }
    }
    const onCancel = () => {
        setDeptOperationShow(false)
    }

    //获取用户列表
    const getUserList = async (str: string, userIdOrName: string, data: any) => {
        const param = {
            deptId: str || (hasSelectedKeys[0] ? hasSelectedKeys[0].toString() : ''),
            userIdOrName,
            pageSize: pageQuery.pageSize,
            pageNum: pageQuery.pageNum,
            ...data,
            orderByColumn: sortValue.orderByColumn,
            isAsc: sortValue.isAsc,
        } as AccountQuery
        const res = await getUserListApi(param);
        if (res) {
            const { rows, total } = res
            rows.map((item, index) => {
                item.key = index
            })
            setScrollY(getTableScroll())
            setAccountList([...rows])
            setAccountTotalCount(total)
        }
        setFirstLoad(false)
    }

    //获取账号状态
    const getAccountStatus = (status: number) => {
        switch (status) {
            case AccountStatusEnum.NORMAL:
                return AccountStatusTextEnum.NORMAL_TEXT
            case AccountStatusEnum.LOCKED:
                return AccountStatusTextEnum.LOCKED_TEXT
            case AccountStatusEnum.DISABLE:
                return AccountStatusTextEnum.DISABLE_TEXT
            default:
                break;
        }
    }

    const getAccountSource = (source: number) => {
        switch (source) {
            case AccountSourceEnum.MANUAL:
                return AccountSourceTextEnum.MANUAL_TEXT
            case AccountSourceEnum.THIRDPART:
                return AccountSourceTextEnum.THIRDPART_TEXT
            case AccountSourceEnum.EXPORT:
                return AccountSourceTextEnum.EXPORT_TEXT
            default:
                break;
        }
    }
    //列表中账号状态选择
    const handleAccountStatusOnSelect = async (value: any, item: any) => {
        setTablePopConfirm(true)
        const userId = accountList[item.key].userId;
        console.log(item, 'item');

        setSelectedTableKey(userId)
        var text = value == 1 ? 's' : value == 0 ? 'a' : 'un'
        setTablePopStatus(value)
        setTableConfirmText(text);
    }
    //列表中二次确认框 取消之后
    const handleCancelClickPop = () => {
        setTablePopConfirm(false)
        setSelectedTableKey(null as unknown as number)
    }
    //防连点
    const [clickStatus, setClickStatus] = useState(false);
    //列表中二次确认框 确认之后
    const handleAccountStatusChange = async (value: any, item: any) => {
        if (clickStatus) {
            return
        }
        setClickStatus(true)
        const userId = accountList[item.key].userId;
        const param = {
            userId,
            status: value
        }
        const res = await changeStatus(param)
        if (res) {
            accountList[item.key].status = value
            const list = [...accountList]
            setAccountList(list)
            setUserId(item.userId);
            setSelectedStatus(value);
            handleCancelClickPop()
        }
        setSelectedTableKey(null as unknown as number)
        setClickStatus(false)
    }
    const pageChange = (page: any, pageSize: any, sorter: any) => {
        pageQuery.pageNum = page.current
        pageQuery.pageSize = page.pageSize

        if (!sorter.order) {
            sortValue.orderByColumn = '';
            sortValue.isAsc = '';
        } else {
            sortValue.orderByColumn = sorter.columnKey;
            sortValue.isAsc = sorter.order === 'ascend' ? 'asc' : 'desc';
        }
        getUserList('', '', serchData)
    }

    const checkAccount = async (item: AccountVO, type: string) => {
        setOperationType(type)
        if (type == 'CHECK' || type == 'EDIT') {
            const res = await getUserDetailApi(item.userId)
            if (res) {
                createAccountDrawerRef.current?.editDrawerOpen(res.data, type)
            }
        } else {
            createAccountDrawerRef.current?.editDrawerOpen(item, type)
        }
    }

    const handleConfirmClick = (item: AccountVO, type: string) => {
        switch (type) {
            case 'r':
                resetPassword(item)
                break;
            case 's':
                handleAccountStatusChange(AccountStatusEnum.DISABLE, item)
                break;
            case 'a':
                handleAccountStatusChange(AccountStatusEnum.NORMAL, item)
                break;
            case 'u':
                resetPassword(item)
                break;
            case 'd':
                deleteAccount(item)
                break;
            default:
                break;
        }
    }

    const handleCancelClick = (item: AccountVO, type: string) => {
        if (type !== 'u') {
            setSelectedTableKey(null as unknown as number)
        } else {
            handleAccountStatusChange(AccountStatusEnum.NORMAL, item)
        }
    }

    //重置密码
    const resetPassword = async (item: AccountVO) => {
        if (clickStatus) {
            return
        }
        setClickStatus(true)
        const res = await resetPwd(item.userId)
        if (res) {
            if (tableConfig.type === 'u') {
                handleAccountStatusChange(AccountStatusEnum.NORMAL, item)
            }
            Modal.success({
                title: '密码重置成功！',
                content: (
                    <div className={style["success-content"]}>
                        <div className={style["success-line"]}>
                            <span className={style["success-title"]}>账号名称</span>{item.userName}
                        </div>
                        <div className={style["success-line"]}>
                            <span className={style["success-title"]}>新密码</span><p ref={textRef} style={{ margin: 0 }}>{res.msg}</p>
                            <CopyOutlined style={{ marginLeft: 20 }} onClick={handleCopyClick} className='click-element' />
                        </div>
                    </div>
                ),
            });
            setSelectedTableKey(null as unknown as number)
            setClickStatus(false)
        }
        setClickStatus(false)
    }

    //停用
    const stopAccount = async (item: AccountVO) => {

    }

    // 删除          
    const deleteAccount = async (item: AccountVO) => {
        const res = await deleteAccountApi(item.userId);
        if (res) {
            message.success('删除成功');
            getUserList('', '', {})
        }
    };

    const getTableOperationButtonList = (item: AccountVO) => {
        if (item.userId === 1) {
            return []
        }
        let tableOperationButtonList = [
            { key: 'check', label: (<a onClick={() => { checkAccount(item, 'CHECK') }}>查看</a>) },
        ]

        if (checkPermissions(SYS_KEY.USER_EDIT)) {
            tableOperationButtonList.push({ key: 'edit', label: (<a onClick={() => { checkAccount(item, 'EDIT') }}>编辑</a>) })

            switch (Number(item.status)) {
                case AccountStatusEnum.NORMAL:
                    tableOperationButtonList.push({ key: 'stop', label: (<a onClick={(e) => { setSelectedTableKey(item.userId); setTableConfirmText('s'); setResetConfirm(true); e.nativeEvent.stopPropagation(); }}>停用</a>) })
                    break;
                case AccountStatusEnum.DISABLE:
                    tableOperationButtonList.push({ key: 'start', label: (<a onClick={(e) => { setSelectedTableKey(item.userId); setTableConfirmText('a'); setResetConfirm(true); e.nativeEvent.stopPropagation(); }}>启用</a>) })
                    break;
                case AccountStatusEnum.LOCKED:
                    tableOperationButtonList.push({ key: 'unlock', label: (<a onClick={(e) => { setSelectedTableKey(item.userId); setTableConfirmText('u'); setResetConfirm(true); e.nativeEvent.stopPropagation(); }}>解锁</a>) })
                    break;
                default:
                    break;
            }
        }

        if (checkPermissions(SYS_KEY.USER_PWD)) {
            if (Number(item.status) === AccountStatusEnum.NORMAL || Number(item.status) === AccountStatusEnum.DISABLE) {
                tableOperationButtonList.push({
                    key: 'reset', label: (
                        <a onClick={(e) => { setSelectedTableKey(item.userId); setTableConfirmText('r'); setResetConfirm(true); e.nativeEvent.stopPropagation(); }}>重置密码</a>
                    )
                })
            }
        }

        if (Number(item.status) === AccountStatusEnum.DISABLE && checkPermissions(SYS_KEY.USER_REMOVE)) {
            tableOperationButtonList.push({
                key: 'delete', label: (
                    <a onClick={(e) => { setSelectedTableKey(item.userId); setTableConfirmText('d'); setResetConfirm(true); e.nativeEvent.stopPropagation(); }}>删除</a>
                )
            })
        }

        return tableOperationButtonList

    }

    //设置标特操作提示框文案
    const setTableConfirmText = (type: string) => {
        switch (type) {
            case 'r':
                setTableConfig({
                    title: '确定重置此账号的密码么？',
                    okText: "确定",
                    cancelText: "取消",
                    description: "",
                    type
                })
                break;
            case 's':
                setTableConfig({
                    title: '确定停用此账号？',
                    okText: "确定",
                    cancelText: "取消",
                    description: "",
                    type
                })
                break;
            case 'a':
                setTableConfig({
                    title: '确定启用此账号？',
                    okText: "确定",
                    cancelText: "取消",
                    description: "",
                    type
                })
                break;
            case 'u':
                setTableConfig({
                    title: '确定解除此账号的锁定状态吗？',
                    okText: "解锁并重置密码",
                    cancelText: "确定解锁",
                    description: "",
                    type
                })
                break;
            case 'un':
                setTableConfig({
                    title: '确定锁定此账号吗？',
                    okText: "确定",
                    cancelText: "取消",
                    description: "",
                    type
                })
                break;
            case 'd':
                setTableConfig({
                    title: '确定删除此账号？',
                    okText: "确定",
                    cancelText: "取消",
                    description: "",
                    type
                })
                break;
            default:
                break;
        }
    }

    const handleCopyClick = () => {
        const textToCopy = textRef.current!.textContent;
        if (textToCopy) {
            copyToClipboard(textToCopy).then(() => {
                // notification.info({
                //     message: '密码复制成功',
                // });
                message.success("复制成功");
            })
                .catch((error) => {
                    console.error('复制失败', error);
                });
            // navigator.clipboard.writeText(textToCopy)
            //     .then(() => {
            //         notification.info({
            //             message: '密码复制成功',
            //         });
            //         console.log('复制成功');
            //     })
            //     .catch((error) => {
            //         console.error('复制失败', error);
            //     });
        }
    };

    const filterFinish = (data: any) => {
        getUserList('', '', data)
        setSerchData(data)
    }
    //过滤器
    const [iconColor, setIconColor] = useState('var(--text-font-color4)');
    const handleMouseEnter = () => {
        // console.log('鼠标移入')
        setIconColor('var(--text-font-color9)');
    };
    const handleMouseLeave = () => {
        // console.log('鼠标移出')
        setIconColor('var(--text-font-color4)');
    };
    //操作中三个点
    const [iconColorPointBack, setIconColorPointBack] = useState('');
    const [checkUserId, setCheckUserId] = useState('' as any);
    const handleMouseEnterPoint = (i: any) => {
        setCheckUserId(i.userId)
        setIconColorPointBack('var(--text-font-color35)');
    };
    const handleMouseLeavePoint = () => {
        setCheckUserId('')
        setIconColorPointBack('');
    };
    const [scrollY, setScrollY] = useState("")
    const getHeight = (e: string) => {
        console.log(e);
        setScrollY(e)
    }
    return (
        <>
            <div className={style['AccountManagement_container']}>
                <div className={style['AccountManagement_left-side']}>
                    <Search placeholder="请输入部门名称"
                        value={deptSearchText} onChange={(e) => setDeptSearchText(e.target.value)}
                        onSearch={onSearch}
                        style={{ width: '164px', marginTop: '24px' }}
                    />
                    <Skeleton loading={firstLoad}>
                        <Tree defaultExpandAll={true}
                            style={{ width: '164px', marginTop: '16px' }}
                            onSelect={handleTreeSelect}
                            selectedKeys={hasSelectedKeys}
                            expandedKeys={hasExpanedKeys}
                            onExpand={handleExpand}>
                            {renderTreeNodes(deptTreeData)}
                        </Tree>
                    </Skeleton>

                </div>
                <motion.div animate={{ minWidth: `calc(1166px - 64px - ${!thin ? '152' : '16'}px)`, width: `calc(100vw - 64px - ${!thin ? '152' : '16'}px)` }}>
                    <div className={style['AccountManagement_header']} style={{ width: '100%' }}>
                        <div className={'Page_header-title'}>账号管理</div>
                        <div className={style['AccountManagement_header-action']}>
                            <SearchIconInput
                                onSearch={(e: string) => { getUserList('', e, {}); filterViewRef.current?.resetData(); }}
                                placeholder="请输入账号名称/ID/手机号"></SearchIconInput>
                            {/* <div style={{ width: 32, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '8px', marginRight: '8px', background: 'var(--background5)', borderRadius: 'var(--border-radius5)' }}> */}
                            <Tooltip placement="bottom" title={'过滤器'}>
                                <Button
                                    onClick={() => filterViewRef.current?.showFilterView()}
                                    icon={
                                        <FilterOutlined
                                            style={{
                                                color: iconColor,
                                                fontSize: 'var(--text-font7)',
                                            }}
                                        />
                                    }
                                    type="text"
                                    className="click-element"
                                    style={{ marginRight: '10px' }}
                                    onMouseLeave={handleMouseLeave}
                                    onMouseEnter={handleMouseEnter}></Button>
                            </Tooltip>
                            {/* </div> */}
                            <span style={{ width: '0', height: '16px', border: 'var(--border3)' }}></span>
                            <ButtonPermissions
                                style={{ marginLeft: '16px' }}
                                type="primary"
                                onClick={createAccount}
                                title={`新建账号`}
                                permissions={SYS_KEY.USER_ADD}
                            ></ButtonPermissions>
                        </div>
                    </div>
                    <div className={style['AccountManagement_right-side']} >
                        {alertShow && <Alert
                            banner
                            message="还未创建角色，请先创建角色"
                            type="warning"
                            onClose={() => { setAlertShow(false) }}
                            action={
                                <Space>
                                    <Button type="text" size="small" ghost>
                                        前往角色管理
                                    </Button>
                                </Space>
                            }
                            style={{ marginBottom: '20px' }}
                            closable
                        />}



                        <FilterView getHeight={getHeight} ref={filterViewRef} searchList={searchList} finish={filterFinish}></FilterView>
                        <Skeleton title={false} loading={firstLoad}>
                            <Table
                                style={{ width: '100%' }}
                                columns={columns} dataSource={accountList}
                                scroll={{ x: 1800, y: scrollY }}
                                pagination={{
                                    total: accountTotalCount,
                                    showTotal: (total) => `共${total}条`,
                                    pageSize: pageQuery.pageSize,
                                    showSizeChanger: true,
                                }}
                                onChange={(page, pageSize, sort) => pageChange(page, pageSize, sort)}
                            />
                        </Skeleton>
                    </div>
                </motion.div>
            </div>
            <CreateAccountDrawer ref={createAccountDrawerRef} createSuccess={loadData} type={operationType} />
            {/* <Modal.success title="密码重置成功!" open={resetSuccess} >

            </Modal.success> */}
        </>
    )
}

export default AccountManagement
