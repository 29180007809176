import { useLocation, Route, Routes, Navigate } from 'react-router-dom';
import routerMap from './routerMap';
import LZLayout from '../pages/base/Layout';
import { state, setMenu, setUserInfo, setUserDetail, setStyleStoreData, setJumpLink, setRfmName } from '../store';
import { useEffect } from 'react';
import { getUserInfoApi, getPersonalInfo, getConfigKeyApi } from '../api/auth/auth';
import { getRoutersApi } from '../api/router/router';
import { getSysVersionAppearanceApi } from '../api/userIntersection/userIntersection';
import busEvent from '../utils/bus'
// import tempLuZhiBIRouter from './tempLuZhiBIRouter';

const FrontendAuth = () => {
  const { pathname } = useLocation();
  const targetRouterConfig = routerMap.find(item => item.path === pathname);
  useEffect(() => {
    if ((!state.menu || state.menu.length === 0) && targetRouterConfig?.auth) {
      // getUserInfo();
      getSysVersionAppearance();
    }
  }, [pathname]);
  useEffect(() => {
    busEvent.on('styleStoreData', getSysVersionAppearance)
  }, [])

  const getUserInfo = async (waterMarkContent: string, waterMarkStatus: string) => {
    const userInfo = await getUserInfoApi();
    if (userInfo) {
      const userDetail = await getPersonalInfo();
      if (userDetail) {
        setUserDetail(userDetail.data);
      }
      setUserInfo(userInfo.data);
      getRouters();
      getConfigKey();
      getConfigKeyRmf();
      if (waterMarkStatus === '0') {
        busEvent.emit('userInfo', [userInfo.data.user.userName, waterMarkContent]);
      } else {
        busEvent.emit('userInfo', []);
      }
      // setMenu(menuList);
    }
  };

  // 获取header上面帮助中心icon的跳转链接
  const getConfigKey = async () => {
    const res = await getConfigKeyApi('lz.user.help');
    console.log(res)
    if (res.code === 200) {
      setJumpLink(res.data);
    }
  };

  // 更改menu左上的logo，需要更新getUserInfo
  const getSysVersionAppearance = async () => {
    const res = await getSysVersionAppearanceApi();
    if (res.code === 200) {
      setStyleStoreData(res.data);
      getUserInfo(res.data.waterMarkContent, res.data.waterMarkStatus);
    }
  };

  const getConfigKeyRmf = async () => {
    const res = await getConfigKeyApi('lz.rmf.name ');
    console.log(res)
    if (res.code === 200) {
      setRfmName(res.data.toString())
    }
  }

  const getRouters = async () => {
    const { data } = await getRoutersApi();
    // data.splice(1, 0, tempLuZhiBIRouter);
    setMenu(data)
  };

  //如果路由配置项中直接跳转到对应页面
  if (targetRouterConfig && !targetRouterConfig?.layout) {
    return (
      <Routes>
        <Route path={pathname} element={targetRouterConfig?.component} />
      </Routes>
    );
  }

  if (targetRouterConfig) {
    if (pathname === '/') {
      return (
        <Routes>
          {/* 导致header渲染了两次,于是注掉了 */}
          {/* <Route path="/" element={<LZLayout />}> */}
          <Route index element={targetRouterConfig.component} />
          {/* </Route> */}
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route path="/" element={<LZLayout />}>
            <Route path={pathname} element={targetRouterConfig.component} />
          </Route>
        </Routes>
      );
    }
  }
  // 如果路由不合法，重定向到 404 页面
  return (
    <Routes>
      <Route path="*" element={<div>404</div>} />
    </Routes>
  );
};
export default FrontendAuth;
