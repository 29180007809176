import React, { ReactNode, useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import style from './index.module.scss'
import { Input, Modal, Popover, Tooltip, message } from "antd"; //Progress
import { setUserDetail, state, setUserInfo } from '../../../../../store';
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { getPersonalInfo, outLoginApi, resetUserName } from "../../../../../api/auth/auth";
import busEvent from '../../../../../utils/bus'
import { CaretDownOutlined, LogoutOutlined } from "@ant-design/icons";
import headerImage from '../../../../../assets/images/headerImage.png';

let UserCenterInfo = forwardRef((props: {
}, ref: any) => {
    const [detail, setDetail] = useState(state.detail)
    const navigate = useNavigate();

    // 退出登录
    const outLogin = async () => {
        const res = await outLoginApi()
        if (res.code === 200) {
            navigate('/login')
            busEvent.emit('userInfo', [])
            const updatedUserInfo: any = {
                permissions:{...state.permissions},
                roles:{
                  ...state.roles,
                },
                user: {
                  ...state.user,
                  userName: ''
                }
            };
            setUserInfo(updatedUserInfo)
        }
    }

    const infoStyle = {
        color: 'var(--text-font-color3)',
        borderRadius: '0',
        border: 'var(--border9)',
    }
    // 展开品牌
    const [showBrandsText, setShowBrandsText] = useState(true)
    const showOtherBrands = () => {
        return (<>
            {detail.brandName.length > 1 && <div className={style['userInfo_div']}>
                <Tooltip placement="left" title={detail.brandName[0]} >
                    <div className={style['userInfo_title']}>{detail.brandName[0]}</div>
                </Tooltip>
                <div className={style['userInfo_tip']} onClick={() => { setShowBrandsText(!showBrandsText) }}>
                    {showBrandsText && <span className={style['userInfo_text']}>等{detail.brandName.length}个 </span>}
                    {!showBrandsText && <span className={style['userInfo_text'] + ' ' + style['retract']}>收起</span>}
                    <motion.span className={style['clickBtn']} style={showBrandsText ? {} : {}} animate={{ rotate: showBrandsText ? 0 : 180 }} whileTap={{ rotate: 180 }}>
                        <span className={style['userInfo_btn']}><CaretDownOutlined /></span>
                    </motion.span>
                </div>
            </div>}
            {!showBrandsText && detail.brandName.length > 1 && <>
                {detail.brandName.slice(1, detail.brandName.length).map((item: any, index: number) => {
                    return <div className={style['userInfo_div']} >
                        <Tooltip placement="left" title={item} >
                            <div key={index} style={{ marginRight: '67px' }} className={style['userInfo_title']}>{item}</div>
                        </Tooltip>
                    </div>
                })}
            </>}
        </>)
    }
    // 展开店铺
    const [showShopsText, setShowShopsText] = useState(true)
    const showOtherShops = () => {
        return (<>
            {detail.shopName.length > 1 && <div className={style['userInfo_div']}>
                <Tooltip placement="left" title={detail.shopName[0]} >
                    <div className={style['userInfo_title']}>{detail.shopName[0]}</div>
                </Tooltip>
                <div className={style['userInfo_tip']} onClick={() => { setShowShopsText(!showShopsText) }}>
                    {showShopsText && <span className={style['userInfo_text']}>等{detail.shopName.length}家 </span>}
                    {!showShopsText && <span className={style['userInfo_text'] + ' ' + style['retract']}>收起</span>}
                    <motion.span className={style['clickBtn']} style={showShopsText ? {} : {}} animate={{ rotate: showShopsText ? 0 : 180 }} whileTap={{ rotate: 180 }}><span className={style['userInfo_btn']}><CaretDownOutlined /></span></motion.span>
                </div>
            </div>}
            {!showShopsText && detail.shopName.length > 1 && <>
                {detail.shopName.slice(1, detail.shopName.length).map((item: any, index: number) => {
                    return <div className={style['userInfo_div']} >
                        <Tooltip placement="left" title={item} >
                            <div key={index} style={{ marginRight: '67px' }} className={style['userInfo_title']}>{item}</div>
                        </Tooltip>
                    </div>
                })}
            </>}
        </>)
    }
    // 展开生产线
    const [showUserProductLineText, setUserProductLineText] = useState(true)
    const showOtherUserProductLine = () => {
        return (<>
            {detail.productLineName.length > 1 && <div className={style['userInfo_div']}>
                <Tooltip placement="left" title={detail.productLineName[0]} >
                    <div className={style['userInfo_title']}>{detail.productLineName[0]}</div>
                </Tooltip>
                <div className={style['userInfo_tip']} onClick={() => { setUserProductLineText(!showUserProductLineText) }}>
                    {showUserProductLineText && <span className={style['userInfo_text']}>等{detail.productLineName.length}条 </span>}
                    {!showUserProductLineText && <span className={style['userInfo_text'] + ' ' + style['retract']}>收起</span>}
                    <motion.span className={style['clickBtn']} style={showUserProductLineText ? {} : {}} animate={{ rotate: showUserProductLineText ? 0 : 180 }} whileTap={{ rotate: 180 }}>
                        <span className={style['userInfo_btn']}><CaretDownOutlined /></span>
                    </motion.span>
                </div>
            </div>}
            {!showUserProductLineText && detail.productLineName.length > 1 && <>
                {detail.productLineName.slice(1, detail.productLineName.length).map((item: any, index: number) => {
                    return <div className={style['userInfo_div']} >
                        <Tooltip placement="left" title={item} >
                            <div key={index} style={{ marginRight: '67px' }} className={style['userInfo_title']}>{item}</div>
                        </Tooltip>
                    </div>
                })}
            </>}
        </>)
    }
    // 展开用户组
    const [showUserGroupText, setUserGroupText] = useState(true)
    const showOtherUserGroup = () => {
        return (<>
            {detail.userGroupName.length > 1 && <div className={style['userInfo_div']}>
                <Tooltip placement="left" title={detail.userGroupName[0]} >
                    <div className={style['userInfo_title']}>{detail.userGroupName[0]}</div>
                </Tooltip>
                <div className={style['userInfo_tip']} onClick={() => { setUserGroupText(!showUserGroupText) }}>
                    {showUserGroupText && <span className={style['userInfo_text']}>等{detail.userGroupName.length}个 </span>}
                    {!showUserGroupText && <span className={style['userInfo_text'] + ' ' + style['retract']}>收起</span>}
                    <motion.span className={style['clickBtn']} style={showUserGroupText ? {} : {}} animate={{ rotate: showUserGroupText ? 0 : 180 }} whileTap={{ rotate: 180 }}><span className={style['userInfo_btn']}><CaretDownOutlined /></span></motion.span>
                </div>
            </div>}
            {!showUserGroupText && detail.userGroupName.length > 1 && <>
                {detail.userGroupName.slice(1, detail.userGroupName.length).map((item: any, index: number) => {
                    return <div className={style['userInfo_div']} >
                        <Tooltip placement="left" title={item} >
                            <div key={index} style={{ marginRight: '67px' }} className={style['userInfo_title']}>{item}</div>
                        </Tooltip>
                    </div>
                })}
            </>}
        </>)
    }
    // 展开角色
    const [showRolesText, setRolesText] = useState(true)
    const showOtherRoles = () => {
        return (<>
            {detail.roleName.length > 1 && <div className={style['userInfo_div']}>
                <Tooltip placement="left" title={detail.roleName[0]} >
                    <div className={style['userInfo_title']}>{detail.roleName[0]}</div>
                </Tooltip>
                <div className={style['userInfo_tip']} onClick={() => { setRolesText(!showRolesText) }}>
                    {showRolesText && <span className={style['userInfo_text']}>等{detail.roleName.length}个 </span>}
                    {!showRolesText && <span className={style['userInfo_text'] + ' ' + style['retract']}>收起</span>}
                    <motion.span className={style['clickBtn']} style={showRolesText ? {} : {}} animate={{ rotate: showRolesText ? 0 : 180 }} whileTap={{ rotate: 180 }}><span className={style['userInfo_btn']}><CaretDownOutlined /></span></motion.span>
                </div>
            </div>}
            {!showRolesText && detail.roleName.length > 1 && <>
                {detail.roleName.slice(1, detail.roleName.length).map((item: any, index: number) => {
                    return <div className={style['userInfo_div']} >
                        <Tooltip placement="left" title={item} >
                            <div key={index} style={{ marginRight: '67px' }} className={style['userInfo_title']}>{item}</div>
                        </Tooltip>
                    </div>
                })}
            </>}
        </>)
    }

    // 个人中心修改框 value
    const [inputValue, setInputValue] = useState(detail.nickName);
    // 个人中心修改框
    const [changeName, setChangeName] = useState(false)
    // 禁止输入空格
    const handleKeyDown = (event: any) => {
        if (event.keyCode === 32) {
            event.preventDefault();
        }
    }
    const editValue = (e: any) => {
        setInputValue(e.target.value)
    }
    const [oldName, setOldName] = useState(inputValue)
    const [editStatus, setEditStatus] = useState(false)
    const checkValue = async () => {
        if (editStatus) return
        if (!inputValue) {
            message.error('请输入姓名')
            return
        }
        if (inputValue.length > 20) {
            message.error('姓名长度不能超过20个字符')
            return
        }
        if (inputValue === oldName) {
            setChangeName(false)
            setEditStatus(false)
            return
        }
        setEditStatus(true)
        const res = await resetUserName({ nickName: inputValue })
        if (res.code === 200) {
            detail.nickName = inputValue
            message.success('修改成功')
            setChangeName(false)
            setOldName(inputValue)
            updateDetail()
            busEvent.emit('updateZHList')
        }
        setEditStatus(false)
    }

    const updateDetail = async () => {
        const userDetail = await getPersonalInfo()
        if (userDetail) {
            setUserDetail(userDetail.data)
            setDetail(userDetail.data)
        }
    }

    const content = () => {
        return (<>
            {detail.userName &&
                <div style={{ overflow: 'hidden' }}>
                    <div className={style['userInfo_content']}>
                        <p>
                            <span className={style['lz_info_lableName']}>账号名称</span>
                            <span className={style['lz_info_content']}>{detail.userName}</span>
                        </p>
                        <p>
                            <span className={style['lz_info_lableName']}>登录密码</span>
                            <span className={style['lz_info_content']}>
                                <a onClick={() => { navigate(`/userInfoEdit?type=${'reset'}&username=${encodeURIComponent(detail.userName)}`) }}>修改密码</a>
                            </span>
                        </p>
                        <p>
                            <span className={style['lz_info_lableName']}>姓名</span>
                            <span className={style['lz_info_content']}>
                                {!changeName && <>
                                    {detail.nickName}
                                    <a className={style['userInfo_content_btn']} onClick={() => { setChangeName(true); setInputValue(detail.nickName) }}>修改</a>
                                </>}
                                {changeName &&
                                    <Input onPressEnter={checkValue} style={{ width: '75%' }} count={{ show: true, max: 20 }} placeholder={inputValue} value={inputValue} onChange={editValue} onKeyDown={handleKeyDown} onBlur={checkValue} />
                                }
                            </span>
                        </p>
                        <p>
                            <span className={style['lz_info_lableName']}>所属组织</span>
                            <span className={style['lz_info_content']}>
                                {!detail.deptName ? '--' : detail.deptName}
                            </span>
                        </p>
                        
                        {
                            // <p>
                            //   <span className={style['lz_info_lableName']}>所属品牌</span>
                            //   <span className={style['lz_info_content']}>
                            //       {(!detail.brandName || detail.brandName.length < 1) && '--'}
                            //       {detail.brandName.length == 1 && <Tooltip placement="left" title={detail.brandName[0]} >
                            //           <span className={style['userInfo_title']}>{detail.brandName[0]}</span>
                            //       </Tooltip>}
                            //       {showOtherBrands()}
                            //   </span>
                            // </p>
                            <p>
                              <span className={style['lz_info_lableName']}>所属店铺</span>
                              <span className={style['lz_info_content']}>
                                  {(!detail.shopName || detail.shopName.length < 1) && '--'}
                                  {detail.shopName.length == 1 && <Tooltip placement="left" title={detail.shopName[0]} >
                                      <span className={style['userInfo_title']}>{detail.shopName[0]}</span>
                                  </Tooltip>}
                                  {showOtherShops()}
                              </span>
                            </p>
                        // <p>
                        //     <span className={style['lz_info_lableName']}>所属产品线</span>
                        //     <span className={style['lz_info_content']}>
                        //         {(!detail.productLineName || detail.productLineName.length < 1) && '--'}
                        //         {detail.productLineName.length == 1 && <Tooltip placement="left" title={detail.productLineName[0]} >
                        //             <span className={style['userInfo_title']}>{detail.productLineName[0]}</span>
                        //         </Tooltip>}
                        //         {showOtherUserProductLine()}
                        //     </span>
                        // </p>
                        }
                        
                        
                        <p>
                            <span className={style['lz_info_lableName']}>手机号</span>
                            <span className={style['lz_info_content']}>
                                {!detail.phone &&
                                    <a onClick={() => { navigate(`/userInfoEdit?type=${'bindPhone'}&username=${encodeURIComponent(detail.userName)}`) }}>绑定手机号</a>
                                }
                                {detail.phone && <>{detail.phone} <a className={style['userInfo_content_btn']} onClick={() => { navigate(`/userInfoEdit?type=${'editPhone'}&username=${encodeURIComponent(detail.userName)}`) }}>修改</a></>}
                            </span>
                        </p>
                        <p>
                            <span className={style['lz_info_lableName']}>邮箱</span>
                            <span className={style['lz_info_content']}>
                                {!detail.email && <a onClick={() => { navigate(`/userInfoEdit?type=${'bindEmail'}&username=${encodeURIComponent(detail.userName)}`) }}>绑定邮箱</a>}
                                {detail.email && <>{detail.email} <a className={style['userInfo_content_btn']} onClick={() => { navigate(`/userInfoEdit?type=${'editEmail'}&username=${encodeURIComponent(detail.userName)}`) }}>修改</a></>}
                            </span>
                        </p>
                        <p>
                            <span className={style['lz_info_lableName']}>所属用户组</span>
                            <span className={style['lz_info_content']}>
                                {(!detail.userGroupName || detail.userGroupName.length < 1) && '--'}
                                {detail.userGroupName.length == 1 && <Tooltip placement="left" title={detail.userGroupName[0]} >
                                    <span className={style['userInfo_title']}>{detail.userGroupName[0]}</span>
                                </Tooltip>}
                                {showOtherUserGroup()}
                            </span>
                        </p>
                        <p>
                            <span className={style['lz_info_lableName']}>功能角色</span>
                            <span className={style['lz_info_content']}>
                                {(!detail.roleName || detail.roleName.length < 1) && '--'}
                                {detail.roleName.length == 1 && <Tooltip placement="left" title={detail.roleName[0]} >
                                    <span className={style['userInfo_title']}>{detail.roleName[0]}</span>
                                </Tooltip>}
                                {showOtherRoles()}
                            </span>
                        </p>
                    </div>
                </div>}

            <div className={style['userInfo_logout']} style={detail.userName ? { borderTop: 'var(--border12)', paddingTop: '16px' } : {}}>
                <LogoutOutlined />
                <div style={{ marginLeft: '5px' }} onClick={() => { outLogin() }}>退出登录</div>
            </div>
        </>
        )
    }

    const handlePopoverVisibleChange = (visible: boolean) => {
        if (!visible) {
            // 处理鼠标移出的逻辑
            setChangeName(false)
            // 关闭所有展开
            setRolesText(true)
            setShowBrandsText(true)
            setShowShopsText(true)
            setUserProductLineText(true)
            setUserGroupText(true)

        }
    };

    // 暴露方法给父组件
    useImperativeHandle(ref, () => ({

    }))
    //监听或进入调用
    useEffect(() => {
        busEvent.on('updateUserDetail', () => {
            updateDetail()
        })
    }, [])

    return <>
        <Popover content={content} trigger="hover" arrow={false} onOpenChange={handlePopoverVisibleChange}>
            <div className="lzHeader_userInfo">
                {state.user.avatar && <img src={state.user.avatar} alt="" className='lzHeader_avatar' />}
                {!state.user.avatar && <img src={headerImage} alt="" className='lzHeader_avatar' />}
                <span className={style['lzHeader_username']}>{detail.userName}</span>
            </div>
        </Popover>
    </>
})
export default UserCenterInfo
