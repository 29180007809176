import React, { useState, useImperativeHandle, useEffect, useRef } from "react";
import style from './index.module.scss'
import { Tree, Space, Dropdown, Popconfirm, Empty, Input, message, Skeleton, Modal, Button, Tooltip } from "antd";
import { setPositionNumber } from "../../../../../../utils/tools";
import {
    FolderOpenOutlined, PlusOutlined, TagOutlined
} from '@ant-design/icons';
import { info } from "console";
import { addLabelCategoryApi, deleteCategoryApi, deleteLabelApi, moveLabelApi, queryListApi, updateCategoryNameApi } from "../../../../../../api/label/label";
import { getUserClickApi } from '../../../../../../api/home/home'
import { addCategoryQuery, categoryQuery, upDateCategoryNameQuery } from "../../../../../../api/label/types";
import { state } from "../../../../../../store";
import { checkPermissions } from "@/utils/utils";
import { LABEL_KEY } from "@/router/Permissions";
import AddCategoryDrawer from "../AddCategoryDrawer";
import _ from 'lodash'
import { useLocation } from 'react-router-dom';


const { TreeNode } = Tree;

let hasLoadNode: any[] = []

const treeData: any[] = []

let constData: any[] = []
let createStr = ''

let isDelete = false
let searchText = ''


let currentEditText = ''
let RuleTagTree = React.forwardRef((props: {
    changeCate?: Function,
    selectLabel?: Function,
    selectCatgory?: Function,
    addLabel?: Function,
    editLabel?: Function
    clearSearch?: Function,
    getLabelCount?: Function
}, ref: any) => {
    const [data, setData] = useState([] as any[])
    const [mouseOnKey, setMouseOnKey] = useState(null as unknown as number)
    const [confirmKey, setConfirmKey] = useState(null as unknown as number)
    const [dropKey, setDropKey] = useState(null as unknown as number)
    const [isEdit, setIsEdit] = useState(false)
    const [currentSelectKeys, setCurrentSelectKeys] = useState([] as any[])
    const [editType, setEditType] = useState(0) //1添加 2编辑
    // const [currentEditText, setCurrentEditText] = useState('')
    const [hasExpandedKeys, setHasExpandedKeys] = useState([] as any[])
    const [firstLoad, setFirstLoad] = useState(true)

    const [AddCategoryDrawerOpen, setAddCategoryDrawerOpen] = useState(false)
    const location = useLocation()

    const inputRef: any = useRef(null);
    const treeContainerRef = useRef<HTMLDivElement | null>(null);
    useImperativeHandle(ref, () => ({
        setTreeData,
        addCate,
        getCategoryList,
        searchLable
    }))

    const handleScroll = () => {
        console.log('滚动');

        hidden()
    }
    useEffect(() => {

        // console.log(hasExpandedKeys, 'hasExpandedKeys');
        // console.log(data, 'data');
        document.addEventListener('click', hidden)
        if (treeContainerRef.current) {
            treeContainerRef.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            document.removeEventListener('click', hidden)
            if (treeContainerRef.current) {
                treeContainerRef.current.removeEventListener('scroll', handleScroll);
            }
            // window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    const searchLable = (labelName: string, isDelete?: boolean) => {
        searchText = labelName
        if (!labelName) {
            setData(constData)
            setDraggable(true)
            return
        }
        const arr = [] as any[]
        const dataModel = JSON.parse(JSON.stringify(constData))
        console.log(dataModel);
        setDraggable(false)
        dataModel.map((t: any) => {
            let category = JSON.parse(JSON.stringify(t))
            if (t.children && t.children.length > 0) {
                let b = [] as any[]
                t.children.map((s: any) => {
                    let label = JSON.parse(JSON.stringify(s))
                    if (s.children && s.children.length > 0) {
                        let a = [] as any[]
                        s.children.map((m: any) => {
                            if (m.labelName.indexOf(labelName) > -1) {
                                a.push(m)
                            }
                        })

                        s.children = a.length > 0 ? a : []
                    }
                    if (s.children.length > 0) {
                        b.push(s)
                    }

                })
                t.children = b.length > 0 ? b : []
            }
            if (t.children.length > 0) {
                arr.push(t)
            }
        })
        arr.map((item: any, index: number) => {
            item.title = item.categoryName
            item.key = item.id
            item.floor = 0
            item.position = `${index}`
            // item.children = item.children || []
            item.icon = <FolderOpenOutlined style={{ marginRight: 8 }} />
            if (item.children.length > 0) {
                item.children.map((t: any, i: number) => {
                    t.title = t.categoryName
                    t.key = t.id
                    t.floor = 1
                    t.position = `${index}-${i}`
                    // t.children = t.children || []
                    t.icon = <FolderOpenOutlined style={{ marginRight: 8 }} />
                    t.parentId = item.id
                    if (t.children.length > 0) {
                        t.children.map((m: any, n: number) => {
                            m.title = m.labelName
                            m.key = m.labelId
                            m.floor = 2
                            m.position = `${index}-${i}-${n}`
                            m.icon = <TagOutlined style={{ marginRight: 8 }} />
                            m.parentId = t.id
                        })
                    }
                })
            }
        })
        console.log(arr);

        if (isDelete && arr.length === 0) {
            console.log(constData);

            setData(constData)
            if (props.clearSearch) {
                props.clearSearch()
            }
            searchText = ''
            isDelete = false
            return
        } else if (arr.length === 0) {
            message.warning('该标签不存在，请重新搜索')
        }
        setData(arr)
    }

    let newArr = [] as any
    const extractKeys = (data: any) => {
        data.forEach((item: any) => {
            newArr.push(item);
            if (item.children) {
                extractKeys(item.children);
            }
        });
    }


    const getCategoryList = async (creater?: string, isEdit?: boolean, key?: any) => {
        createStr = creater || ""
        // setData([])
        const param: categoryQuery = {
            creater,
            enable: 0
        }
        const res = await queryListApi(param)
        if (!res.data) {
            return
        }
        if (props.getLabelCount) {
            props.getLabelCount()
        }
        const categoryList = JSON.parse(JSON.stringify(res.data));
        let hasSecondFloor = false
        categoryList.map((item: any, index: number) => {
            item.title = item.categoryName
            item.key = item.id
            item.floor = 0
            item.position = `${index}`
            item.children = item.secondCategoryInfos || []
            item.icon = <FolderOpenOutlined style={{ marginRight: 8 }} />
            if (item.children.length > 0) {
                hasSecondFloor = true
                item.children.map((t: any, i: number) => {
                    t.title = t.categoryName
                    t.key = t.id
                    t.floor = 1
                    t.position = `${index}-${i}`
                    t.children = t.labelInfos || []
                    t.icon = <FolderOpenOutlined style={{ marginRight: 8 }} />
                    t.parentId = item.id
                    if (t.children.length > 0) {
                        t.children.map((m: any, n: number) => {


                            m.title = m.labelName
                            m.key = m.labelId
                            m.floor = 2
                            m.position = `${index}-${i}-${n}`
                            m.icon = <TagOutlined style={{ marginRight: 8 }} />
                            m.parentId = t.id
                            if (key && key.labelId.toString() === m.key.toString()) {
                                setHasExpandedKeys([key?.labelId?.toString(), t.key.toString(), item.key.toString()])
                            }
                        })
                    }
                })
            }
        })
        // 从首页跳转到对应的标签页
        if (location.state?.labelId) {
            newArr = [];
            extractKeys(categoryList)
            // console.log(newArr, '=======================newArr');
            let firId: any
            let secId: any
            newArr.forEach((it: any, index: any) => {
                if (location.state?.labelId === it.labelId) {
                    console.log(newArr[index], 'newArr[index]');
                    secId = newArr[index].parentId
                    const index1 = newArr.findIndex((s: any) => s.id === secId)
                    if (index1 !== -1) {
                        firId = newArr[index1].parentId
                    }
                }
            })
            console.log(firId, '=====================================firId', secId);
            const filterArr = newArr.filter((it: any) => it.labelId)
            setCurrentSelectKeys([location.state?.labelId])
            setHasExpandedKeys([String(firId), String(secId), location.state?.labelId])
            const defaultIndex = filterArr.findIndex((it: any) => it.labelId === location.state?.labelId)
            const item = filterArr[defaultIndex]
            if (props.selectLabel) {
                props.selectLabel(item)
            }
        }
        // 

        if (props.changeCate) {
            props.changeCate(hasSecondFloor)
        }
        constData = categoryList;
        if (props.clearSearch && !firstLoad && !isDelete) {
            searchText = ''
            props.clearSearch()
        }
        if (isDelete) {
            searchLable(searchText, true)
        } else {
            setData(categoryList)

            if (isEdit) {
                console.log(key);
                let firstKey = currentSelectKeys
                firstKey = [key?.labelId.toString()]
                setCurrentSelectKeys([key?.labelId.toString()])
                // hasExpandedKeys.push(key?.labelId?.toString())
                // setHasExpandedKeys(hasExpandedKeys)

                loop(categoryList, firstKey[0], '', (item, index, arr, parentKey) => {
                    if (props.selectLabel && item.labelId) {
                        props.selectLabel(item)
                    }
                })
            }
        }
        setFirstLoad(false)

    }

    const hidden = () => {
        setDropKey(null as unknown as number)
    }

    const setTreeData = (data: any[], type: string) => {

    }


    const getOperationList = (node: any) => {
        let a:any[] = []
          if (((node.floor === 0 || node.floor === 1) && checkPermissions(LABEL_KEY.UPDATE_CATEGORY)) || (node.floor === 2 && checkPermissions(LABEL_KEY.UPDATE_LABEL) && node.operatePermission === 2)) {
            a.push({ key: 'edit', label: (<a onClick={() => { handleEditClick(node.floor, node.title, node.key, node) }}>修改</a>) })
          }
          if (((node.floor === 0 || node.floor === 1) && checkPermissions(LABEL_KEY.DEL_CATEGORY)) || (node.floor === 2 && checkPermissions(LABEL_KEY.DEL_LABEL) && node.operatePermission === 2)) {
            a.push({ key: 'delete', label: (<a onClick={() => { setConfirmKey(node.key); }}>删除</a>) })
          }
        return a;
    }

    const handlPressEnter = async (key: string, floor: number, parentId?: number, position?: string, title?: string, type?: string) => {
        if (currentEditText === '') {
            message.error('请输入类目名称')
            return
        }

        if (editType === 1) {
            let param: addCategoryQuery = { categoryName: '', operator: 0, type: 0 };
            if (floor === 0) {
                param = {
                    categoryName: currentEditText,
                    type: 1,
                    operator: state.user.userId
                }
            } else if (floor === 1) {
                param = {
                    categoryName: currentEditText,
                    type: 2,
                    operator: state.user.userId,
                    parentId
                }
            }
            const res = await addLabelCategoryApi(param);
            if (res) {
                await getCategoryList(createStr)
                setCurrentSelectKeys([res.data.id.toString()])
                hasExpandedKeys.push(res.data.id.toString())
                setHasExpandedKeys(hasExpandedKeys)
                setIsEdit(false)
            } else {
                inputRef.current!.focus({
                    cursor: 'all'
                })

            }
        } else {
            let param: upDateCategoryNameQuery = { id: parseInt(key), categoryName: currentEditText }
            const res = await updateCategoryNameApi(param)
            if (res) {
                await getCategoryList(createStr)
                setIsEdit(false)
            } else {
                inputRef.current!.focus({
                    cursor: 'all'
                })
            }
        }
    }

    const handleInputChange = (str: string) => {
        if (str === ' ') {
            // setCurrentEditText("")
            currentEditText = ''
            return
        }
        // setCurrentEditText(str)
        currentEditText = str
    }

    const setTreeTitle = (arr: any[], key: string, str: string) => {
        arr.map(item => {
            if (item.key === key) {
                item.title = str;
                if (item.floor === 0 && !item.children) {
                    item.children = [{
                        key: Math.floor(Math.random() * 10001),
                        title: '二级类目1',
                        floor: 1,
                        index: 0,
                        icon: <FolderOpenOutlined style={{ marginRight: 8 }} />,
                    }]
                }
            }
            if (item.children && item.children.length > 0) {
                setTreeTitle(item.children, key, str)
            }
        })
    }
    // 禁止输入空格
    const handleKeyDown = (event: any) => {
        if (event.keyCode === 32) {
            event.preventDefault();
        }
    }
    const renderTreeNodes = (data: any[]) => {
        return data.map((node: any) => {
            const { key, title, children, floor, icon, position, index, parentId } = node

            const opertion = (
                <Space style={{ position: 'absolute', right: '5px' }}>
                    <Dropdown open={key === dropKey} placement='bottom' menu={{ items: getOperationList(node) }} trigger={['click']}>
                        <Popconfirm
                            placement="right"
                            title={`确定删除此${floor < 2 ? '类目' : '标签'}么？`}
                            description={floor < 2 ? '删除时，将同时删除该类目下的标签' : '删除后，将无法应用该标签及标签值'}
                            okText="确定"
                            cancelText="取消"
                            onConfirm={(e) => { handleDeleteClick(floor, position, key); e?.stopPropagation(); setConfirmKey(null as unknown as number); setMouseOnKey(null as unknown as number) }}
                            onCancel={() => { setConfirmKey(null as unknown as number); setMouseOnKey(null as unknown as number) }}
                            open={key === confirmKey}
                        >
                            <a style={{ color: 'var(--text-font-color4)' }} onClick={(e) => { setDropKey(key); if (floor === 2) { handleTreeSelect([key], { node: { key: key, pos: `0-${position}` } }); } e.stopPropagation() }}>···</a>
                        </Popconfirm>
                    </Dropdown>
                </Space>
            );

            const addIcon = (
                <Space>
                    <PlusOutlined onClick={(e) => { setDropKey(null as unknown as number); e.stopPropagation(); handleAddClick(position, floor, key, node) }} style={{ position: 'absolute', right: '20px', top: 7, color: 'var(--text-font-color4)', fontSize: 'var(--text-font4)' }} />
                </Space>
            )

            const treeItem = (
                <span title={title}>
                    {icon}{title}
                </span>
            )
            if (children && children.length > 0) {
                return (
                    <TreeNode key={key}
                        title={<span
                            onMouseEnter={() => handleMouseEnter(key)}
                            onMouseLeave={() => handleMouseLeave()}
                            style={{ position: 'relative', display: 'flex', flex: '1', alignItems: 'center' }}
                        >
                            {!(isEdit && currentSelectKeys.length > 0 && key.toString() === currentSelectKeys[0].toString()) && <Tooltip placement="left" title={title}>
                                <span style={{ width: floor === 0 ? '130px' : floor === 1 ? '118px' : '106px' }} className="line-text">
                                    {treeItem}
                                </span>
                            </Tooltip>}
                            {!isEdit && (mouseOnKey === key || confirmKey === key || dropKey === key) && getOperationList.length > 0 && opertion}
                            {(isEdit && currentSelectKeys.length > 0 && key.toString() === currentSelectKeys[0].toString()) && <span>

                                <Input maxLength={20} onBlur={() => { handleBlur(floor, position, title, key, parentId) }} ref={inputRef} onKeyDown={handleKeyDown} defaultValue={currentEditText} onPressEnter={(e) => {
                                    console.log(node);
                                    handlPressEnter(key, floor, parentId, position, title)
                                }} onChange={(e) => { handleInputChange(e.target.value) }} /></span>}
                            {/* {!isEdit && (mouseOnKey === key || confirmKey === key) && ((floor === 1 || floor === 0) && checkPermissions(LABEL_KEY.ADD_CATEGORY)) && addIcon} */}
                        </span>}>
                        {renderTreeNodes(children)}
                    </TreeNode >
                )
            } else {
                return (
                    <TreeNode key={key}
                        title={<span
                            onMouseEnter={() => handleMouseEnter(key)}
                            onMouseLeave={() => handleMouseLeave()}
                            style={{ display: 'flex', flex: '1' }}
                        >
                            {!(isEdit && currentSelectKeys.length > 0 && key.toString() === currentSelectKeys[0].toString()) && <Tooltip placement="left" title={title}>
                                <span style={{ width: floor === 0 ? '130px' : floor === 1 ? '118px' : '106px' }} className="line-text">
                                    {treeItem}
                                </span>
                            </Tooltip>}
                            {!isEdit && (mouseOnKey === key || confirmKey === key || dropKey === key) && getOperationList.length > 0 && opertion}
                            {(isEdit && currentSelectKeys.length > 0 && key.toString() === currentSelectKeys[0].toString()) && <span>

                                <Input maxLength={20} onBlur={() => { handleBlur(floor, position, title, key, parentId) }} ref={inputRef} onKeyDown={handleKeyDown} defaultValue={currentEditText} onPressEnter={(e) => {
                                    console.log(node);
                                    handlPressEnter(key, floor, parentId, position, title)
                                }} onChange={(e) => { handleInputChange(e.target.value) }} /></span>}
                            {/* {!isEdit && (mouseOnKey === key || confirmKey === key) && ((floor === 1 || floor === 0) && checkPermissions(LABEL_KEY.ADD_CATEGORY)) && addIcon} */}
                        </span>}>

                    </TreeNode >
                )
            }
        })
    }

    const handleMouseEnter = (key: number) => {
        setMouseOnKey(key)
    }

    const handleMouseLeave = () => {
        setMouseOnKey(null as unknown as number)
    }

    const handleTreeSelect = (selectedKeys: any[], e: any) => {
        // console.log(e, '选中');
        // console.log(hasExpandedKeys, 'select hasExpandedKeys');
        if (isEdit) {
            return
        }
        // debugger
        setCurrentSelectKeys([e.node.key])
        const arr = e.node.pos.split('-')
        const count = arr.length;
        console.log(count);

        if (hasExpandedKeys.indexOf(e.node.key.toString()) === -1) {

            console.log(e.node.key.toString());
            const a = JSON.parse(JSON.stringify(hasExpandedKeys))
            a.push(e.node.key.toString())
            console.log(a);

            setHasExpandedKeys(a)
        } else {
            let a = JSON.parse(JSON.stringify(hasExpandedKeys))
            a = _.remove(a, function (n) {
                return n !== e.node.key.toString()
            })
            setHasExpandedKeys(a)
        }

        if (count > 2) {
            if (count < 4) {

                if (props.selectCatgory) {
                    props.selectCatgory(data[arr[1]].children[arr[2]])
                }
            } else {
                const item = data[arr[1]].children[arr[2]].children[arr[3]]
                if (props.selectLabel) {
                    props.selectLabel(item)
                }
            }
            // if (props.changeCate) {
            //     props.changeCate(count === 3)
            // }
        }
    }


    const closeDraw = () => {
        setAddCategoryDrawerOpen(false)
    }

    const submitAddCate = async (e: any) => {
        let param: addCategoryQuery = { categoryName: e.categoryName, type: e.type, parentId: e.id };
        const res = await addLabelCategoryApi(param);
        if (res) {
            message.success('创建成功')
            await getCategoryList(createStr)
            setCurrentSelectKeys([res.data.id.toString()])
            hasExpandedKeys.push(res.data.id.toString())
            setHasExpandedKeys(hasExpandedKeys)
            setAddCategoryDrawerOpen(false)
        }
    }
    //添加类目
    const addCate = () => {
        setAddCategoryDrawerOpen(true)

        // const newData = data;
        // newData.splice(0, 0, {
        //     key: Math.floor(Math.random() * 10001),
        //     title: '',
        //     floor: 0,
        //     index: data.length,
        //     icon: <FolderOpenOutlined style={{ marginRight: 8 }} />,
        // })
        // setData(newData)
        // setEditType(1)
        // setCurrentSelectKeys([newData[0].key])
        // // setCurrentEditText('')
        // currentEditText = ''
        // setIsEdit(true)
        // setTimeout(() => {
        //     inputRef.current!.focus({
        //         cursor: 'all'
        //     })
        // }, 10)
    }
    //增加一级类目
    const handleAddClick = (position: string, floor: number, parentKey: string, node?: any) => {
        setEditType(1)
        const arr = position.split('-');
        const key = Math.floor(Math.random() * 10001)
        if (floor === 0) {
            data[parseInt(arr[0])].children.splice(0, 0, {
                key,
                title: '',
                floor: 1,
                index: data[parseInt(arr[0])].children.length,
                icon: <FolderOpenOutlined style={{ marginRight: 8 }} />,
                position: `${position}-${data[parseInt(arr[0])].children.length}`,
                parentId: parentKey
            })
            setData(data)
            setEditType(1)
            setCurrentSelectKeys([key])
            // setCurrentEditText('')
            currentEditText = ''
            setIsEdit(true)
            const a = JSON.parse(JSON.stringify(hasExpandedKeys))
            a.push(parentKey.toString());
            setHasExpandedKeys(a)
            setTimeout(() => {
                inputRef.current!.focus({
                    cursor: 'all'
                })
            }, 500)
        } else {
            if (props.addLabel) {
                props.addLabel(node)
            }
        }
    }

    //标记类目
    const handleEditClick = (floor: number, title: string, key: string, node: any) => {
        if (floor < 2) {
            setEditType(2)
            setCurrentSelectKeys([key])
            // setCurrentEditText(title)
            currentEditText = title
            setIsEdit(true)
            setTimeout(() => {
                inputRef.current!.focus({
                    cursor: 'all'
                })
            }, 10)
        } else {
            if (props.editLabel) {
                props.editLabel(node)
            }
        }
    }

    //输入框失去焦点
    const handleBlur = async (floor: number, position: string, title: string, key: string, parentId: number) => {
        if (editType === 1) {
            if (currentEditText === '') {
                if (floor === 0) {
                    data.splice(0, 1)
                } else if (floor === 1) {
                    var a = position.split('-')
                    // var count = data[parseInt(a[0])].children.length
                    data[parseInt(a[0])].children.splice(0, 1)
                }
                setData(data)
                setCurrentSelectKeys([])
                // setCurrentEditText('')
                currentEditText = ''
                setIsEdit(false)
            } else {
                await handlPressEnter(key, floor, parentId, position)
            }
        } else {
            if (currentEditText === '') {
                if (floor === 0) {
                    data[parseInt(position)].title = title
                } else {
                    var a = position.split('-')
                    data[parseInt(a[0])].children[parseInt(a[1])].title = title
                }
                setData(data)
                setCurrentSelectKeys([])
                // setCurrentEditText('')
                currentEditText = ''
                setIsEdit(false)
            } else {
                await handlPressEnter(key, floor, parentId, position, title)
            }
        }

    }

    // 是否显示应用标签列表
    const [showInfo, setShowInfo] = useState(false)
    const [usedInfos, setUsedInfos] = useState<any[]>([])
    const [showErrModal, setShowErrModal] = useState(false)
    //删除类目
    const handleDeleteClick = async (floor: number, position: string, id: number) => {
        if (floor < 2) {
            const res = await deleteCategoryApi({ id })
            if (res) {
                isDelete = true
                message.success('删除成功')
                getCategoryList(createStr)
            }
        } else {
            const res = await deleteLabelApi({ labelId: id })
            if (res && res.data.delStatus !== 0) {
                isDelete = true
                message.success('删除成功')
                getUserClickApi(state.childMenuId, 3, undefined, id, state.parentMenuId, state.currentPath)
                // 删除标签时清空右侧数据
                if (props.selectLabel) {
                    props.selectLabel({})
                }
                getCategoryList(createStr)
            } else {
                if (res && res.data.delStatus === 0) {
                    setUsedInfos(res.data.usedInfos)
                    // setShowErrModal(true)
                    message.error('该标签及标签值正在被应用，不允许删除')
                }
            }
        }
    }

    //拖动
    const handleTreeDrop = (e: any) => {
        console.log(e);
        loop(data, e.dragNode.key, '', (item, index, arr, parentKey) => {
            if (parentKey.toString() === e.node.key.toString()) {
                loop(data, parentKey, '', (item2) => {
                    item2.children = item2.children || [];
                    // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
                    item2.children.splice(index, 1)
                    item2.children.unshift(item);
                    let updateInfos: any[] = []
                    item2.children.map((item: any, index: number) => {
                        updateInfos.push({
                            id: item.key,
                            sortIndex: index
                        })
                    })
                    const param = {
                        type: item.floor + 1,
                        updateInfos
                    }
                    moveLabelApi(param)
                });
            } else {
                loop(data, e.dragNode.key, '', (item1, index1, arr1, parentKey1) => {
                    if (parentKey === parentKey1) {
                        if (item1.floor === 0) {
                            console.log(index1);
                            console.log(e.dropPosition);

                            if (e.dropPosition === -1) {
                                data.splice(index1, 1)
                                data.splice(0, 0, item1)
                            } else if (index1 > e.dropPosition) {
                                data.splice(index1, 1)
                                data.splice(e.dropPosition, 0, item1)
                            } else if (index1 < e.dropPosition) {
                                data.splice(e.dropPosition, 0, item1)
                                data.splice(index1, 1)
                            }
                            let updateInfos: any[] = []
                            data.map((item: any, index: number) => {
                                updateInfos.push({
                                    id: item.key,
                                    sortIndex: index
                                })
                            })
                            const param = {
                                type: 1,
                                updateInfos
                            }
                            moveLabelApi(param)
                        } else {
                            loop(data, parentKey, '', (item2) => {
                                item2.children = item2.children || [];
                                if (index1 > e.dropPosition) {
                                    item2.children.splice(index1, 1)
                                    item2.children.splice(e.dropPosition, 0, item1)
                                } else if (index1 < e.dropPosition) {
                                    item2.children.splice(e.dropPosition, 0, item1)
                                    item2.children.splice(index1, 1)
                                }

                                let updateInfos: any[] = []
                                item2.children.map((item: any, index: number) => {
                                    updateInfos.push({
                                        id: item.key,
                                        sortIndex: index
                                    })
                                })
                                const param = {
                                    type: item.floor + 1,
                                    updateInfos
                                }
                                moveLabelApi(param)
                            });
                        }


                    }
                })
            }
        })
        setData(data)
    }

    const loop = (data: any[], key: string, parentKey: string, callback: (node: any, i: number, data: any[], parentKey: string) => void) => {

        for (let i = 0; i < data.length; i++) {

            if (data[i].key.toString() === key.toString()) {
                return callback(data[i], i, data, parentKey)
            }
            if (data[i].children) {
                loop(data[i].children!, key, data[i].key, callback)
            }
        }
    }

    const onExpand = async (selectedKeys: any, e: any) => {
        // const { key, pos } = e.node
        // //展开第一层标签
        // if (pos.split('-').length === 2) {
        //     //未加载过数据
        //     if (hasLoadNode.indexOf(key) === -1) {
        //         await getCategoryList(pos.split('-').length, key, parseInt(pos.split('-')[1]))
        //     }
        // } else {
        //     if (hasLoadNode.indexOf(key) === -1) {

        //     }
        // }
        console.log(selectedKeys);

        setHasExpandedKeys(selectedKeys)
    }


    const [draggable, setDraggable] = useState(true)
    return <div className={style['tree-container']} ref={treeContainerRef} >
        <Skeleton loading={firstLoad}>
            {data.length > 0 ? <Tree className={style['rule-tag-tree']}
                draggable={draggable}
                showIcon={true}
                style={{ width: '218px' }}
                selectedKeys={currentSelectKeys}
                onSelect={handleTreeSelect}
                onDrop={handleTreeDrop}
                expandedKeys={hasExpandedKeys}
                onExpand={onExpand}
            >
                {renderTreeNodes(data)}
            </Tree > : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </Skeleton>
        <Modal title="提示" open={showErrModal} footer={null} onCancel={() => setShowErrModal(false)}>
            <p>该标签及标签值正在被应用，不允许删除</p>
            {/* <Button onClick={() => setShowInfo(!showInfo)}>{showInfo ? '隐藏' : '显示'}</Button>
            {showInfo && <p>
                {usedInfos.map((item: any, index: number) =>
                    <span key={index}>{item.name}{index < usedInfos.length - 1 ? '，' : ''}</span>
                )
                }
            </p>
            } */}
        </Modal>
        <AddCategoryDrawer open={AddCategoryDrawerOpen} cateList={constData} closeDraw={closeDraw} submitAddCate={submitAddCate}></AddCategoryDrawer>
    </div>

})

export default RuleTagTree