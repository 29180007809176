// 在组织架构树种选择成员,默认不选择当前登录账号成员
import React, { useState, useEffect } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Space, Tooltip, Tree } from 'antd';
import type { DrawerProps, TreeDataNode, TreeProps } from 'antd';
import { getDeptUserTreeListApi } from '@/api/share/shareAndCommon';

const PermissionsDataDrawer = (props: {
  setShareDrawer: Function;
  shareDrawer: boolean;
  title: string;
  tip: any;
  onShareClick?: Function;
  shareDisplay: boolean;
  shareCheckedList:any
}) => {
  const [open, setOpen] = useState(false);
  const { shareDrawer, setShareDrawer = () => {}, onShareClick, shareDisplay, title, tip, shareCheckedList} = props;
  const [placement, setPlacement] = useState<DrawerProps['placement']>('right');
  const [checkedList, setCheckedList] = useState<any>();
  const [shareTreeData, setShareTreeData] = useState<TreeDataNode[]>([]);
  const [selfUserId, setSelfUserId]=useState<string>('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [list, setList] = useState<any>([]);
  const getDeptUserTreeList = async () => {
    const res = await getDeptUserTreeListApi();
    if (res.code === 200) {
      // 将返回数据遍历成所需数据
      // const list = res.data.deptUserTree.map((dept:any) => ({
      //   title: <span style={{color:'black'}}>{dept.deptName}</span>,
      //   key: dept.deptId.toString(),
      //   children: dept.children.map((subDept:any) => ({
      //       title: <span style={{color:'black'}}>{subDept.deptName}</span>,
      //       key: subDept.deptId.toString(),
      //       children: [
      //           ...subDept.children.map((subSubDept:any) => ({
      //               title: <span style={{color:'black'}}>{subSubDept.deptName}</span>,
      //               children: subSubDept.users.map((user:any) => ({
      //                   title: <span style={{color:'black'}}>{user.userName}</span>,
      //                   key: user.userId,
      //                   disableCheckbox: user.userId === res.data.selfUserId || (shareCheckedList.length > 0 && shareCheckedList?.find((item:any) => item === user.userId))
      //               }))
      //           })),
      //           ...subDept.users.map((user:any) => ({
      //               title: <span style={{color:'black'}}>{user.userName}</span>,
      //               key: user.userId,
      //               disableCheckbox:user.userId === res.data.selfUserId ||  (shareCheckedList.length > 0 && shareCheckedList?.find((item:any) => item === user.userId))
      //           }))
      //       ]
      //   }))
      // }));
      setList(res.data.deptUserTree)
      setSelfUserId(res.data.selfUserId)
      // setShareTreeData(list);
    }
  };
  const shareTitle = (
    <div>
      {title}
      <Tooltip
        placement="bottom"
        title={tip.map((paragraph: any, index: any) => (
          <div key={index}>
            {paragraph}
            {index < tip.length - 1 && <br />}
          </div>
        ))}>
          {
            tip.length > 0 && <QuestionCircleOutlined style={{ marginLeft: 10 }} />
          }
      </Tooltip>
    </div>
  );

  // 关闭当前抽屉
  const onClose = () => {
    setShareDrawer(false);

  };
  useEffect(() => {
    setCheckedList([])
    setCheckedList([...shareCheckedList]);
  }, [shareCheckedList]);
  useEffect(() => {
    setOpen(shareDrawer);
    if(!shareDrawer){
        // setCheckedList([])
        
    }else{
      setAutoExpandParent(true)
      getDeptUserTreeList()
    }
  }, [shareDrawer]);
  useEffect(() => {
    if (shareDrawer && selfUserId !== '') {
      const updateList = list.map((dept:any) => ({
        title: <span style={{color:'black'}}>{dept.deptName}</span>,
        key: dept.deptId.toString(),
        children: dept.children.map((subDept:any) => ({
            title: <span style={{color:'black'}}>{subDept.deptName}</span>,
            key: subDept.deptId.toString(),
            children: [
                ...subDept.children.map((subSubDept:any) => ({
                    title: <span style={{color:'black'}}>{subSubDept.deptName}</span>,
                    children: subSubDept.users.map((user:any) => ({
                        title: <span style={{color:'black'}}>{user.userName}</span>,
                        key: user.userId,
                        disableCheckbox: user.userId === selfUserId 
                        || (!shareDisplay && shareCheckedList.length > 0 && shareCheckedList?.find((item:any) => item === user.userId))
                    }))
                })),
                ...subDept.users.map((user:any) => ({
                    title: <span style={{color:'black'}}>{user.userName}</span>,
                    key: user.userId,
                    disableCheckbox:user.userId === selfUserId 
                    || (!shareDisplay && shareCheckedList.length > 0 && shareCheckedList?.find((item:any) => item === user.userId))
                }))
            ]
        }))
      }));
      setShareTreeData(updateList);
    }
  }, [shareCheckedList, shareDrawer, selfUserId]);
  // 树节点除自己外默认全选
  // const getAllKeys = (treeData: any, selfUserId:string): string[] => {
  //   let keys: string[] = [];
  //   treeData.forEach((node:any) => {
  //       if (node.key !== selfUserId) {
  //         keys.push(node.key);
  //       }
  //       if (node.children) {
  //           keys = keys.concat(getAllKeys(node.children,selfUserId));
  //       }
  //   });
  //   return keys;
  // };
  // useEffect(() => {
  //   if(checkedList.length<=0){
  //     const allKeys = getAllKeys(shareTreeData,selfUserId);
  //     setCheckedList(allKeys);
  //   }
  // }, [shareTreeData]);

  const onCheck: TreeProps['onCheck'] = (checkedKeys, info) => {
    setCheckedList(checkedKeys);
  };
  return (
    <div>
      <Drawer
        title={shareTitle}
        placement={placement}
        width={400}
        onClose={onClose}
        open={open}
        extra={

          tip.length > 0 &&
          <Space >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button
              disabled = {checkedList.length <= 0 || shareCheckedList?.length === checkedList.length}
              type="primary"
              onClick={() => {
                if (onShareClick) {
                  onShareClick(checkedList);
                }
              }}>
              确定
            </Button>
          </Space>
        }>
          <Tree
            checkable
            disabled={shareDisplay}
            checkedKeys={checkedList}
            defaultExpandAll={autoExpandParent}
            onCheck={onCheck}
            treeData={shareTreeData}
          />
      </Drawer>
    </div>
  );
};

export default PermissionsDataDrawer;
