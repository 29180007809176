import React, { useState, useEffect } from "react";
import type { UploadProps , UploadFile} from 'antd';
import { UploadOutlined, LeftOutlined, DownloadOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Steps,
  Upload,
  message,
  DrawerProps,
  Switch,
  Cascader,
} from "antd";
import { useNavigate, useLocation } from 'react-router-dom'
import {
  customerList,
  labelCheakEditApi,
  queryListApi,
  crowdCheckEditApi,
  getCrowdInfoApi,
  getExcel,
  createLabelInfoExcel,
  createGroupInfoExcel
} from "../../../../api/label/label";
import dayjs from "dayjs";
import OperateLog from '../../../UserGrouping/components/OperateLog'
import ExportExplain from "../../components/ExportExplain";
import { createCrowdQuery } from "../../../../api/label/types";
import { GroupListVO } from "../../../../api/userGroup/type";
import { state } from "../../../../store";
import styles from "./index.module.scss";
import { CROWD_KEY } from '../../../../router/Permissions';
import { checkPermissions } from '@/utils/utils';
import { getUserClickApi } from '../../../../api/home/home';
import { download } from '@/api/network';
import { setStateLabelId } from '../../../../store';

const { TextArea } = Input;

// 下载CSV模板 需要的识别码的参数
let onlyCodeName = '客户唯一标识' as any;

let uploadFileStatus = false;//上传文件状态
let fileUpLoad = {} as any;
const CreateExport = (
  props:{
    dataList?:any
  }
) => {
  const navigate = useNavigate();
  const [permissionLevel,setPermissionLevel] = useState<number>();

  // let location = useLocation();
  

  const [flag, setFlag] = useState(props.dataList.flag);
  const [uploadFileFinish, setUploadFileFinish] = useState( flag === "create" ? false : true);
  const upLoadText = props.dataList.type == 2 ? '上传 CSV /EXCEL 格式的文件与客户属性进行匹配，匹配结果保留为分群客户' : `上传CSV/EXCEL格式文件，文件内容包含"客户属性与对应的标签值"`
  //1 标签 2分群
  const type = props.dataList.type;

  const [current, setCurrent] = useState(0);

  const [crowdId, setCrowdId] = useState("");
  const [labelId, setLabelId] = useState("");

  const [openLog, setOpenLog] = useState(false);
  const [crowIdRecord, setCrowIdRecord] = useState({} as GroupListVO)
  const openLogs = (info: any) => {
    setCrowIdRecord(info)
    setOpenLog(true)
  }

  const [accountForm] = Form.useForm();

  // 查看状态
  type FieldType = {
    crowdName: string;
    crowdDescribe: string;
    enable: number;
    updateType: string;
    dataType: any;
    upload: string;

    labelName: string;
    categoryId: number;
    categoryIdStore: any;
    renewType: any;
    description: string;
  };

  const formData: FieldType = {
    crowdName: "",
    crowdDescribe: "",
    enable: 1,
    updateType: "3",
    dataType: null,
    upload: "",

    labelName: "",
    categoryId: 0,
    categoryIdStore: [],
    renewType: '3',
    description: "",
  };

  const [conditions, setConditions] = useState(
    JSON.parse(JSON.stringify(formData))
  );
  const changeConditions = (newConditions: any) => {
    setConditions({
      ...conditions,
      ...newConditions,
    });
  };

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  // 编辑数据处理
  const handleData = (store?: any) => {
    if (type == 1) {
      const obj = {
        labelName: props.dataList.editData.labelName,
        categoryId: props.dataList.editData.categoryId,
        categoryIdStore: store,
        renewType: String(props.dataList.editData.renewType),
        description: props.dataList.editData.description,
        dataType: props.dataList.editData.dataType,
      };
      setConditions({ ...obj });
      accountForm.setFieldValue("labelName", props.dataList.editData.labelName);
      accountForm.setFieldValue("renewType",  String(props.dataList.editData.renewType));
      accountForm.setFieldValue("description", props.dataList.editData.description);
      accountForm.setFieldValue("dataType", props.dataList.editData.dataType);
    } else {
      const obj = {
        crowdName: props.dataList.editData.crowdName,
        crowdDescribe: props.dataList.editData.crowdDescribe,
        enable: props.dataList.editData.enable,
        updateType: String(props.dataList.editData.updateType),
        dataType: props.dataList.editData.dataType,
      };
      setConditions(obj);
      accountForm.setFieldValue("crowdName", props.dataList.editData.crowdName);
      accountForm.setFieldValue("crowdDescribe", props.dataList.editData.crowdDescribe);
      accountForm.setFieldValue("updateType", String(props.dataList.editData.updateType));
      accountForm.setFieldValue("dataType", props.dataList.editData.dataType);
    }
    setPermissionLevel(props.dataList.editData.permissionLevel)
    setFileList([
      {
        uid: '-1',
        name: props.dataList.editData.fileName,
      },
    ])
  };
  //更新类型
  const [optionsValue, setOptionsValue] = useState([
    { label: "手动更新", value: "3" },
  ]);
  interface Option {
    value: string | number;
    label: string;
    children?: Option[];
  }
  //标签类目
  const [optionsValueCateGory, setOptionsValueCateGory] = useState([] as Option[]);

  // 下一步
  const nextStep = () => {
    if (type == 1 && !conditions.labelName.trim()) {
      setLoading(true);
      return message.warning("请输入标签名称");
    }
    if (type == 1 && conditions.categoryIdStore.length === 0) {
      setLoading(true);
      return message.warning("请选择标签类目");
    }
    if (type == 2 && !conditions.crowdName.trim()) {
      setLoading(true);
      return message.warning("请输入分群名称");
    }
    

    setCurrent(1);
  };

  // 提交
  const [loading, setLoading] = useState(true);
  const submit = async () => {
    if (loading) {
      setLoading(false);
      if (type == 1 && !conditions.labelName.trim()) {
        setLoading(true);
        return message.warning("请输入标签名称");
      }
      if (type == 1 && conditions.labelName.length > 20) {
        setLoading(true);
        return message.warning("标签名称过长");
      }
      if (type == 2 && !conditions.crowdName.trim()) {
        setLoading(true);
        return message.warning("请输入分群名称");
      }
      if (type == 2 && conditions.crowdName.length > 50) {
        setLoading(true);
        return message.warning("分群名称过长");
      }

      if (!conditions.dataType) {
        setLoading(true);
        return message.warning("请选择客户唯一识别码");
      }
      if (Object.keys(fileUpLoad).length === 0 && flag === "create") {
        setLoading(true);
        return message.warning("请上传文件");
      }
      if (uploadFileStatus) {
        setLoading(true);
        return message.warning("请上传.xlsx .xls .csv 的文件");
      }

      const formData = new FormData();

      // delete conditions.categoryId  处理入参转为二进制 
      formData.append('req', new Blob([JSON.stringify({
        ...conditions,
        taskName: type == 1 ? conditions.labelName : conditions.crowdName,
        categoryId: type == 1 ?
            conditions.categoryIdStore.length === 0
              ? props.dataList.catgoryIdStore[props.dataList.catgoryIdStore.length - 1]
              : conditions.categoryIdStore[
              conditions.categoryIdStore.length - 1
              ] : null,
        taskLocation: type == 1 ? `导入标签_${conditions.labelName}-${dayjs().format("YYYYMMDDHHmmss")}` : `导入分群_${conditions.crowdName}-${dayjs().format("YYYYMMDDHHmmss")}`, //页面位置
        flagType: flag === "create" ? 0 : 1,  // 0 新建 1 追加
        crowdId: crowdId,
        labelId: labelId,
        createMode: type == 1 ? "导入标签" : '导入分群',
        type : type == 1 ? null : 4
      })], { type: 'application/json' }));
      if (Object.keys(fileUpLoad).length !== 0) {
        formData.append('file', fileUpLoad);
      }
      let res: any = {};
      if (type == 1) {
        // if (flag === "create") {
        //   params.creater = String(state.user.userId);
        // } else {
        //   params.creater = props.dataList.editData.creater;
        // }
        res = await createLabelInfoExcel(formData);
      } else {
        if (flag === "create" || flag === "copy") {
          // params.createBy = String(state.user.userId);
          localStorage.removeItem('pageNum')
          localStorage.removeItem('pageSize')
          localStorage.removeItem('searchListData')
          getUserClickApi(state.childMenuId, 3, crowdId, undefined, state.parentMenuId, '/userGrouping/groupingManagement/userGroupingList/userList?type=1')
        } else {
          // params.createBy = props.dataList.editData.createBy;
          getUserClickApi(state.childMenuId, 3, props.dataList.editData.id, undefined, state.parentMenuId, '/userGrouping/groupingManagement/userGroupingList/userList?type=1')
        }
        res = await createGroupInfoExcel(formData);
      }

      if (res) {
        setLoading(true);
        if (res.code == 200) {
          message.success(
            flag === "create" ? "创建成功" : flag === "edit" ? "编辑成功" : "复制成功"
          );
          if (type == 1) {
            setStateLabelId(Number(labelId));
            getUserClickApi(state.childMenuId, 3, undefined, labelId, state.parentMenuId, '/tages/ruleTags/tags')
          }
          setTimeout(() => {
            navigate(-1) ;
          }, 1000);
        } else if (res.code == 506) {
          setLoading(true);
        }
      } else {
        setLoading(true);
      }
    }
  };
  // 查看切换编辑 分群
  const editGroup = async () => {
    if (props.dataList.editData.calculateStatus === 1) {
      message.warning('分群计算中，稍后重试');
    } else {
      if (loading) {
        setLoading(false);
        const params = {
          crowdId: props.dataList.editData.crowdId
        };
        const res = await crowdCheckEditApi(params);
        if (res.data) {
          setFlag('edit');
        } else {
          message.warning("该分群已被应用，不可进行修改操作");
        };
        setLoading(true);
      }
    }
  };
  // 查看切换编辑 标签
  const editLabel = async () => {
    if (props.dataList.editData.calculateStatus === 1) {
      message.warning('标签计算中，稍后重试');
    } else {
      if (loading) {
        setLoading(false);
        const params = {
          labelId: props.dataList.editData.labelId
        };
        const res = await labelCheakEditApi(params);
        if (res.data) {
          setFlag('edit');
        } else {
          message.warning("该标签值已被应用，不可进行修改操作");
        };
        setLoading(true);
      }
    }
  };

  // 获取分群详细信息——此接口用于记录操作日志，实际编辑数据从列表页接口拿
  const getCrowdInfo = async (id: string) => {
    const param: any = {
      crowdId: id
    };
    const res = await getCrowdInfoApi(param);
  };
  //上传文件 路径 展示的文件 
  const handleChange = async (info: any) => {
    let newFileList = [...info.fileList]
    newFileList = newFileList.slice(-2);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(newFileList);

    fileUpLoad = info.file.originFileObj;
    if (uploadFileFinish) {
      fileUpLoad = {}
    }
    setUploadFileFinish(!uploadFileFinish);
  };
  //自定义上传文件
  const CustomRequest = (props: any) => {
    const { onSuccess, onError, file, onProgress } = props;

    // 这里可以编写你的上传逻辑，例如使用FormData等
    const formData = new FormData();
    formData.append('file', file);
    // 模拟上传进度
    if (uploadFileStatus) {
      setTimeout(() => {
        onError({ message: '上传失败' });
      }, 2000); // 延迟1秒钟模拟上传失败
    }else {
      setTimeout(() => {
        onProgress({ percent: 100 });
        onSuccess("mock-upload-response");
      }, 2000);
    }
  }
  //上传文件限制
  const beforeUpload = (file: any) => {
    const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const isXls = file.name.endsWith('.xls');
    const isCsv = file.name.endsWith('.csv');
    const isSize = file.size <= 25 * 1024 * 1024;
    uploadFileStatus = false;

    if (!isXlsx && !isXls && !isCsv) {
      message.error(`请上传.xlsx .xls .csv 的文件`);
      uploadFileStatus = true;
      return
    }
    if (!isSize) {
      message.error(`文件最大支持25mb`);
      uploadFileStatus = true;
      return
    }
    return (isXlsx || isXls || isCsv) && isSize || Upload.LIST_IGNORE;
  };
  //上传
  const upload: UploadProps = {
    name: 'file',
    multiple: true,
    // action: '#',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };
  // 获取标签类目
  const getCategoryList = async (creater?: string) => {
    let store: any = [];
    setOptionsValueCateGory([]);
    const param: any = {
      creater: "",
      enable: 1
    };
    const res = await queryListApi(param);
    const categoryList = JSON.parse(JSON.stringify(res.data));
    categoryList.map((item: any, index: number) => {
      item.label = item.categoryName;
      item.value = item.id;
      item.children = item.secondCategoryInfos || [];
      if (item.children?.length > 0) {
        item.children.map((t: any, i: number) => {
          t.label = t.categoryName;
          t.value = t.id;
          if (flag === "view" || flag === "edit") {
            if (t.id === props.dataList.editData.categoryId) {
              store.push(item.id);
              store.push(t.id);
            }
          }
          if (t.children?.length > 0) {
            t.children.map((m: any, n: number) => {
              m.label = m.labelName;
              m.value = m.labelId;
            });
          }
        });
      }
    });
    if (flag === "edit" || flag === "view") {
      handleData(store);
      accountForm.setFieldValue("categoryIdStore", store);
    }
    setOptionsValueCateGory(categoryList);
  };
  //抽屉  true  开启导入说明
  const [openExportExplain, setOpenExportExplain] = useState(false);

  // 打开导入说明
  const exportExplain = () => {
    setOpenExportExplain(true);
  };
  // 关闭导入说明
  const closeExportExplain = (v: boolean) => {
    setOpenExportExplain(v);
  };
  //用户唯一标识码 列表
  const [propertyOptions, setPropertyOptions] = useState([] as any);
  // 获取用户属性
  const getProfilePropertyList = async () => {
    const { data } = await customerList();
    data.forEach((v: any, i: number) => {
      v.label = v.fieldName;
      v.value = v.id;
    });
    setPropertyOptions(data);
  };
  useEffect(() => {
    propertyOptions.map((item: any) => {
      if (item.id == conditions.dataType) {
        onlyCodeName = item.label
      }
    })
    console.log("===");
  }, [conditions.dataType])
  // 下载CSV模板
  const toDownLoad = async () => {
    // const param: any = {
    //   labelType: onlyCodeName,
    //   type: type
    // };
    // const res = await getExcel(param);
    // window.location.href = res.msg;
    download(`/resource/oss/getExcelE?labelType=${onlyCodeName}&type=${type}`)
  };
  // 查看操作日志回调 - 关闭日志
  const getOpenlog = (log: boolean) => {
    setOpenLog(log)
  }
  useEffect(() => {
    getProfilePropertyList()
    if (flag === "edit" || flag === "view" || flag === "copy") {
      handleData();
      if (type == 1) {
        // setParamsList(JSON.parse(JSON.stringify(items)));
        getCategoryList();
        if (flag === "edit" || flag === "view") {
          // setLabelId(JSON.parse(props.dataList.editData.labelRule).label_id);
          // localStorage.setItem('labelData', JSON.stringify(props.dataList.editData))
          setLabelId(props.dataList.editData.labelId);
          localStorage.setItem('labelData', JSON.stringify(props.dataList.editData))
        } else {
          setLabelId(String(new Date().valueOf()) + "01");
        }
      } else if (type == 2) {
        if (flag === "edit" || flag === "view") {
          // setCrowdId(JSON.parse(props.dataList.editData.ruleJson).crowd_id);
          // getCrowdInfo(JSON.parse(props.dataList.editData.ruleJson).crowd_id);
          setCrowdId(props.dataList.editData.crowdId);
          getCrowdInfo(props.dataList.editData.crowdId);
        } else {
          setCrowdId(String(new Date().valueOf()) + "02");
        }
      }
    } else {
      if (type == 1) {
        // setParamsList(JSON.parse(JSON.stringify(items)));
        getCategoryList();
        setLabelId(String(new Date().valueOf()) + "01");
      } else {
        setCrowdId(String(new Date().valueOf()) + "02");
      }
    }
    return () => {
      // 组件卸载时清理message
      message.destroy();
      onlyCodeName = '客户唯一标识' as any;
      uploadFileStatus = false;//上传文件状态
      fileUpLoad = {} as any;
    };
  }, [])

  return (
    <div className={styles.CreateExportBox}>
      <div className={styles.pageTitle}>
        <div className={styles.returnBtn} onClick={() => {navigate(-1);if (type == 1) {
          setStateLabelId(Number(labelId))
        }}}><LeftOutlined style={{ marginRight: 8 }} />返回</div>
        {type == 1 && <span>{flag === "create" ? "新建标签" : flag === "view" ? "查看标签" : flag === "copy" ? "复制标签" : "编辑标签"}</span>}
        {type == 2 && <span>{flag === "create" ? "新建分群" : flag === "view" ? "查看分群" : flag === "copy" ? "复制分群" : "编辑分群"}</span>}
      </div>
      <div className={styles.stepsBox}>
        <Steps
          progressDot
          current={current}
          items={[
            { title: type == 1 ? '标签基础信息设置' : "分群基础信息设置" },
            { title: type == 1 ? '标签规则设置' : "分群规则设置" }
          ]}
        />
      </div>

      <div className={styles.labelBaseInfo} style={{ marginLeft: current === 0 ? '12%' : '24px' }}>
        {current === 0 ? type == 1 ? '标签基本信息' : '分群基本信息' : type == 1 ? '标签规则' : '分群规则'}
        {current === 1 && type == 1 && <span className={styles.exportText}>上传文件，通过客户属性筛选目标客户，对目标客户进行标记
          <Space style={{ marginLeft: '10px' }}>
            <QuestionCircleOutlined onClick={exportExplain} />
          </Space>
        </span>}
      </div>
      {
        current === 0 && type == 2 && <div className={styles.formBox}>
          <Form layout="vertical" form={accountForm}>
            <Form.Item<FieldType>
              label="分群名称"
              name="crowdName"
              rules={[{ required: true, message: "请输入分群名称" }]}
            >
              <Input
                disabled={flag === "view"}
                autoComplete="off"
                showCount
                maxLength={50}
                placeholder="请输入分群名称"
                value={conditions.crowdName}
                onChange={(e) => changeConditions({ crowdName: e.target.value })}
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="更新类型"
              name="updateType"
              rules={[{ required: true, message: "请选择更新类型" }]}
            >
              <Select
                disabled={true}
                placeholder="请选择"
                value={conditions.updateType}
                defaultValue={conditions.updateType}
                options={optionsValue}
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="描述"
              name="crowdDescribe"
              className={styles.labelDescription}
            >
              <TextArea
                disabled={flag === "view"}
                placeholder="请输入描述"
                showCount
                maxLength={100}
                value={conditions.crowdDescribe}
                style={{ height: 100, resize: "none" }}
                onChange={(e) =>
                  changeConditions({ crowdDescribe: e.target.value })
                }
              />
            </Form.Item>
          </Form>
        </div>
      }
      {
        current === 0 && type == 1 && <div className={styles.formBox}>
          <Form layout="vertical" form={accountForm}>
            <Form.Item<FieldType>
              label="标签名称"
              name="labelName"
              rules={[{ required: true, message: "请输入标签名称" }]}
            >
              <Input
                disabled={flag === "view"}
                autoComplete="off"
                showCount
                maxLength={20}
                placeholder="请输入标签名称"
                value={conditions.labelName}
                onChange={(e) => changeConditions({ labelName: e.target.value })}
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="标签类目"
              name="categoryIdStore"
              rules={[{ required: true, message: "请选择标签类目" }]}
            >
              <Cascader
                disabled={flag === "view"}
                // value={conditions.categoryIdStore}
                defaultValue={conditions.categoryIdStore}
                placeholder="请选择标签类目"
                options={optionsValueCateGory}
                onChange={(e) => changeConditions({ categoryIdStore: e })}
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="更新类型"
              name="renewType"
              rules={[{ required: true, message: "请选择更新类型" }]}
            >
              <Select
                disabled={true}
                placeholder="请选择"
                value={conditions.renewType}
                defaultValue={conditions.renewType}
                options={optionsValue}
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="标签描述"
              name="description"
              className={styles.labelDescription}
            >
              <TextArea
                disabled={flag === "view"}
                placeholder=""
                showCount
                maxLength={100}
                style={{ height: 100, resize: "none" }}
                value={conditions.description}
                onChange={(e) =>
                  changeConditions({ description: e.target.value })
                }
              />
            </Form.Item>
          </Form>
        </div>
      }
      {
        current === 1 && <div className={styles.formBox}>
          <Form layout="vertical" form={accountForm}>
            <Form.Item<FieldType>
              label="客户唯一识别码"
              name="dataType"
              rules={[{ required: true, message: "请选择客户唯一识别码" }]}
            >
              <Select
                disabled={flag === "view"}
                placeholder="请选择"
                value={conditions.dataType}
                defaultValue={conditions.dataType}
                options={propertyOptions}
                onChange={(e) =>
                  changeConditions({ dataType: e })
                }
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="上传"
              name="upload"
              rules={[{ required: flag === "create" ? true : false, message: "请上传" }]}
            >
              <div className={styles.downLoadBox}>
                <Upload
                  // {...upload} 
                  disabled={flag === "view"}
                  fileList={fileList}
                  style={{ height: '168px' }}
                  accept=".xlsx,.xls,.csv"
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  // action="http://localhost:3000/upload" // 这里指定本地地址
                  customRequest={CustomRequest}
                >
                  {!uploadFileFinish && <Button icon={<UploadOutlined />} disabled={flag === "view"}>上传</Button>}
                </Upload>
                {flag !== "view" && <div className={styles.modelDownLoad} onClick={toDownLoad}>下载CSV模板</div >}
              </div>
              <div className={styles.upLoadHint}>{upLoadText}</div>
            </Form.Item>
          </Form>
        </div>
      }
      <div className={styles.buttonBox}>
        <Button style={{ marginRight: 8 }} onClick={() => {navigate(-1);if (type == 1) {
          setStateLabelId(Number(labelId))
        }}}>取消</Button>
        {flag === "view" && checkPermissions(CROWD_KEY.UPDATA) && permissionLevel ===2 && <Button type="primary" onClick={type == 1 ? editLabel : editGroup} style={{ marginRight: 8 }}>编辑</Button>}
        {current === 0 && <Button type="primary" onClick={nextStep}>{flag === "view" ? "下一页" : "下一步"}</Button>}
        {current === 1 && <Button type="primary" onClick={() => setCurrent(0)} style={{ marginRight: 8 }}>{flag === "view" ? "上一页" : "上一步"}</Button>}
        {current === 1 && flag !== "view" && <Button type="primary" onClick={() => submit()}>确定</Button>}
        {flag === "view" && type == 2 && <Button type="primary" style={{ marginLeft: 8 }} onClick={() => { setOpenLog(true) }}>
          查看操作日志
        </Button>}
      </div>
      <ExportExplain
        openExportExplain={openExportExplain}
        closeExportExplain={closeExportExplain}
      />
      {/* 操作日志 */}
      <OperateLog
        openLog={openLog}
        getOpenlog={getOpenlog}
        crowInfo={props.dataList.editData}
      />
    </div>
  );
};

export default CreateExport;
