import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
// import type { Dayjs } from 'dayjs';
import { Button, Table , TableColumnsType, Select, Drawer, Space, message, 
  DrawerProps, Switch, Radio, RadioChangeEvent, TimePicker, Dropdown, Divider } from "antd";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import styles from '../../GroupingManagement/UserGroupingList/index.module.scss'
import { DataType, GroupListVO } from '../../../../api/userGroup/type'
import { findCdpLogsApi } from '../../../../api/userGroup/group'
import { LogOperate } from '../../../../api/userGroup/status'
import nameIcon from '../../assets/images/name.png'
import idIcon from '../../assets/images/id.png'
import numIcon from '../../assets/images/num.png'
import statusIcon from '../../assets/images/status.png'
import timeIcon from '../../assets/images/time.png'
import desIcon from '../../assets/images/des.png'
import updataIcon from '../../assets/images/updata.png'
import DownLoadLog from "../../components/DownLoadLog";
import PermissionsDataDrawer from '../../../../component/PermissionsDataDrawer';
const OperateLog = (props: {
  openLog?: boolean,
  getOpenlog?: Function,
  crowInfo?: GroupListVO
}) => {
  const {
    openLog,
    getOpenlog = () => {},
    crowInfo
  } = props;
  dayjs.extend(customParseFormat);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [headerList, setHeaderList] = useState([] as any)
  let icon = {
    name: nameIcon,
    id: idIcon,
    num: numIcon,
    status: statusIcon,
    time: timeIcon,
    des: desIcon,
    updata: updataIcon
  }
  
  //抽屉  true  开启
  const [downLoadLog, setDownLoadLog] = useState(false);
  const [downloadId, setDownloadId] = useState('');
  const [shareDrawer, setShareDrawer] = useState(false); // 分享抽屉
  const [shareButtonTitle, setShareButtonTitle] = useState('');
  const shareButtonTip:any = [];
  const shareEditStatus:any = true;
  const [shareCheckedList, setShareCheckedList] = useState<any>([]);
  const onShareClick = () => {
    console.log(123)
  }
  // 分享抽屉
  const openShareDrawer= (v: boolean,type:string,userList:any[]) => {
    setShareDrawer(v);
    setShareCheckedList(userList)
    switch (type) {
      case 'common':
        setShareButtonTitle('共享记录');
        break;
      case 'share':
        setShareButtonTitle('分享记录');
        break;
      default:
        break;
    }
      };
  // 打开下载抽屉
  const openDownLoadLog = (item:any) => {
    setDownloadId(item.downloadId);
    setDownLoadLog(true);
    onClose()
  };
  // 关闭下载抽屉
  const closeDownLoadLog= (v: boolean) => {
    setDownLoadLog(v);
  };
  const columns: TableColumnsType<DataType> = [
    {
      title: '操作时间',
      dataIndex: 'operateTime',
      key: 'operateTime',
      sorter: true,
      width: 150
    },
    {
      title: '操作人',
      dataIndex: 'operator',
      key: 'operator',
      width: 100
    },
    {
      title: '操作项',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      filters: [
        {
          text: '新建',
          value: 'ADD',
        },
        {
          text: '编辑',
          value: 'UPDATE',
        },
        {
          text: '启用',
          value: 'ENABLE',
        },
        {
          text: '停用',
          value: 'DISABLE',
        },
        {
          text: '删除',
          value: 'DELETE',
        },
        {
          text: '下载',
          value: 'DOWNLOAD',
        },
        {
          text: '上传',
          value: 'UPLOAD',
        },
        {
          text: '数据更新',
          value: 'DATAUPDATE',
        },
        {
          text:'分享',
          value:'PRIVATE_SHARE',
        },
        {
          text:'共享',
          value:'PUBLIC_SHARE',
        },
      ],
      render: (text: string, record: DataType) => `${operationItem(text)}`
    },
    {
      title: '操作详情',
      dataIndex: 'operateDetail',
      key: 'operateDetail',
      width: 200,
      render: (text: string, record: DataType) =>
      <>
        {text && <div dangerouslySetInnerHTML={{ __html: text }}></div>}
        {!text && record.action !=='PUBLIC_SHARE' && record.action !=='PRIVATE_SHARE' && <div>--</div>}
        {/* 只有上传的时候有按钮 需修改 */}
        {record.action == 'UPLOAD' &&  <div className={styles.checkUpload} onClick={()=>openDownLoadLog(record)}>点击查看上传文件</div>}
        {record.action === 'PUBLIC_SHARE' && <div className={styles.checkUpload} onClick={()=> openShareDrawer(true,'common',record.userList)}>共享记录</div>}
        {record.action === 'PRIVATE_SHARE' && <div className={styles.checkUpload} onClick={()=> openShareDrawer(true,'share',record.userList)}>分享记录</div>}
      </>
    },
  ];
  const [logData, setLogData] = useState([] as DataType[]);
  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const headerOperate: any = {};
  // 查询列表
  const findCdpLogsList = async (page: { pageSize: number; pageNum: number }) => {
    const data = {
      pageNum: page.pageNum,
      pageSize: page.pageSize,
      businessId: crowInfo?.crowdId,
      businessType: 'CROWD',
      ...headerOperate
    }
    const res = await findCdpLogsApi({
      ...data
    });
    if (res) {
      setLogData(res.rows)
      setPageQuery({
        pageNum: page.pageNum,
        pageSize: page.pageSize,
        total: res.total
      })
    }
    
    
  }
  useEffect(() => {
    setHeaderList([])
    if (openLog) {
      setHeaderList([
          {
            icon: icon.name,
            label: '分群名称',
            value: crowInfo?.crowdName
          },
          {
            icon: icon.id,
            label: '分群ID',
            value: crowInfo?.crowdId
          },
          {
            icon: icon.num,
            label: '分群人数',
            value: crowInfo?.groupNum? crowInfo?.groupNum : '--'
          },
          {
            icon: icon.status,
            label: '状态',
            value: crowInfo?.enable === 0? '停用' : '启用'
          },
          {
            icon: icon.time,
            label: '创建时间',
            value: crowInfo?.createTime
          },
          {
            icon: icon.des,
            label: '描述',
            value: crowInfo?.crowdDescribe? crowInfo?.crowdDescribe : '--'
          },
          {
            icon: icon.updata,
            label: '更新方式',
            value: crowInfo?.updateType == '0' ? '定时更新' : crowInfo?.updateType == '2' ? '不更新' : crowInfo?.updateType == '3' ? '手动更新' : '--'
          },
        ])
      findCdpLogsList({ pageSize: 10, pageNum: 1 });
      // console.log(headerList, '日志head');
      
    }
  }, [openLog])
  // 关闭抽屉 
  const onClose = () => {
    getOpenlog(false);
  }
  const handleStandardTableChange = (page: any, filters: any, sort: any) => {
    setPageQuery({
      ...pageQuery,
      pageNum: page.current,
      pageSize: page.pageSize
    })
    if (filters.action) {
      headerOperate.action = filters.action
    } else {
      headerOperate.action = []
    }
    if (sort.order) {
      headerOperate.orderType = sort.order === 'ascend'? 'ASC' : 'DESC'
    } else {
      headerOperate.orderType = ''
    }
    findCdpLogsList({ pageSize:page.pageSize, pageNum: page.current });
  }
  // 操作项
  const operationItem = (item: string) => {
    switch (item) {
      case 'ADD':
        return LogOperate.ADD
        break;
      case 'UPDATE':
        return LogOperate.UPDATE
        break;
        case 'ENABLE':
        return LogOperate.ENABLE
        break;
      case 'DISABLE':
        return LogOperate.DISABLE
        break;
        case 'DELETE':
        return LogOperate.DELETE
        break;
      case 'DOWNLOAD':
        return LogOperate.DOWNLOAD
        break;
      case 'UPLOAD':
        return LogOperate.UPLOAD
        break;
      case 'DATAUPDATE':
        return LogOperate.DATAUPDATE
        break;
      default:
        return ''
        break;
    }
  }
  return (
    <div>
      <Drawer
        title='操作日志'
        placement='right'
        width={700}
        onClose={onClose}
        open={openLog}
      >
      <div className={styles.logHeader}>
      {headerList.map((it: any, index: number) => 
        <div key={`${it.label}-${index}`} style={{textAlign: 'left', width: '50%', lineHeight: 'var(--text-font21)'}}>
          <span><img src={it.icon} style={{width: '20px', verticalAlign: '-13%', marginRight: '5px'}}></img></span>
          <span>{it.label}：</span>
          <span>{it.value}</span>
        </div>
      )}
      </div>
      <Table
      // rowKey={record=>record.id} 
      columns={columns} 
      rowKey={(record) => record.id}
      pagination={{
        total: pageQuery.total,
        pageSize: pageQuery.pageSize,
        current: pageQuery.pageNum,
        showTotal: (total) => `共${total}条`,
        showSizeChanger: true,
      }}
      onChange={(page, filters, sort) => handleStandardTableChange(page, filters, sort)}
      dataSource={logData} />
      </Drawer>
      <DownLoadLog
          // searcParam={searcParam}
          title={crowInfo?.crowdName}
          downLoadLog={downLoadLog}
          type={2}
          closeDownLoadLog={closeDownLoadLog}
          crowdId={crowInfo?.crowdId}
          downloadId={downloadId}
          // userInfoType={userInfoType}
          // labelId={labelId}
          // labelValueId={labelValueId}
          // renewTime={renewTime}
          // crowdId={localStorage.getItem('setGroupDetail') ? JSON.parse(localStorage.getItem('setGroupDetail') as any).crowdId : ''}
        />

        <PermissionsDataDrawer
          shareDrawer={shareDrawer}
          setShareDrawer={setShareDrawer}
          shareDisplay={shareEditStatus}
          title={shareButtonTitle}
          tip={shareButtonTip}
          onShareClick={onShareClick}
          shareCheckedList={shareCheckedList}
        />
    </div>
  )
}

export default OperateLog