import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import { useNavigate } from 'react-router-dom'
import { Button, Empty, Modal, Popconfirm, Tooltip, Switch, Tabs, message, Input, ConfigProvider, Dropdown } from 'antd';
import RuleTagTree from './component/Tree';
import ChartData from './component/ChartData';
import TagsData from './component/TagsData';
import OperationLog from './component/OperationLog';
import { getLabelCountApi, changeLabelStatusApi, getLabelInfoDetailApi, labelCheakEditApi , labelUpdata} from '../../../../api/label/label';
import { getPublicShareApi, getPrivateShareApi, getPublicSharedListApi, getPrivateSharedListApi } from '@/api/share/shareAndCommon';
import { state, setStateLabelId, setCheckLabelType } from '../../../../store';
import { FolderOpenOutlined, TagOutlined } from '@ant-design/icons';
import SystemButton from '../../../../component/Button/SystemButton';
import { checkPermissions } from "@/utils/utils";
import { LABEL_KEY } from "@/router/Permissions";
import { getUserClickApi } from '../../../../api/home/home';
import ImportMes from '../../../UserGrouping/components/ImportMes';
import CreateChooseGroup from '../../../UserGrouping/GroupingManagement/CreateChooseGroup';
import PermissionsDataDrawer from '../../../../component/PermissionsDataDrawer';
import type { MenuProps } from 'antd';
const { Search } = Input;

let oldTab = 0;
let selectCategoryObj: any = {};
function Tag() {
  const navigate = useNavigate();
  
  //传到创建分群还是标签的值
  const [chooseGroupData, setChooseGroupData] = useState({ flag: 'create' , type : 1 } as any);
  const [changeBtnStatus, setChangeBtnStatus] = useState(false);
  interface infoType {
    labelId: string;
    labelName: string;
    labelStatus: number;
    creater: string;
    createTime: string;
    createMode: string;
    updater: string;
    updateTime: string;
    renewTime: string;
    description: string;
    renewType: string;
    taskId: string | number;
    calculateStatus: string | number; //计算状态 0: 未计算 1: 计算中 2: 成功 3: 失败
    permissionLevel: number;
  }

  // 标签信息
  const [labelInfos, setLabelInfos] = useState({} as infoType);

  const [usedLabelCount, setUsedLabelCount] = useState(0);

  const ruleTagTreeRef: any = useRef(null);

  //新建标签是否可以点击
  const [canAddLable, setCanAddLabel] = useState(true);

  const [labelType, setLabelType] = useState('1'); //1全部标签 2我创建的

  const [labelId, setLabelId] = useState('');

  const [detailStatus, setDetailStatus] = useState(false);

  const [shareDrawer, setShareDrawer] = useState(false); // 分享抽屉
  const [shareButtonTitle, setShareButtonTitle] = useState('');
  const shareEditStatus:any = false;
  const [shareButtonTip, setShareButtonTip] = useState<any>([]);
  const [shareCheckedList, setShareCheckedList] = useState<any>([]);
  const [shareStatus, setShareStatus] = useState<string>('');
  // 抽屉里的数据
  const onShareClick = async(data:any) => {
    const filteredArray = data.filter((item: any) => typeof item !== 'string');
      switch (shareStatus) {
        case 'common':
          handleShare(getPublicShareApi, { labelId: editData.labelId, targetUserIds: filteredArray },shareStatus);
        break;
        case 'share':
          handleShare(getPrivateShareApi, { labelId: editData.labelId, targetUserIdlist: filteredArray },shareStatus);
        break;
        default:
          break;
      }
  }
  // 保存分享/共享人选中的人群
  const handleShare = async (api: (params: any) => Promise<any>, params: any, shareStatus: string) => {
    const res = await api(params);
    if (res.code === 200) {
      setShareDrawer(false)
      if(shareStatus === 'common'){
        message.success('共享成功');
      }else if(shareStatus === 'share'){
        message.success('分享成功');
      }else{
        message.success('操作成功');
      }
      operationLogRef.current?.pushListData();
    }
  };
  // 查询分享/共享人选中的人群
  const getCheckedList = async (api: (params: any) => Promise<any>, params: any) => {
    const res = await api(params);
    if (res.code === 200) {
      setShareCheckedList(res.data)
    }
  };
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a onClick={()=> openShareDrawer(true,'common')}>共享标签</a>
      ),
    },
    {
      key: '2',
      label: (
        <a onClick={()=> openShareDrawer(true,'share')}>分享标签</a>
      ),
    }
  ];
  // 分享抽屉
  const openShareDrawer= (v: boolean,type:string) => {
    setShareDrawer(v);
    setShareStatus(type)
    switch (type) {
      case 'common':
        setShareButtonTitle('共享标签');
        setShareButtonTip([
          '1、共享人对标签可以进行查看、编辑、删除等操作，维护后的数据同步更新。',
          '2、共享后的标签，被共享人不允许进行再次共享和分享。'
        ]);
        getCheckedList(getPublicSharedListApi, { labelId: editData.labelId});
        break;
      case 'share':
        setShareButtonTitle('分享标签');
        setShareButtonTip([
          '1、相当于创建一个标签副本，分享人和被分享人数据互为隔离，各自维护数据，互不影响。',
          '2、分享后的标签，被分享人允许进行共享和分享。'
        ]);
        setShareCheckedList([])
        // getCheckedList(getPrivateSharedListApi, { labelId: editData.labelId});
        break;
      default:
        break;
    }
  };
  const getLabelInfoDetail = async (id?: string) => {
    if (detailStatus) return;
    setDetailStatus(true);
    const res = await getLabelInfoDetailApi({ labelId: id ?? labelId });
    if (res) {
      getUserClickApi(state.childMenuId, 3, undefined, labelInfos.labelId, state.parentMenuId, state.currentPath);
      setLabelInfos(res.data);
      setEditData(res.data);
      setDetailStatus(false);
    }
  };

  //选择标签
  const selectLabel = (item: any) => {
    setChartType('1');
    setErrStatus(false)
    // setStatusPopConfirm(false);
    setLabelId(item.labelId);
    if (!item.labelId) {
      setLabelInfos({} as infoType);
    } else {
      getLabelInfoDetail(item.labelId);
    }
  };
  //选择树结构
  const changeCate = (t: boolean) => {
    setCanAddLabel(!t);
  };
  //选择类目
  const [catgoryIdStore, setCatgoryIdStore] = useState([] as any); //1全部标签 2我创建的
  const selectCatgory = (item: any) => {
    selectCategoryObj = item;
    const store = [] as number[];
    store.push(selectCategoryObj?.parentId);
    store.push(selectCategoryObj?.key);
    setCatgoryIdStore([...store]);
  };

  //新建标签
  const handleAddLabelEmit = (item: any) => {
    const store = [] as number[];
    store.push(item.parentId);
    store.push(item.key);
    setCatgoryIdStore([...store]);
    navigate('/tages/ruleTags/tags/createLabel', { state: { flag: 'create', catgoryIdStore: store } })
  };

  //编辑标签
  const editLabel = async (item: any) => {
    if (editData.calculateStatus === 1) {
      return message.warning('标签计算中，稍后重试');
    }
    const params = {
      labelId: editData.labelId
    };
    const res = await labelCheakEditApi(params);
    if (res.data) {
      if (editData.createMode == "导入标签") {
        navigate('/tages/ruleTags/tags/CreateExportLabel', { state: { flag: 'edit', editData: editData ,type :1 } })
      } else {
        navigate('/tages/ruleTags/tags/createLabel', { state: { flag: 'edit', editData: editData } })
      }
    }else {
      message.warning("该标签值已被应用，不可进行修改操作");
    };
  };

  // 查看标签详情
  const viewLabel = () => {
    setEditData(labelInfos);
    setStateLabelId(Number(labelInfos.labelId))
    //导入标签  需修改
    if (labelInfos.createMode == '导入标签') {
      navigate('/tages/ruleTags/tags/CreateExportLabel', { state: { flag: 'view', editData: editData , type : 1 }  })
    }else{
      // getUserClickApi(state.childMenuId, 3, undefined, labelInfos.labelId, state.parentMenuId, state.currentPath);
      navigate('/tages/ruleTags/tags/createLabel', { state: { flag: 'view', editData: editData } })
    }
  };

  const clearSearch = () => {
    // searchRef.current.focus();
    // searchRef.current.setValue('')
    // searchRef.current.blur()˝
    setSearchValue('')
  }

  const getLabelCount = async () => {
    const res = await getLabelCountApi();
    setUsedLabelCount(res.data);
  };

  const tagsType: any[] = [
    {
      key: '1',
      label: '全部标签',
      children: (
        <RuleTagTree
          editLabel={editLabel}
          addLabel={handleAddLabelEmit}
          selectCatgory={selectCatgory}
          ref={ruleTagTreeRef}
          selectLabel={selectLabel}
          clearSearch={clearSearch}
          changeCate={changeCate}
          getLabelCount={getLabelCount}></RuleTagTree>

      ),
    },
    {
      key: '2',
      label: '我创建的',
      children: (
        <RuleTagTree
          editLabel={editLabel}
          addLabel={handleAddLabelEmit}
          selectCatgory={selectCatgory}
          ref={ruleTagTreeRef}
          selectLabel={selectLabel}
          clearSearch={clearSearch}
          changeCate={changeCate}
          getLabelCount={getLabelCount}></RuleTagTree>
      ),
    },
  ];



  // 图表组件 ref
  const tagsDataRef: any = useRef(null);
  const chartDataRef: any = useRef(null);
  const operationLogRef: any = useRef(null);

  const [chartType, setChartType] = useState('1');
  // tags展示图表
  const dataTagsType: any[] = [
    {
      key: '1',
      label: '标签值',
      children: <TagsData ref={tagsDataRef} labelId={labelInfos.labelId} calculateStatus={labelInfos.calculateStatus} renewType={labelInfos.renewType} taskId={labelInfos.taskId}></TagsData>,
    },
    ...(
      labelInfos.createMode != '导入标签'?[
        {
          key: '2',
          label: '数据概览',
          children: <ChartData ref={chartDataRef} labelId={labelInfos.labelId} calculateStatus={labelInfos.calculateStatus}></ChartData>,
        },
      ]:[]
    ),
    {
      key: '3',
      label: '操作日志',
      children: <OperationLog 
      ref={operationLogRef} 
      labelId={labelInfos.labelId} 
      calculateStatus={labelInfos.calculateStatus} 
      title={labelInfos.labelName}></OperationLog>,
    },
  ];
  
  // tag/labelInfos变化时重新获取数据画图
  const changeChart = (key?: any) => {
    let type = key ?? chartType;
    oldTab +=1
    if (type === '1') {
      if(oldTab > 0 && oldTab != 0){
        oldTab = -1;
      }

      tagsDataRef.current?.getList({ pageSize: 10, page: 1, sortType: '' });
    } else if (type === '2') {
      chartDataRef.current?.pushListData();
    } else {
      operationLogRef.current?.pushListData();
    }
  };

  useEffect(() => {
    changeChart();
  }, [labelInfos]);
  useEffect(() => {
    if(oldTab < 0 && labelInfos.labelId){
      getLabelInfoDetail()
    }
  }, [oldTab]);



  useEffect(() => {
    // getLabelCount();
    setLabelType(state.checkLabelType.toString())
    if (state.labelId !== 0) {
      setTimeout(() => {
        ruleTagTreeRef.current!.getCategoryList(state.checkLabelType === '1' ? '' : state.user.userId.toString(), true, { labelId: state.labelId });
        setStateLabelId(0);
        setCheckLabelType("1")
      }, 100);
    } else {
      setTimeout(() => {
        ruleTagTreeRef.current!.getCategoryList(state.checkLabelType === '1' ? '' : state.user.userId.toString());
      }, 100);
    }
    // 根据权限判断启用/禁用按钮是否展示
    if (
      !(
        state.permissions.findIndex((item: string) => item === '*:*:*') < 0 &&
        state.permissions.findIndex((item: string) => item === LABEL_KEY.UPDATE_LABEL) < 0
      )
    ) {
      setChangeBtnStatus(true);
    }
  }, []);

  // 新建类目
  const handleAddCateClick = () => {
    ruleTagTreeRef.current!.addCate();
  };

  const [chooseGroup, setChooseGroup] = useState(false); // 选择新建标签弹窗
  // 新建标签
  const [flag, setFlag] = useState(''); // 支持新建create、编辑edit、查看view
  const handleAddLabelClick = () => {
    if (usedLabelCount >= 300) {
      message.error('标签总额度达到上限，请删除不常用或未被应用标签');
    } else {
      console.log('创建标签状态');

      console.log(labelType);
      setStateLabelId(Number(labelInfos.labelId))
      setCheckLabelType(labelType)
      setChooseGroupData ({ flag: 'create', catgoryIdStore: catgoryIdStore , type : 1 } )
      setChooseGroup(true)
      // navigate('/userGrouping/groupingManagement/CreateChooseGroup', { state: { flag: 'create', catgoryIdStore: catgoryIdStore , type : 1 }  })
      // navigate('/tages/ruleTags/tags/createLabel', { state: { flag: 'create', catgoryIdStore: catgoryIdStore } });
      setFlag('create');
    }
  };
  // 关闭下载抽屉
  const colseChooseGroup= (v: boolean) => {
    setChooseGroup(v);
  };
  // 编辑
  const [editData, setEditData] = useState({} as any);

  // 操作成功
  // const operateSuccess = (val: any) => {
  //   getLabelInfoDetail(val.labelId);
  //   getLabelCount();

  //   ruleTagTreeRef.current!.getCategoryList(labelType === '1' ? '' : state.user.userId.toString(), true, val);
  // };

  // 修改标签状态二次确认框
  const [statusPopConfirm, setStatusPopConfirm] = useState(false);
  //切换全部标签/我创建的标签
  const onLabelTagChange = (activeKey: string) => {
    setLabelType(activeKey);
    setTimeout(() => {
      ruleTagTreeRef.current!.getCategoryList(activeKey === '1' ? '' : state.user.userId.toString());
    }, 100);
  };

  const handleCancelClickPop = () => {
    setStatusPopConfirm(false);
  };
  // 确认连点限制
  let statusConfirm = false;
  const saveInfo = async () => {
    let param = {
      labelId: labelInfos.labelId,
      labelStatus: labelInfos.labelStatus === 1 ? 0 : 1,
      isFinalConfirm: true,
    };
    if (statusConfirm) {
      return
    }
    statusConfirm = true
    const res = await changeLabelStatusApi(param);
    if (res) {
      getUserClickApi(state.childMenuId, 3, undefined, labelInfos.labelId, state.parentMenuId, state.currentPath);
      if (res.data.result === 0 && res.data.usedInfos.length > 0 && labelInfos.labelStatus === 1) {
        // setErrInfo(res.data.usedInfos);
        // setErrStatus(true);
        message.error('该标签及标签值正在被应用，不允许禁用')
      } else if (res.data.result === 1) {
        setLabelInfos({
          ...labelInfos,
          labelStatus: labelInfos.labelStatus === 1 ? 0 : 1,
        });
      }
      getLabelInfoDetail();
      setErrStatus(false)
      statusConfirm = false
      // setStatusPopConfirm(false);
    } else {
      setErrStatus(false);
    }
  };

  const { confirm } = Modal
  const [showInfo, setShowInfo] = useState(false);
  const [errInfo, setErrInfo] = useState([] as any);
  const [errStatus, setErrStatus] = useState(false);

  // 第一次修改标签状态
  const changeLabelStatus = async () => {
    if (labelInfos.createMode == '导入标签' && editData.calculateStatus === 1) {
      message.warning('当前标签任务计算中，标签状态暂不能修改')
      return
    }
    let param = {
      labelId: labelInfos.labelId,
      labelStatus: labelInfos.labelStatus === 1 ? 0 : 1,
      isFinalConfirm: false,
    };
    // 启用->禁用
    if (labelInfos.labelStatus === 1) {
      // setErrStatus(true)
      // setStatusPopConfirm(true);
      confirm({
        title: '确定禁用吗?',
        content: '禁用后，将停止计算及无法应用该标签及标签值',
        onOk: () => {
          saveInfo()
        }
      })
      return;
    }
    const res = await changeLabelStatusApi(param);
    // console.log(res);
    // 禁用->启用
    // console.log(param, 0);
    if (res) {
      getUserClickApi(state.childMenuId, 3, undefined, labelInfos.labelId, state.parentMenuId, state.currentPath);
      if (res.data.result) {
        setLabelInfos({
          ...labelInfos,
          labelStatus: labelInfos.labelStatus === 1 ? 0 : 1,
        });
        getLabelInfoDetail();
        setErrStatus(false)
        // setStatusPopConfirm(false);
      } else {
        setErrStatus(true)
        // setStatusPopConfirm(true);
      }
    }
  };
  //数据更新
  const dataUpdata = async () => {
    let param = {
      labelId: labelInfos.labelId,
    };
    const res = await labelUpdata(param);
    if (res) {
      message.success('数据更新成功')
      getLabelInfoDetail()
    }
  };

  const onSearch = (e: any) => {
    console.log(e);
    ruleTagTreeRef.current!.searchLable(e)
  }

  const searchRef: any = useRef(null);
  const [searchValue, setSearchValue] = useState('')
  const searchChange = (e: any) => {
    setSearchValue(e.target.value)
  }
  //抽屉  true  开启导入文件
  const [openImportMes, setOpenImportMes] = useState(false);
  //手动更新
  const handelUpdata = () => {
    if (editData.calculateStatus === 1) {
      message.error('标签计算中，请稍后重试');
    } else if (labelInfos.createMode == '导入标签') {
      setOpenImportMes(true)
    } else {
      dataUpdata()
      // 规则类数据更新
    }
  }
  // 关闭手动更新抽屉
  const closeOpenImportMes = (v: boolean) => {
    setOpenImportMes(v);
  };
  // 数据更新追加之后重新调取接口
  const handleAddFile = () => {
    getLabelInfoDetail()
  };
  return (
    <div className={style['container']}>
      <div className={style['left-aside']}>
        <div className={style['left-aside-container']}>
          <div className={style['tree-buttons']}>
            <SystemButton
              icon={<FolderOpenOutlined />}
              style={{ width: '105px', margin: 4 }}
              onClick={handleAddCateClick}
              title="新建类目"
              permissions={LABEL_KEY.ADD_CATEGORY}></SystemButton>
            <SystemButton
              icon={<TagOutlined />}
              type="primary"
              style={{ width: '105px', margin: 4 }}
              onClick={handleAddLabelClick}
              disabled={canAddLable}
              title="新建标签"
              permissions={LABEL_KEY.ADD_LABEL}></SystemButton>
            {/* <Button icon={<FolderOpenOutlined />} style={{ width: '105px', margin: 4 }} onClick={handleAddCateClick}>新建类目</Button> */}
            {/* <Button icon={<TagOutlined />} type="primary" style={{ width: '105px', margin: 4 }} onClick={handleAddLabelClick} disabled={canAddLable}>新建标签</Button> */}
          </div>
          <Search value={searchValue} onChange={searchChange} ref={searchRef} placeholder="请输入标签名称" onSearch={onSearch} style={{ width: 218, margin: '16px 0' }} />
          <ConfigProvider
            theme={{
              components: {
                Tabs: {
                  /* 这里是你的组件 token */
                  // cardPadding: '8px 25px'
                },
              },
            }}
          >
            <Tabs
              activeKey={labelType}
              destroyInactiveTabPane={true}
              onChange={onLabelTagChange}
              tabBarGutter={2}
              type="card"
              items={tagsType}
              style={{ width: '218px' }}
            />
          </ConfigProvider>
        </div>
        {/* <RuleTagTree ref={ruleTagTreeRef}></RuleTagTree> */}
        {/* <span className={style['tag-total']}>
          总额度使用：<span>{usedLabelCount}</span>/300
        </span> */}
      </div>
      <div className={style['content']}>
        {labelInfos.labelId && (
          <div className={style['header']}>
            <div className={style['header-left']}>
              <div className={'Page_header-title'}>{labelInfos.labelName}</div>
              <div className={style['header-left-button']}>
                {checkPermissions(LABEL_KEY.UPDATE_LABEL) && <Switch
                  checkedChildren="启用"
                  unCheckedChildren="禁用"
                  defaultChecked
                  value={!!labelInfos.labelStatus}
                  onChange={(checked, event) => {
                    changeLabelStatus();
                  }}
                />}

                <Popconfirm
                  placement="top"
                  title={`确定${!!labelInfos.labelStatus ? '禁用' : '启用'}吗?`}
                  description={
                    !!labelInfos.labelStatus ? `禁用后，将停止计算及无法应用该标签及标签值` : '当前标签的标签规则中包含已禁用标签，数据可能会不准'
                  }
                  onConfirm={() => saveInfo()}
                  onCancel={handleCancelClickPop}
                  open={statusPopConfirm}
                  onPopupClick={e => {
                    e.stopPropagation();
                  }}></Popconfirm>
              </div>
            </div>
            <div>
              {/* 数据更新 需修改 */}
              { labelInfos.permissionLevel === 2 && labelInfos.renewType == '3' && <Button  onClick={handelUpdata} style={{marginRight:'10px'}}>数据更新</Button>}
              <SystemButton title="查看标签详情" onClick={viewLabel} permissions={LABEL_KEY.SHOW_LABEL} />
              { labelInfos.permissionLevel === 2 && 
                <Dropdown menu={{ items }} placement="bottom" trigger={['click']}>
                  <SystemButton title="授权" style={{marginLeft:'10px'}} /> 
                </Dropdown>
              }
            </div>
          </div>
        )}
        {!labelInfos.labelId && (
          <div style={{ marginTop: '10vh' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
        {labelInfos.labelId && (
          <>
            <div className={style['header-tag']}>
              <div className={style['header-tag-info']}>创建人：{labelInfos.creater}</div>
              <div className={style['header-tag-info']}>创建时间：{labelInfos.createTime}</div>
              <div className={style['header-tag-info']}>创建方式：{labelInfos.createMode}</div>
              <div className={style['header-tag-info']}>修改人：{labelInfos.updater}</div>
              <div className={style['header-tag-info']}>修改时间：{labelInfos.updateTime}</div>
              <Tooltip placement="topLeft" title={labelInfos.description} >
                  <div className={style['header-tag-info']} style={{display: 'flex'}}>
                    <div>标签描述：</div>
                    <div style={{width: 'calc(100% - 120px)'}} className='line-text'>{labelInfos.description}</div>
                  </div> 
                </Tooltip>
              <div className={style['header-tag-info']}>更新方式：{labelInfos.renewType == '3' ? '手动更新' :'定时更新'}</div>
            </div>
            <Tabs
              className={style['content_tabs']}
              activeKey={chartType}
              onChange={key => {
                setChartType(key);
                changeChart(key);
              }}
              tabBarGutter={50}
              items={dataTagsType}></Tabs>
          </>
        )}
      </div>
      <Modal
        title='确认弹窗'
        open={errStatus}
        // footer={null}
        onOk={() => {
          saveInfo()
        }}
        onCancel={() => {
          setErrStatus(false);
        }}>
        {/* <p>该标签及标签值正在被应用，不允许禁用</p>
        <Button onClick={() => setShowInfo(!showInfo)}>{showInfo ? '隐藏' : '显示'}</Button>
        {showInfo && (
          <p>
            {errInfo.map((item: any, index: number) => (
              <span key={index}>
                {item.name}
                {index < errInfo.length - 1 ? ',' : ''}
              </span>
            ))}
          </p>
        )} */}
        <p style={{ textAlign: 'center', fontSize: 'var(--text-font7)', fontWeight: 'bold' }}>{`确定${!!labelInfos.labelStatus ? '禁用' : '启用'}吗?`}</p>
        <p style={{ textAlign: 'center' }}>{
          !!labelInfos.labelStatus ? `禁用后，将停止计算及无法应用该标签及标签值` : '当前标签的标签规则中包含已禁用标签，数据可能会不准'
        }</p>
      </Modal>
      <ImportMes
          openImportMes={openImportMes}
          closeOpenImportMes={closeOpenImportMes}
          popType={1}
          fileTitle={labelInfos.labelName}
          labelId={labelInfos.labelId}
          handleAddFile={handleAddFile}
      />
      <CreateChooseGroup
          chooseGroupData={chooseGroupData}
          chooseGroup={chooseGroup}
          colseChooseGroup={colseChooseGroup}
        />
      <PermissionsDataDrawer
        shareDrawer={shareDrawer}
        setShareDrawer={setShareDrawer}
        shareDisplay={shareEditStatus}
        title={shareButtonTitle}
        tip={shareButtonTip}
        onShareClick={onShareClick}
        shareCheckedList={shareCheckedList}
      />
    </div>
  );
}

export default Tag;
