import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Row, Col, Input, Space, Select, DatePicker, Checkbox, Radio, message } from "antd";
import type { SelectProps } from "antd/lib";
import { LeftOutlined } from "@ant-design/icons";
import ModuleTitle from "@/component/ModuleTitle";
import IndustryApi from '@/crm/api/dataBoardApi/industry';
import dayjs from "dayjs";
import {
  useGetChannelList,
  useGetShoplList,
} from "@/crm/assets/public/dataBoard";
import type { Dayjs } from "dayjs";
import styles from "./index.module.scss";

const { RangePicker } = DatePicker;

type FieldType = {
  analysisName: string;
  platform: string[];
  shop: string[];
  date: [start: Dayjs | null, end: Dayjs | null];
  analysisDimension: 'single' | 'category';
  dateRange: 5 | 10;
  analysisContent: ('tdld' | 'kdld' | 'lkhg' | 'xkeg')[];
};

// 传入数组返回对应字符串逗号分隔
const getArrString = (list: string[], disabledText: string) => {
  const key: string =
    list.length > 1
      ? list.filter((e: string) => e !== disabledText).join(",") || ""
      : list.join(",");
  const channelKey =
    list.includes(disabledText) &&
      list.findIndex((e: string) => e === disabledText) !== 0
      ? disabledText
      : key;
  return channelKey;
};

const defaultCreateInfo: FieldType = {
  analysisName: '',
  platform: ['all'],
  shop: ['0'],
  date: [null, null],
  analysisDimension: 'single',
  dateRange: 5,
  analysisContent: ['tdld'],
};

const Create: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { channelAllList } = useGetChannelList('member');
  const { shopAllList, getShopList } = useGetShoplList('member');
  const [formData, setFormData] = useState<FieldType>(defaultCreateInfo);
  const onBack = () => {
    navigate("/DataBoard/Industry/ProductSegmentation");
  };
  const onSubmit = () => {
    const params = {
      analysisName: formData.analysisName,
      channels: formData.platform.includes('all') ? channelAllList.map(i => i.value).slice(1) : formData.platform,
      shops: formData.shop.includes('0') ? shopAllList.map(i => i.value).slice(1) : formData.shop,
      startDate: formData.date[0]?.format("YYYY-MM-DD HH:mm:ss") || "",
      endDate: formData.date[1]?.format("YYYY-MM-DD HH:mm:ss") || "",
      analysisDim: formData.analysisDimension,
      analysisRange: formData.dateRange,
      analysisContent: formData.analysisContent.join(","),
    };
    IndustryApi.createProductSegmentation(params).then((res) => {
      if (res.code === 200) {
        message.success("创建成功");
        navigate("/DataBoard/Industry/ProductSegmentation");
      }
    });
  };
  return (
    <div className={styles.Create}>
      <div className={styles.header}>
        <div className={styles["BackList_title"]} onClick={onBack}>
          <LeftOutlined />
          <span className={styles["BackList_text"]}>返回</span>
        </div>
        <ModuleTitle mark="large" title="新建分析报告" />
      </div>
      <div className={styles.main}>
        <Form
          autoComplete="off"
          className={styles.form}
          form={form}
          onFinish={onSubmit}
          labelCol={{ span: 3 }}
        >
          <Row gutter={16} wrap style={{ marginTop: 24 }}>
            <Col span={24}>
              <Form.Item
                name="analysisName"
                label="分析名称"
                hasFeedback
                validateTrigger="onBlur"
                // 增加失去焦点校验
                rules={[{
                  required: true,
                  validator: async (_rule, value) => {
                    if (!value) {
                      return Promise.reject('请输入分析报告名称!');
                    }
                    const res = await IndustryApi.checkAnalysisName({ analysisName: value });
                    if (res.data) {
                      return Promise.reject('分析名称重复');
                    }
                    return Promise.resolve();
                  }
                }]}
              >
                <Input
                  placeholder="请输入分析报告名称"
                  value={formData.analysisName}
                  showCount
                  maxLength={50}
                  onChange={
                    (e) => {
                      setFormData({
                        ...formData,
                        analysisName: e.target.value,
                      });
                    }
                  } />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="渠道">
                <div style={{ display: 'flex', gap: 16 }}>
                  <Form.Item noStyle>
                    <Select
                      placeholder="请选择平台"
                      maxTagCount={1}
                      maxTagPlaceholder={() => '...'}
                      mode="multiple"
                      options={channelAllList}
                      value={formData.platform}
                      onChange={(platform) => {
                        if (platform.length === 0) {
                          message.error('请至少选择一个平台');
                          return;
                        }
                        if (platform.length === channelAllList.length - 1) {
                          setFormData({
                            ...formData,
                            platform: ["all"],
                            shop: ["0"],
                          });
                          return;
                        }
                        const channelKey = getArrString(platform, "all");
                        getShopList(null, channelKey || "all");
                        setFormData({
                          ...formData,
                          platform: channelKey.split(","),
                          shop: ["0"],
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item noStyle>
                    <Select
                      placeholder="请选择店铺"
                      mode="multiple"
                      maxTagCount={1}
                      maxTagPlaceholder={() => '...'}
                      options={shopAllList}
                      value={formData.shop}
                      onChange={(shop) => {
                        if (shop.length === 0) {
                          message.error('请至少选择一个店铺');
                          return;
                        }
                        const filterList = shop.filter(e=>e !== '0')
                        if (filterList.length === shopAllList.length - 1) {
                          setFormData({
                            ...formData,
                            shop: ["0"],
                          });
                          return;
                        }
                        const shopKey = getArrString(filterList, "0");
                        setFormData({ ...formData, shop: shopKey.split(",") });
                      }}
                    />
                  </Form.Item>
                </div>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="时间周期" name="date" rules={[{ required: true, message: '请选择时间周期!' }]}>
                <RangePicker
                  value={formData.date}
                  disabledDate={(current) => current && current > dayjs().subtract(1, 'day').endOf('day')}
                  onChange={(value) => {
                    if (!value) {
                      return;
                    }
                    setFormData({
                      ...formData,
                      date: value,
                    });
                  }}
                  style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="分析维度">
                <Radio.Group value={formData.analysisDimension} onChange={(value) => {
                  setFormData({
                    ...formData,
                    analysisDimension: value.target.value,
                  });
                }}>
                  <Radio value="single">单品</Radio>
                  <Radio value="category">类目</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="维度范围">
                <Select value={formData.dateRange} onChange={(value) => {
                  setFormData({
                    ...formData,
                    dateRange: value,
                  });
                }}>
                  <Select.Option value={5}>Top5</Select.Option>
                  <Select.Option value={10}>Top10</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="分析内容">
                <Checkbox.Group value={formData.analysisContent} onChange={(value) => {
                  if (value.length === 0) {
                    message.error('请至少选择一个分析内容');
                    return;
                  }
                  setFormData({
                    ...formData,
                    analysisContent: value,
                  });
                }}>
                  <Checkbox value="tdld">同单连带（一单）</Checkbox>
                  <Checkbox value="kdld">跨单连带（一人）</Checkbox>
                  <Checkbox value="lkhg">老客回购</Checkbox>
                  <Checkbox value="xkeg">新客首单</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="&nbsp;" colon={false}>
                <Space size="small">
                  <Button onClick={onBack}>取消</Button>
                  <Button type="primary" htmlType="submit">
                    提交
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default Create;