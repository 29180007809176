// 新增分享共享功能的接口
// jinmengqi

import request from '../network'
// 获得分享的组织架构树
export function getDeptUserTreeListApi() {
  return request({
    url: '/system/system/shop/getDeptUserTree',
    method: 'get',
});
}

// 共享标签的保存
export function getPublicShareApi(data: any) {
  return request({
      url: '/label/label/publicShare',
      method: 'post',
      data: data
  });
}

// 分享标签的保存
export function getPrivateShareApi(data: any) {
  return request({
      url: '/label/label/privateShare',
      method: 'post',
      data: data
  });
}

// 查询当前标签被分享过的用户ID列表
export function getPrivateSharedListApi(query: any) {
  return request({
      url: '/label/label/privateSharedList',
      method: 'get',
      params: query
  });
}

// 查询当前标签被共享过的用户ID列表
export function getPublicSharedListApi(query: any) {
  return request({
      url: '/label/label/publicSharedList',
      method: 'get',
      params: query
  });
}

// 共享分群的保存
export function getPublicShareCrowdApi(data: any) {
  return request({
      url: '/label/crowd/publicShare',
      method: 'post',
      data: data
  });
}

// 分享分群的保存
export function getPrivateShareCrowdApi(data: any) {
  return request({
      url: '/label/crowd/privateShare',
      method: 'post',
      data: data
  });
}

// 查询当前分群被分享过的用户ID列表
export function getPrivateSharedCrowdListApi(query: any) {
  return request({
      url: `/label/crowd/${query}/getPrivateShareUserList`,
      method: 'get',
  });
}

// 查询当前分群被共享过的用户ID列表
export function getPublicSharedCrowdListApi(query: any) {
  return request({
      url: `/label/crowd/${query}/getPublicShareUserList`,
      method: 'get',
  });
}