import { useEffect, useRef, useState } from "react";
import style from "./index.module.scss";
import { getGrowthValueListApi } from "../../../../api/recordManagement";
import { Checkbox, Table } from "antd";
import {
  StickText,
  TextColorTypes,
  TitleWithExpansion,
  useGetMemberCardList,
  useGetMemberLevelList,
  NumberStatus,
  LinesText,
} from "../../../../component/MiniStyles";
import FilterView from "../../../../component/FilterView";
import { permissionMap } from "../../../../assets/public/pageMap";

import { GrowthItem } from "../../../../api/recordManagement/types";
import checkPermission from "@/crm/assets/public/checkPermission";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

type FieldType = {
  memberCardId: string;
  changeType?: string;
  afterGrade?: string;
  beforeGrade?: string;
  startTime?: string;
  endTime?: string;
  searchType?: string;
  searchValue?: string;
};

const defaultTableInfo = {
  pageNum: 1,
  pageSize: 10,
  total: 0,
};

function GrowthChangeRecord() {
  const { memberCardAllOptions } = useGetMemberCardList();
  const navigate = useNavigate();
  const defaultFormValue = {
    memberCardId: memberCardAllOptions[0]?.value || "",
    changeType: "",
    afterGrade: "",
    beforeGrade: "",
    startTime: "",
    endTime: "",
    searchType: "PHONE",
    searchValue: "",
  };
  const [handFlag, setHandFlag] = useState<boolean>(false);
  const [tableList, setTableList] = useState<GrowthItem[]>([]);
  const [searchInfo, setSearchInfo] = useState<FieldType>(defaultFormValue);
  const [searchFlag, setSearchFlag] = useState<boolean>(false);
  const [tableInfo, setTableInfo] = useState(defaultTableInfo);
  const [formMemberCardId, setFormMemberCardId] = useState("");
  const [levelList, setLevelList] = useState<any[]>([]);
  const { getMemberLevelList } = useGetMemberLevelList();
  const filterViewRef = useRef<any>(null);

  const [searchList, setSearchList] = useState([
    {
      searchType: "string",
      searchComponentType: "select",
      placeHolder: "请选择",
      title: "会员卡",
      key: "memberCardId",
      options: memberCardAllOptions,
    },
    {
      searchType: "string",
      searchComponentType: "select",
      placeHolder: "请选择",
      title: "类型",
      key: "changeType",
      options: [
        {
          value: "",
          label: "全部类型",
        },
        {
          value: "ADD",
          label: "增加",
        },
        {
          value: "SUB",
          label: "减少",
        },
      ],
    },
    {
      searchType: "string",
      searchComponentType: "select",
      placeHolder: "请选择",
      title: "变更前等级",
      key: "beforeGrade",
      options: [
        {
          value: "type1",
          label: "类型1",
        },
      ],
    },
    {
      searchType: "string",
      searchComponentType: "select",
      placeHolder: "请选择",
      title: "变更后等级",
      key: "afterGrade",
      options: [
        {
          value: "type1",
          label: "类型1",
        },
      ],
    },
    {
      searchType: "text",
      searchComponentType: "dateRange",
      placeHolder: "",
      title: "变更时间",
      key: "startTime-endTime",
    },
    {
      searchType: "string",
      searchComponentType: "input",
      // options: searchIdList,
      placeHolder: "请输入",
      key: "searchValue",
      title: "OneID",
      // selectInputKey: "searchType",
    },
  ]);

  // 获得table列表
  const getTableList = async () => {
    const { pageNum, pageSize } = tableInfo;
    const res = await getGrowthValueListApi({
      ...searchInfo,
      pageNum,
      pageSize,
      handFlag,
    });
    if (res?.code === 200) {
      setTableList(res.data?.records || []);
      setTableInfo({ ...tableInfo, total: res.data?.total || 0 });
      setLevelList(searchList[2].options);
    }
  };

  // 查询
  const filterFinish = (data: any) => {
    const { startTime = "", endTime = "" } = data;
    setSearchInfo({ ...data, startTime, endTime, searchType: "ONE_ID" });
    setSearchFlag((e) => !e);
    setTableInfo({ ...tableInfo, pageNum: 1 });
  };

  const onFormChange = (e: any) => {
    if (e[0].name[0] === "memberCardId") {
      setFormMemberCardId(e[0].value);
      const obj = {
        ...filterViewRef.current.getFormInfo(),
        afterGradeCode: "",
        beforeGradeCode: "",
      };
      filterViewRef.current.handChangeForm(obj);
    }
  };

  // 等级下拉框
  const getLevelList = async () => {
    let res: any[] = [];
    if (formMemberCardId || searchInfo.memberCardId) {
      res = await getMemberLevelList(
        formMemberCardId || searchInfo.memberCardId
      );
    }
    const arr = JSON.parse(JSON.stringify(searchList));
    arr[2].options = [{ value: "", label: "全部等级" }, ...res];
    arr[3].options = [{ value: "", label: "全部等级" }, ...res];
    setSearchList(arr);
    if (!levelList.length) {
      setTimeout(() => {
        setLevelList(res);
      }, 10);
    }
  };

  // 点击oneid跳转 客户详情页面
  const gotoDetail = (id: string) => {
    if (!id) return;
    navigate("/userInsight/userInsightDocument/UserDetail");
    localStorage.setItem("oneId", id);
    localStorage.setItem("loyaltySearchInfo", JSON.stringify(searchInfo));
    localStorage.setItem("loyaltyTableInfo", JSON.stringify(tableInfo));
    localStorage.setItem("loyaltySearchStatus", filterViewRef.current.arrow);
  };

  // 回显搜索部分
  const handInitSearch = () => {
    if (localStorage.getItem("oneId")) {
      if (localStorage.getItem("loyaltySearchInfo")) {
        const obj =
          JSON.parse(localStorage.getItem("loyaltySearchInfo") || "") ||
          defaultFormValue;
        filterViewRef.current.handChangeForm({
          ...obj,
          "startTime-endTime": obj.startTime
            ? [dayjs(obj.startTime), dayjs(obj.endTime)]
            : "",
        });
        localStorage.getItem("loyaltySearchStatus") === "true" &&
          filterViewRef.current.showFilterView();
        setSearchInfo(obj);
      }
      if (localStorage.getItem("loyaltyTableInfo")) {
        const obj =
          JSON.parse(localStorage.getItem("loyaltyTableInfo") || "") ||
          defaultTableInfo;
        // setTableInfo({ ...tableInfo, pageNum: obj.pageNum });
        tableInfo.pageNum = obj.pageNum;
      }
      localStorage.removeItem("oneId");
      localStorage.removeItem("loyaltySearchInfo");
      localStorage.removeItem("loyaltyTableInfo");
      localStorage.removeItem("loyaltySearchStatus");
    }
  };


  useEffect(() => {
    if (memberCardAllOptions.length > 0) {
      const obj = {
        ...searchInfo,
        memberCardId: memberCardAllOptions[0]?.value || "",
      };
      const arr = JSON.parse(JSON.stringify(searchList));
      arr[0].options = memberCardAllOptions;
      setSearchList(arr);
      if (localStorage.getItem("oneId")) {
        handInitSearch();
        return;
      }
      tableInfo.pageNum = 1;
      setSearchInfo(obj);
      filterViewRef.current.handChangeForm(obj);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(memberCardAllOptions)]);

  // 获得等级下拉框
  useEffect(() => {
    getLevelList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMemberCardId, searchInfo.memberCardId]);

  useEffect(() => {
    getTableList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableInfo.pageNum,
    tableInfo.pageSize,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(searchInfo),
    handFlag,
    searchFlag,
  ]);

  // 表格部分
  const TableSection = () => {
    return (
      <div>
        <div
          style={{ width: "100%", textAlign: "right", marginBottom: "12px" }}>
          <Checkbox
            checked={handFlag}
            onChange={(e) => {
              setHandFlag(e.target.checked);
            }}>
            仅看手动变更
          </Checkbox>
        </div>
        <Table
          scroll={{ x: 1366 }}
          dataSource={tableList}
          rowClassName={style["table-row"]}
          pagination={{
            current: tableInfo.pageNum,
            pageSize: tableInfo.pageSize,
            total: tableInfo.total,
            onChange: (pageNo, pageSize) =>
              setTableInfo({
                ...tableInfo,
                pageNum: pageNo,
                pageSize,
              }),
            showSizeChanger: true,
            showTotal: (total) => `共 ${total} 条`,
          }}>
          <Table.Column
            width={300}
            title="OneID"
            dataIndex="oneId"
            fixed="left"
            render={(value) => (
              <div style={{color:'var(--text-font-color7)'}}>
                <StickText
                  text={value}
                  onClick={() => gotoDetail(value)}
                  show={checkPermission(permissionMap.get("成长值变更记录复制"))}
                />
              </div>
            )}
          />
          <Table.Column width={200} title="变更时间" dataIndex="createTime" />
          <Table.Column
            width={100}
            title="类型"
            dataIndex="changeTypeLabel"
            render={(value, record: GrowthItem) => (
              <TextColorTypes
                text={value}
                status={record.changeType === "ADD"}
              />
            )}
          />
          <Table.Column
            width={110}
            title="变更量"
            dataIndex="changeQuantity"
            render={(value, record: any) => (
              <NumberStatus
                number={value}
                status={record.changeType === "ADD"}
              />
            )}
          />
          <Table.Column
            width={150}
            title="变更前等级"
            dataIndex="beforeGrade"
            render={(value) => `VIP${value}` || "--"}
          />
          <Table.Column
            width={150}
            title="变更后等级"
            dataIndex="afterGrade"
            render={(value) => `VIP${value}` || "--"}
          />
          <Table.Column width={200} title="变更原因" dataIndex="changeCause" />
          <Table.Column
            width={350}
            title="备注"
            dataIndex="remark"
            render={(value) => <LinesText>{value || "--"}</LinesText>}
          />
        </Table>
      </div>
    );
  };

  return (
    <div className={style["GrowthChangeRecord-bgd"]}>
      {/* 标题 */}
      <TitleWithExpansion
        title="成长值变更记录"
        searchPermissions={permissionMap.get("成长值变更记录查看")}
        onChange={() => filterViewRef.current.showFilterView()}>
        {/* 搜索部分 */}
        <FilterView
          ref={filterViewRef}
          searchList={searchList}
          defaultFormValue={defaultFormValue}
          finish={filterFinish}
          onFormChange={onFormChange}></FilterView>
      </TitleWithExpansion>

      {/* 表格部分 */}
      <TableSection />
    </div>
  );
}

export default GrowthChangeRecord;
