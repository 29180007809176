import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { PlusOutlined, LeftOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Steps,
  message,
  Switch,
  Radio,
  RadioChangeEvent,
  TimePicker,
  Dropdown,
  Cascader,
} from "antd";
import { useNavigate, useLocation } from 'react-router-dom'
import customParseFormat from "dayjs/plugin/customParseFormat";
import SortableList from "../CreateLabel/component/SortableList";
import TimeSelect from "../CreateLabel/component/TimeSelect";
import {
  queryListApi,
  labelCheakEditApi,
  createLabelApi,
  updateLabelApi,
} from "../../../../api/label/label";
import { createLabelQuery } from "../../../../api/label/types";
import { getUserClickApi } from '../../../../api/home/home';
import CreatePrompt from "../CreateLabel/component/CreatePrompt";
import styles from "./index.module.scss";

import { state } from "../../../../store";

import SystemButton from "@/component/Button/SystemButton";
import { checkPermissions } from "@/utils/utils";
import { LABEL_KEY } from "@/router/Permissions";
import { setStateLabelId } from '../../../../store';

const { TextArea } = Input;

const radioGroupOptions = [
  { label: "每天", value: 0 },
  { label: "每周", value: 1 },
  { label: "每月", value: 2 },
];

const weekOptions = [
  { label: "星期一", value: "1" },
  { label: "星期二", value: "2" },
  { label: "星期三", value: "3" },
  { label: "星期四", value: "4" },
  { label: "星期五", value: "5" },
  { label: "星期六", value: "6" },
  { label: "星期日", value: "7" },
];

const monthOptions = [
  { label: "1号", value: "1" },
  { label: "2号", value: "2" },
  { label: "3号", value: "3" },
  { label: "4号", value: "4" },
  { label: "5号", value: "5" },
  { label: "6号", value: "6" },
  { label: "7号", value: "7" },
  { label: "8号", value: "8" },
  { label: "9号", value: "9" },
  { label: "10号", value: "10" },
  { label: "11号", value: "11" },
  { label: "12号", value: "12" },
  { label: "13号", value: "13" },
  { label: "14号", value: "14" },
  { label: "15号", value: "15" },
  { label: "16号", value: "16" },
  { label: "17号", value: "17" },
  { label: "18号", value: "18" },
  { label: "19号", value: "19" },
  { label: "20号", value: "20" },
  { label: "21号", value: "21" },
  { label: "22号", value: "22" },
  { label: "23号", value: "23" },
  { label: "24号", value: "24" },
  { label: "25号", value: "25" },
  { label: "26号", value: "26" },
  { label: "27号", value: "27" },
  { label: "28号", value: "28" }
];


const CreateLabel = () => {
  const items = [
    {
      label_value_id: String(new Date().valueOf()) + "02", //生成
      id: String(new Date().valueOf()) + "02",
      operate_label_id: String(new Date().valueOf()) + "02",
      is_copy: false, // 此值用于标签值名称是以文本方式还是输入框方式展示
      label_open: true,
      label_value: "标签值",
      action_bar_show: false,
      people_num: "--",
      data_sources: "create",
      rules: {
        combinator: "and",
        rules: [
          {
            rules_value: "规则组",
            action_bar_show: false,
            rules_value_id: String(new Date().valueOf()) + "03",
            combinator: "and",
            data_sources: "create",
            is_copy: false, // 此值用于规则组名称是以文本方式还是输入框方式展示
            rules: [
              {
                rules_Label_value: null,
                rules_operate_id: String(new Date().valueOf()) + "04",
                table_name: "",
                field_type: "",
                label_id: null,
                field: null,
                field_id: null,
                field_code: "",
                min: "",
                max: "",
                time: null,
                time_value: null,
                time_key: '1',
                is_custom: false,
                custom_value: null,
                custom_checked: null,
                timeStore: "",
                operator: null,
                operator_id: null,
                operator_value: "",
                behavior: "做过", // 是否做过
                value: "",
                value_select: "",
                value_time: null,
                month_open: false,
                statistics_type: "总次数", // 统计方式
                behavior_field_id: null,
                behavior_field_value: "",
                behavior_time_open: false,
              },
            ],
          },
        ],
      },
    },
  ];

  const navigate = useNavigate();

  let location = useLocation();
  

  const [labelId, setLabelId] = useState("");

  const [flag, setFlag] = useState(location.state.flag);
  const [permissionLevel,setPermissionLevel] = useState<number>();

  const [current, setCurrent] = useState(0);

  const [accountForm] = Form.useForm();

  interface Option {
    value: string | number;
    label: string;
    children?: Option[];
  }

  const [optionsValue, setOptionsValue] = useState([] as Option[]);

  const [paramsList, setParamsList] = useState(
    JSON.parse(JSON.stringify(items))
  );

  type FieldType = {
    labelName: string;
    categoryId: number;
    categoryIdStore: any;
    renewType: number;
    executFre: number;
    updateRateDate: any;
    // time: any;
    weekDay: string;
    monthDay: string;
    description: string;
    isFinalConfirm: boolean;
    labelRules: string;
  };

  const formData: FieldType = {
    labelName: "",
    categoryId: 0,
    categoryIdStore: [],
    renewType: 0,
    executFre: 0,
    updateRateDate: null,
    // time: "",
    weekDay: "",
    monthDay: "",
    description: "",
    isFinalConfirm: false,
    labelRules: "",
  };

  const [conditions, setConditions] = useState(
    JSON.parse(JSON.stringify(formData))
  );
  const changeConditions = (newConditions: any) => {
    if (newConditions.executFre) {
      setConditions({
        ...conditions,
        ...newConditions,
        updateRateDate: null,
        time: "",
      });
    } else {
      setConditions({
        ...conditions,
        ...newConditions,
      });
    }
  };

  // 编辑数据处理
  const handleData = (store: any) => {
    const obj = {
      labelName: location.state.editData.labelName,
      categoryId: location.state.editData.categoryId,
      categoryIdStore: store,
      renewType: location.state.editData.renewType,
      executFre: location.state.editData.executFre,
      updateRateDate:
      location.state.editData.executFre === 0
          ? null
          : location.state.editData.executFre === 1
            ? String(location.state.editData.weekDay)
            : String(location.state.editData.monthDay),
      weekDay: location.state.editData.weekDay,
      monthDay: location.state.editData.monthDay,
      description: location.state.editData.description,
      isFinalConfirm: false,
    };
    setConditions({ ...obj });

    const storeArr = JSON.parse(location.state.editData.showLabelRule).label_group;

    storeArr.forEach((val: any, idx: number) => {
      val.label_open = true;
      val.people_num = "--";
      val.rules.rules.forEach((a: any, b: number) => {
        a.rules.forEach((m: any, n: number) => {
          if(m.field_type === 'Date' || m.field_type === 'Year'){
            if(m.operator_id === 7){
              m["value_time"] = [
                dayjs(m.value[0], m.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
                dayjs(m.value[1], m.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
              ];
            } else {
              m.value_time = m.field_type === "Date" ? dayjs(m.value, "YYYY-MM-DD") : dayjs(m.value, "YYYY");
            }
          }
          if(m.rules){
            m.rules.rules.forEach((p: any, q: number) => {
              if(p.field_type === 'Date' || p.field_type === 'Year'){
                if(p.operator_id === 7){
                  p["value_time"] = [
                    dayjs(p.value[0], p.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
                    dayjs(p.value[1], p.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
                  ];
                } else {
                  p.value_time = p.field_type === "Date" ? dayjs(p.value, "YYYY-MM-DD") : dayjs(p.value, "YYYY");
                }
              }
            })
          }
        })
      })
    });

    setParamsList(storeArr);

    accountForm.setFieldValue("labelName", location.state.editData.labelName);
    accountForm.setFieldValue("renewType", location.state.editData.renewType);
    accountForm.setFieldValue("executFre", location.state.editData.executFre);
    accountForm.setFieldValue("updateRate", location.state.editData.updateRate);
    accountForm.setFieldValue("updateRateDate", location.state.editData.updateRateDate);
    accountForm.setFieldValue("description", location.state.editData.description);
  };

  // 下一步
  const nextStep = () => {
    if (!conditions.labelName.trim()) {
      setLoading(true);
      return message.warning("请输入标签名称");
    }

    if (conditions.categoryIdStore.length === 0) {
      setLoading(true);
      return message.warning("请选择标签类目");
    }

    if (conditions.executFre !== 0) {
      setLoading(true);
      if (!conditions.updateRateDate) {
        return message.warning("请选择更新日期");
      }
    }
    setCurrent(1);
  };

  // 提交
  const [loading, setLoading] = useState(true);
  const submit = async (isFinalConfirm: boolean) => {
    if (loading) {
      setLoading(false);
      if (!conditions.labelName.trim()) {
        setLoading(true);
        return message.warning("请输入标签名称");
      }
      if (conditions.labelName.length > 20) {
        setLoading(true);
        return message.warning("标签名称过长");
      }
      if (conditions.categoryIdStore.length === 0) {
        setLoading(true);
        return message.warning("请选择标签类目");
      }

      if (conditions.executFre !== 0) {
        setLoading(true);
        if (!conditions.updateRateDate) {
          return message.warning("请选择更新日期");
        }
      }

      let f: boolean = true;

      const store: any = paramsList.map((v: any, i: number) => v);
      checkFillIn: for (var i = 0; i < store.length; i++) {
        if (store[i].label_value.length > 20) {
          message.warning("标签值名称最多20个字符");
          f = false;
          break checkFillIn;
        } else {
          for (var y = 0; y < store[i].rules.rules.length; y++) {
            if (store[i].rules.rules[y].rules_value.length > 20) {
              message.warning("规则组名称最多20个字符");
              f = false;
              break checkFillIn;
            } else {
              for (var z = 0; z < store[i].rules.rules[y].rules.length; z++) {
                if (!store[i].rules.rules[y].rules[z].rules_Label_value) {
                  message.warning("请填写完整规则");
                  f = false;
                  break checkFillIn;
                }
                if (store[i].rules.rules[y].rules[z].rules_Label_value === "1") {
                  // 标签
                  if (store[i].rules.rules[y].rules[z].label_id === null) {
                    f = false;
                    message.warning("请选择标签");
                    break checkFillIn;
                  }
                  if (store[i].rules.rules[y].rules[z].operator_id === null) {
                    f = false;
                    message.warning("请选择运算条件");
                    break checkFillIn;
                  }
                  if (
                    store[i].rules.rules[y].rules[z].value === null ||
                    store[i].rules.rules[y].rules[z].value === ""
                  ) {
                    f = false;
                    message.warning("请填写/选择标签值");
                    break checkFillIn;
                  }
                }
                if (store[i].rules.rules[y].rules[z].rules_Label_value === "2") {
                  // 属性
                  if (store[i].rules.rules[y].rules[z].field_id === null) {
                    f = false;
                    message.warning("请选择客户属性");
                    break checkFillIn;
                  }
                  if(store[i].rules.rules[y].rules[z].operator_id !== 5 && 
                    store[i].rules.rules[y].rules[z].operator_id !== 6 && 
                    store[i].rules.rules[y].rules[z].operator_id !== 7
                  ){
                    if (
                      store[i].rules.rules[y].rules[z].value === null ||
                      store[i].rules.rules[y].rules[z].value === ""
                    ) {
                      f = false;
                      message.warning("请填写/选择属性值");
                      break checkFillIn;
                    }
                  }
                  if(store[i].rules.rules[y].rules[z].operator_id === 7){
                    if(store[i].rules.rules[y].rules[z].field_type === 'Date' || 
                        store[i].rules.rules[y].rules[z].field_type === 'Year' || 
                        store[i].rules.rules[y].rules[z].field_type === 'Month'
                    ){
                      if(store[i].rules.rules[y].rules[z].value === '' ||
                      store[i].rules.rules[y].rules[z].value === null || 
                      store[i].rules.rules[y].rules[z].value.length === 0
                      ){
                        f = false;
                        message.warning("请选择属性值区间");
                        break checkFillIn;
                      }
                    } else {
                      if(
                        store[i].rules.rules[y].rules[z].max === '' || 
                        store[i].rules.rules[y].rules[z].max === null || 
                        store[i].rules.rules[y].rules[z].min === '' ||
                        store[i].rules.rules[y].rules[z].min === null
                      ){
                        f = false;
                        message.warning("请填写属性值区间");
                        break checkFillIn;
                      } else {
                        if(Number(store[i].rules.rules[y].rules[z].max) < Number(store[i].rules.rules[y].rules[z].min)){
                          f = false;
                          message.warning("属性值区间请按照从小到大的顺序填写");
                          break checkFillIn;
                        }
                      }
                    }
                  }
                }
                if (store[i].rules.rules[y].rules[z].rules_Label_value === "3") {
                  // 行为
                  if (store[i].rules.rules[y].rules[z].behavior_field_id === null) {
                    f = false;
                    message.warning("请选择客户行为");
                    break checkFillIn;
                  }

                  if (
                    store[i].rules.rules[y].rules[z].behavior === "做过" &&
                    store[i].rules.rules[y].rules[z].statistics_type === "总次数"
                  ) {
                    if (store[i].rules.rules[y].rules[z].operator_id === null) {
                      f = false;
                      message.warning("请选择运算条件");
                      break checkFillIn;
                    }
                    if (
                      store[i].rules.rules[y].rules[z].value === null ||
                      store[i].rules.rules[y].rules[z].value === ""
                    ) {
                      f = false;
                      message.warning("请填写/选择用户行为次数值");
                      break checkFillIn;
                    }
                    if (store[i].rules.rules[y].rules[z].operator_id === 7) {
                      // 区间
                      if (
                        store[i].rules.rules[y].rules[z].max === "" ||
                        store[i].rules.rules[y].rules[z].min === ""
                      ) {
                        f = false;
                        message.warning("请填写用户行为次数值");
                        break checkFillIn;
                      } else {
                        if (Number(store[i].rules.rules[y].rules[z].max) < Number(store[i].rules.rules[y].rules[z].min)) {
                          f = false;
                          message.warning("行为次数区间请按照从小到大的顺序填写");
                          break checkFillIn;
                        }
                      }
                    }
                  }

                  if (store[i].rules.rules[y].rules[z].rules) {
                    for (
                      var s = 0;
                      s < store[i].rules.rules[y].rules[z].rules.rules.length;
                      s++
                    ) {
                      if (
                        store[i].rules.rules[y].rules[z].rules.rules[s].field_id ===
                        null
                      ) {
                        f = false;
                        message.warning("请选择事件属性");
                        break checkFillIn;
                      }
                      if(store[i].rules.rules[y].rules[z].rules.rules[s].operator_id !== 5 && 
                        store[i].rules.rules[y].rules[z].rules.rules[s].operator_id !== 6 &&
                        store[i].rules.rules[y].rules[z].rules.rules[s].operator_id !== 7
                      ){
                        if (
                          store[i].rules.rules[y].rules[z].rules.rules[s].value ===
                          null ||
                          store[i].rules.rules[y].rules[z].rules.rules[s].value === ""
                        ) {
                          f = false;
                          message.warning("请选择/填写用户行为值");
                          break checkFillIn;
                        }
                      }
                      if(store[i].rules.rules[y].rules[z].rules.rules[s].operator_id === 7){
                        if(store[i].rules.rules[y].rules[z].rules.rules[s].field_type === 'Date' || 
                          store[i].rules.rules[y].rules[z].rules.rules[s].field_type === 'Year' || 
                          store[i].rules.rules[y].rules[z].rules.rules[s].field_type === 'Month'
                        ){
                          if(store[i].rules.rules[y].rules[z].rules.rules[s].value === '' ||
                            store[i].rules.rules[y].rules[z].rules.rules[s].value === null || 
                            store[i].rules.rules[y].rules[z].rules.rules[s].value.length === 0
                          ){
                            f = false;
                            message.warning("请选择事件属性值区间");
                            break checkFillIn;
                          }
                        } else {
                          if(
                            store[i].rules.rules[y].rules[z].rules.rules[s].max === "" || 
                            store[i].rules.rules[y].rules[z].rules.rules[s].max === null ||
                            store[i].rules.rules[y].rules[z].rules.rules[s].min === "" ||
                            store[i].rules.rules[y].rules[z].rules.rules[s].min === null
                          ){
                            f = false;
                            message.warning("请填写事件属性值区间");
                            break checkFillIn;
                          } else {
                            if(Number(store[i].rules.rules[y].rules[z].rules.rules[s].max) < Number(store[i].rules.rules[y].rules[z].rules.rules[s].min)){
                              f = false;
                              message.warning("事件属性值区间请按照从小到大的顺序填写");
                              break checkFillIn;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      const label_group_params: any = [];
      store.forEach((v: any, i: number) => {
        // 最外层
        const obj = {} as any;
        obj.label_value_id = v.label_value_id;
        obj.label_value = v.label_value.replace(/</g, '\\u003C').replace(/>/g, '\\u003E');
        obj.rules = {};
        obj.rules.combinator = v.rules.combinator;
        obj.rules.rules = [];
        v.rules.rules.forEach((p: any, q: number) => {
          // 规则组
          const obj_child: any = {
            rules_value: p.rules_value.replace(/</g, '\\u003C').replace(/>/g, '\\u003E'),
            combinator: p.combinator,
            rule: []
          };
          p.rules.forEach((m: any, n: number) => {
            // 规则item
            const obj_child_child: any = {
              rules_Label_value: m.rules_Label_value,
              table_name: m.table_name,
              field: m.field,
              field_type: m.field_type === 'Date' || m.field_type === 'Long' || m.field_type === 'Decimal' || m.field_type === 'Label' || m.field_type === 'Crowd' ? m.field_type : 'String',   
              label_id: m.label_id,
              time: m.time,
              operator: m.operator,
              behavior: m.behavior,
              value: m.value,
              statistics_type: m.statistics_type,
            }
            if (m.rules) {
              obj_child_child.rules = {};
              obj_child_child.rules.combinator = m.rules.combinator;
              obj_child_child.rules.rules = [];
              m.rules.rules.forEach((x: any, y: number) => {
                const obj_child_child_child: any = {
                  table_name: x.table_name,
                  field_type: x.field_type === 'Date' || x.field_type === 'Long' || x.field_type === 'Decimal' || x.field_type === 'Label' || x.field_type === 'Crowd' ? x.field_type : 'String',
                  field: x.field,
                  func: x.func_value.length > 1 ? x.func_value[1] : '',
                  operator: x.operator,
                  value: x.value
                }
                obj_child_child.rules.rules.push(obj_child_child_child);
              })
            }
            obj_child.rule.push(obj_child_child);
          })
          obj.rules.rules.push(obj_child);
        })
        label_group_params.push(obj);
      });

      if (f) {
        const rules = {
          label_id: labelId,
          label_group: label_group_params,
        };

        const rules2 = {
          label_id: labelId,
          label_group: paramsList,
        };

        const params: any = {
          labelId: labelId,
          labelName: conditions.labelName,
          categoryId:
            conditions.categoryIdStore.length === 0
              ? location.state.catgoryIdStore[location.state.catgoryIdStore.length - 1]
              : conditions.categoryIdStore[
              conditions.categoryIdStore.length - 1
              ],
          renewType: conditions.renewType,
          executFre: conditions.executFre,
          weekDay: conditions.executFre === 1 ? conditions.updateRateDate : null,
          monthDay: conditions.executFre === 2 ? conditions.updateRateDate : null,
          description: conditions.description,
          labelRule: JSON.stringify(rules),
          createMode: "规则类标签",
          updater: state.user.userId,
          showLabelRule: JSON.stringify(rules2),
          isFinalConfirm,
        };

        let res: any = {};

        if (flag === "create") {
          params.creater = String(state.user.userId);
          res = await createLabelApi(params);
        } else {
          params.creater = location.state.editData.creater;
          res = await updateLabelApi(params);
        }
        if (res) {
          setLoading(true);
          if (res.code == 200) {
            getUserClickApi(state.childMenuId, 3, undefined, labelId, state.parentMenuId, '/tages/ruleTags/tags')
            message.success(flag === "create" ? "创建成功" : "编辑成功");
            setStateLabelId(Number(labelId));
            navigate(-1);
          } else if (res.code == 506) {
            setLoading(true);
            setCreatePromptOpen(true);
            setPromptValue(res.msg);
          }
        } else {
          setLoading(true);
        }
      } else {
        setLoading(true);
      }
    }
  };

  const hasDuplicates = (data: string[]) => {
    return new Set(data).size !== data.length;
  };

  // 添加标签值
  const addLabelValue = () => {
    const store = paramsList.map((val: any, idx: number) => val);
    const label_value_store: string[] = [];
    store.forEach((v: any, i: number) => {
      label_value_store.push(v.label_value);
    });

    if (!hasDuplicates(label_value_store)) {
      const labelValueItem = {
        label_value_id: String(new Date().valueOf()) + "02",
        id: String(new Date().valueOf()) + "02",
        operate_label_id: String(new Date().valueOf()) + "02",
        is_copy: true,
        label_open: true,
        label_value: "标签值",
        action_bar_show: false,
        people_num: "--",
        data_sources: "create",
        rules: {
          combinator: "and",
          rules: [
            {
              rules_value: "规则组",
              action_bar_show: false,
              rules_value_id: String(new Date().valueOf()) + "03",
              combinator: "and",
              data_sources: "create",
              is_copy: false,
              rules: [
                {
                  rules_Label_value: null,
                  rules_operate_id: String(new Date().valueOf()) + "04",
                  table_name: "",
                  field_type: "",
                  label_id: null,
                  field: null,
                  field_id: null,
                  field_code: "",
                  min: "",
                  max: "",
                  time: null, // 待确认
                  time_value: null,
                  time_key: '1',
                  is_custom: false,
                  custom_value: null,
                  custom_checked: null,
                  timeStore: "",
                  operator: null,
                  operator_id: null,
                  behavior: "做过",
                  value: "",
                  value_select: null,
                  value_time: null,
                  month_open: false,
                  statistics_type: "总次数", // 统计方式
                  behavior_field_id: null,
                  behavior_field_value: "",
                  behavior_time_open: false
                },
              ],
            },
          ],
        },
      };
      store.unshift(labelValueItem);
    }
    setParamsList(store);
  };

  // 获取标签类目
  const getCategoryList = async (creater?: string) => {
    setOptionsValue([]);
    const param: any = {
      creater: "",
      enable: 1
    };
    const res = await queryListApi(param);
    let store: any = [];
    const categoryList = JSON.parse(JSON.stringify(res.data));
    categoryList.map((item: any, index: number) => {
      item.label = item.categoryName;
      item.value = item.id;
      item.children = item.secondCategoryInfos || [];
      if (item.children?.length > 0) {
        item.children.map((t: any, i: number) => {
          t.label = t.categoryName;
          t.value = t.id;
          if (flag === "view" || flag === "edit") {
            if (t.id === location.state.editData.categoryId) {
              store.push(item.id);
              store.push(t.id);
            }
          }
          if (t.children?.length > 0) {
            t.children.map((m: any, n: number) => {
              m.label = m.labelName;
              m.value = m.labelId;
            });
          }
        });
      }
    });
    if (flag === "edit" || flag === "view") {
      handleData(store);
      setPermissionLevel(location.state.editData.permissionLevel)
      accountForm.setFieldValue("categoryIdStore", store);
    }
    setOptionsValue(categoryList);
  };
  // 提交弹窗提示
  const [createPromptOpen, setCreatePromptOpen] = useState(false);
  const [promptValue, setPromptValue] = useState("");
  const getCreatePromptOpenStatus = (val: boolean) => {
    setCreatePromptOpen(val);
  };
  const getConfirmOperate = () => {
    setCreatePromptOpen(false);
    submit(true);
  };

  // 查看切换编辑
  const edit = async () => {
    if (location.state.editData.calculateStatus === 1) {
      message.warning('标签计算中，稍后重试');
    } else {
      if(loading){
        setLoading(false);
        const params = {
          labelId: location.state.editData.labelId
        };
        const res = await labelCheakEditApi(params);
        if(res.data){
          setFlag('edit');
        } else {
          message.warning("该标签值已被应用，不可进行修改操作");
        };
        setLoading(true);
      }
    }
  };

  useEffect(() => {
    setParamsList(JSON.parse(JSON.stringify(items)));
    getCategoryList();
    if (flag === "edit" || flag === "view") {
      setLabelId(JSON.parse(location.state.editData.labelRule).label_id);
      // localStorage.setItem('labelData', JSON.stringify(location.state.editData))
    } else {
      setLabelId(String(new Date().valueOf()) + "01");
    }

    return () => {
      // 组件卸载时清理message
      message.destroy();
    };
  }, [])

  return (
    <div className={styles.createLabelBox}>
      <div className={styles.pageTitle}>
        <div className={styles.returnBtn} onClick={() => {navigate(-1); setStateLabelId(Number(labelId));}}><LeftOutlined style={{marginRight: 8}} />返回</div>
        {flag === "create" ? "新建标签" : flag === "edit" ? "编辑标签" : "查看标签"}
      </div>
      <div className={styles.stepsBox}>
        <Steps
          progressDot
          current={current}
          items={[
            { title: "标签基础信息设置" },
            { title: "标签规则设置" }
          ]}
        />
      </div>
      <div className={styles.labelBaseInfo} style={{marginLeft: current === 0 ? '12%' : '24px'}}>{current === 0 ? '标签基本信息' : '标签规则'}</div>
      {
        current === 0 && <div className={styles.formBox}>
          <Form layout="vertical" form={accountForm}>
            <Form.Item<FieldType>
              label="标签名称"
              name="labelName"
              rules={[{ required: true, message: "请输入标签名称" }]}
            >
              <Input
                disabled={flag === "view"}
                autoComplete="off"
                showCount
                maxLength={20}
                placeholder="请输入标签名称"
                value={conditions.labelName}
                onChange={(e) => changeConditions({ labelName: e.target.value })}
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="标签类目"
              name="categoryIdStore"
              rules={[{ required: true, message: "请选择标签类目" }]}
            >
              <Cascader
                disabled={flag === "view"}
                // value={conditions.categoryIdStore}
                defaultValue={conditions.categoryIdStore}
                placeholder="请选择标签类目"
                options={optionsValue}
                onChange={(e) => changeConditions({ categoryIdStore: e })}
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="更新类型"
              name="renewType"
              rules={[{ required: true, message: "请选择更新类型" }]}
            >
              <Select
                disabled={flag === "view"}
                placeholder="请选择"
                value={conditions.renewType}
                defaultValue={conditions.renewType}
                onChange={(e) => {
                  changeConditions({ renewType: e, executFre: 0 });
                }}
                options={[{ label: "定时更新", value: 0 }, {label: "手动更新", value: 3}]}
              />
            </Form.Item>
            {
              conditions.renewType === 0 && 
              <Form.Item<FieldType>
                label="更新频率"
                name="executFre"
                rules={[{ required: true, message: "请选择更新频率" }]}
              >
                <Radio.Group
                  disabled={flag === "view"}
                  optionType="button"
                  options={radioGroupOptions}
                  value={conditions.executFre}
                  onChange={(e) => changeConditions({ executFre: e.target.value })}
                />
                <div style={{ display: "flex", marginTop: 10 }}>
                  {conditions.executFre !== 0 && (
                    <Select
                      disabled={flag === "view"}
                      placeholder="请选择"
                      style={{ marginRight: 8, width: 200 }}
                      value={conditions.updateRateDate}
                      onChange={(e) => changeConditions({ updateRateDate: e })}
                      options={
                        conditions.executFre === 1 ? weekOptions : monthOptions
                      }
                    />
                  )}
                </div>
              </Form.Item>
            }
            <Form.Item<FieldType>
              label="标签描述"
              name="description"
              className={styles.labelDescription}
            >
              <TextArea
                disabled={flag === "view"}
                placeholder=""
                showCount
                maxLength={100}
                style={{ height: 100, resize: "none" }}
                value={conditions.description}
                onChange={(e) =>
                  changeConditions({ description: e.target.value })
                }
              />
            </Form.Item>
          </Form>
        </div>
      }
      {
        current === 1 && <div className={styles.rulesSettingBox}>
          {
            flag !== "view" && <Button
              disabled={flag === "view"}
              icon={<PlusOutlined />}
              style={{ marginBottom: 16 }}
              onClick={addLabelValue}
            >
            添加标签值
          </Button>
          }
          <div>
            <SortableList
              current={current}
              labelId={labelId}
              operateType={flag}
              paramsList={paramsList}
              parentLabelId={flag === "edit" ? location.state.editData.labelId : ""}
              onParam={(newList: any) => {
                setParamsList(newList);
              }}
              onSort={(newList: any) => setParamsList(newList)}
            />
          </div>
          <CreatePrompt
            createPromptOpen={createPromptOpen}
            promptValue={promptValue}
            getCreatePromptOpenStatus={getCreatePromptOpenStatus}
            getConfirmOperate={getConfirmOperate}
          />
        </div>
      }
      <div className={styles.buttonBox}>
        <Button style={{marginRight: 8}} onClick={() => {navigate(-1);setStateLabelId(Number(labelId));}}>取消</Button>
        {flag === "view" && checkPermissions(LABEL_KEY.UPDATE_LABEL) && permissionLevel === 2 &&<Button type="primary" onClick={edit} style={{marginRight: 8}}>编辑</Button>}
        {current === 0 && <Button type="primary" onClick={nextStep}>{flag === "view" ? '下一页' : '下一步'}</Button>}
        {current === 1 && <Button type="primary" onClick={() => setCurrent(0)} style={{marginRight: 8}}>{flag === "view" ? '上一页' : '上一步'}</Button>}
        {(flag !== "view" && current === 1) && <Button type="primary" onClick={() => submit(false)}>确定</Button>}
      </div>
    </div>
  );
};

export default CreateLabel;
