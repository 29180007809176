import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Select,
  message,
  Tooltip,
} from "antd";
import type { SelectProps } from "antd";
import {
  DeleteOutlined
} from "@ant-design/icons";
import {
  queryLabelListApi,
  profileOperatorApi,
  profileEnumsListApi,
  checkLabelApi
} from "../../../../../../api/label/label";

import { QueryLabelListVO } from "../../../../../../api/label/types";

const Label = (props: {
  labelId: string,
  current: number,
  operateType: string,
  itemData: any,
  ruleData: any,
  val: any,
  idx: number,
  parentLabelId?: string,
  getDeleteItemFunction: Function,
  getRulesChangeFunction: Function
}) => {

  const {
    labelId,
    current,
    operateType, 
    itemData,
    ruleData, 
    val, 
    idx, 
    parentLabelId,
    getDeleteItemFunction = () => {},
    getRulesChangeFunction = () => {}
  } = props;

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const [labelOptions, setLabelOptions] = useState([] as any);
  const [labelRelationshipOptions, setLabelRelationshipOptions] = useState([] as any);
  const [labelValueOptions, setLabelValueOptions] = useState([] as any);

  // 获取标签值
  const checkLabel = async (e: any) => {
    const param = {
      updateLabelId: labelId,
      quoteLabelId: e
    };
    const res = await checkLabelApi(param);
    if(res){
      if(res.code === 506){
        message.error(res.msg);
      } else {
        getRulesChangeFunction({e: e, id: val.rules_operate_id, key: "label_id", f: "p", option: []})
        getProfileOperator();
        getProfileEnumsList(e);
      }
    } 
  };

  // 获取标签值
  const getqueryLabelList = async () => {
    const param: any = {
      labelName: "",
    };
    if(operateType === "edit"){
      param.id = parentLabelId;
    }
    const { data } = await queryLabelListApi(param);
    data.forEach((v: QueryLabelListVO, i: number) => {
      v.label = v.labelName;
      v.value = v.labelId;
    });
    setLabelOptions(data);
  };

  // 获取运算符
  const getProfileOperator = async () => {
    const param = {
      fieldType: "Label",
    };
    const { data } = await profileOperatorApi(param);

    data.forEach((v: any, i: number) => {
      v.label = v.name;
      v.value = v.code;
    });

    setLabelRelationshipOptions(data);
  };

  // 获取枚举值
  const getProfileEnumsList = async (e: any) => {
    let param = {
      fieldType: "Label",
      fieldCode: e,
    };

    const { data } = await profileEnumsListApi(param);
    const arr: any = [];
    if(data){
      data.forEach((v: any, i: number) => {
        if(v.enumName.replace(/\s+/g, '')){
          const obj = {
            label: v.enumName,
            value: v.enumCode,
            enumName: v.enumName,
            enumCode: v.enumCode,
            disabled: v.isShow || String(v.isShow) === 'undefined' ? false : true // 有渠道权限的isShow会返回true,通用接口,有的没有返回值isShow
          };
          arr.push(obj);
        }
      });
    }
    setLabelValueOptions(data ? arr: []);
  };

  useEffect(() => {
    if(current === 1 ||
      operateType === "edit" || 
      operateType === "view" || 
      operateType === "copy" || 
      itemData.data_sources === "copy" || 
      ruleData.data_sources === "copy"){
      getqueryLabelList();
      if(val.label_id){
        getProfileOperator();
        getProfileEnumsList(val.label_id);
      }
    }
  }, []);

  return(
    <div style={{ display: "flex", marginLeft: 8, width: ruleData.rules.length > 1 ? "calc(100% - 8px - (100% - 24px - 10px - 16px)/4)" : "calc(100% - 8px - (100% - 24px)/4)" }}>
      <Select
        disabled={operateType === "view"}
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        placeholder="请选择标签"
        style={{ width: ruleData.rules.length > 1 ? "calc((100% - 16px - 16px - 10px)/3)" : "calc((100% - 16px)/3)" }}
        value={val.label_id} //标签是特殊项,此处填label_id
        onChange={(e) => {
          if(operateType === "edit"){
            checkLabel(e);
          } else {
            getRulesChangeFunction({e: e, id: val.rules_operate_id, key: "label_id", f: "p", option: []})
            getProfileOperator();
            getProfileEnumsList(e);
          }
        }}
        options={labelOptions}
      />
      <Select
        disabled={operateType === "view"}
        placeholder="请选择运算条件"
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        style={{ width: ruleData.rules.length > 1 ? "calc((100% - 16px - 16px - 10px)/3)" : "calc((100% - 16px)/3)", marginLeft: 8 }}
        value={val.operator_id}
        onChange={(e) => {
          getRulesChangeFunction({e: e, id: val.rules_operate_id, key: "operator_id", f: "p", option: labelRelationshipOptions})
          // rulesChange(e, val.rules_operate_id, "operator_id", "p");
        }}
        options={labelRelationshipOptions}
      />
      <Select
        disabled={operateType === "view"}
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        placeholder="请选择标签值"
        style={{ width: ruleData.rules.length > 1 ? "calc((100% - 16px - 16px - 10px)/3)" : "calc((100% - 16px)/3)", marginLeft: 8 }}
        value={val.value}
        onChange={(e) => {
          getRulesChangeFunction({e: e, id: val.rules_operate_id, key: "value", f: "p", option: []});
          // rulesChange(e, val.rules_operate_id, "value", "p")}
        }}
        options={labelValueOptions}
      />
      {ruleData.rules?.length > 1 && operateType !== "view" && (
        <div
          style={{
            marginLeft: 10,
            height: "32px",
            lineHeight: 'var(--text-font15)',
          }}
        >
          <Tooltip title="删除">
            <DeleteOutlined
              style={{
                cursor: "pointer",
                color: "var(--text-font-color4)",
                fontSize: "var(--text-font7)",
              }}
              onClick={() => getDeleteItemFunction(idx)}
            />
          </Tooltip>
        </div>
      )}
    </div>
  )};

export default Label;