import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
// import type { Dayjs } from 'dayjs';
import { PlusOutlined, LeftOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message
} from "antd";
import { useNavigate, useLocation } from 'react-router-dom'
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  editCrowdApi
} from "../../../../api/label/label";
import { createCrowdQuery } from "../../../../api/label/types";
import { GroupListVO } from "../../../../api/userGroup/type";
import { state } from "../../../../store";
import styles from "./index.module.scss";
import { CROWD_KEY } from '../../../../router/Permissions';
import { checkPermissions } from '@/utils/utils';
import { getUserClickApi } from '../../../../api/home/home'

const { TextArea } = Input;

// type: 1-营销创建；2-规则创建；3-RFM创建

const CreateUserGroupingChild = () => {

  const navigate = useNavigate();
  const [permissionLevel, setPermissionLevel] = useState<number>();
  

  let location = useLocation();

  const [flag, setFlag] = useState(location.state.flag);

  const [crowdId, setCrowdId] = useState("");

  const [accountForm] = Form.useForm();

  // 查看状态
  type FieldType = {
    crowdName: string;
    crowdDescribe: string;
  };

  const formData: FieldType = {
    crowdName: "",
    crowdDescribe: "",
  };

  const [conditions, setConditions] = useState(
    JSON.parse(JSON.stringify(formData))
  );
  const changeConditions = (newConditions: any) => {
    setConditions({
      ...conditions,
      ...newConditions,
    });
  };

  // 编辑数据处理
  const handleData = () => {
    const obj = {
      crowdName: location.state.editData.crowdName,
      crowdDescribe: location.state.editData.crowdDescribe,
    };
    setConditions(obj);

    accountForm.setFieldValue("crowdName", location.state.editData.crowdName);
    accountForm.setFieldValue("crowdDescribe", location.state.editData.crowdDescribe);
    setPermissionLevel(location.state.editData.operatePermission)
  };

  // 提交
  const [loading, setLoading] = useState(true);
  const submit = async (isFinalConfirm: boolean) => {
    if (loading) {
      setLoading(false);
      if (!conditions.crowdName.trim()) {
        setLoading(true);
        return message.warning("请输入分群名称");
      }
      const params: any = {
        id: location.state.editData.id,
        crowdName: conditions.crowdName,
        crowdDescribe: conditions.crowdDescribe,
      };

      const res = await editCrowdApi(params);
      getUserClickApi(state.childMenuId, 3, location.state.editData.id, undefined, state.parentMenuId, state.currentPath)

      if (res) {
        setLoading(true);
        if(res.code == 200){
          message.success("编辑成功");
        }
        navigate(-1);
      } else {
        setLoading(true);
      }
    }
  };

  const formatNumber = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    if (flag === "edit" || flag === "view" || flag === "copy") {
      handleData();
      if (flag === "edit" || flag === "view") {
        setCrowdId(location.state.editData.crowdId);
      } else {
        setCrowdId(String(new Date().valueOf()) + "02");
      }
    } else {
      setCrowdId(String(new Date().valueOf()) + "02");
    }
  }, [])

  return (
    <div className={styles.createUserGroupingBox}>
      <div className={styles.pageTitle}>
        <div className={styles.returnBtn} onClick={() => navigate(-1)}><LeftOutlined style={{marginRight: 8}}/>返回</div>
        {flag === "create" ? "新建分群" : flag === "view" ? "查看分群" : flag === "copy" ? "复制分群" : "编辑分群"}
      </div>
      <div className={styles.labelBaseInfo} style={{marginLeft: '12%'}}>分群基本信息</div>
      <div className={styles.formBox}>
        <Form name="basic" form={accountForm} labelCol={{ span: 3 }}>
          <Form.Item<FieldType>
            label="分群名称"
            name="crowdName"
            rules={[{ required: true, message: "请输入分群名称" }]}
          >
            <Input
              disabled={flag === "view"}
              autoComplete="off"
              showCount
              maxLength={50}
              placeholder="请输入分群名称"
              value={conditions.crowdName}
              onChange={(e) => changeConditions({ crowdName: e.target.value })}
            />
          </Form.Item>
          <Form.Item<FieldType> 
            label="描述" 
            name="crowdDescribe"
            className={styles.labelDescription}
          >
            <TextArea
              disabled={flag === "view"}
              placeholder="请输入描述"
              showCount
              maxLength={100}
              value={conditions.crowdDescribe}
              style={{ height: 100, resize: "none" }}
              onChange={(e) =>
                changeConditions({ crowdDescribe: e.target.value })
              }
            />
          </Form.Item>
          {
            location.state.editData.type === 3 && 
            <Form.Item<FieldType> 
              label="创建方式" 
              className={styles.widthStyle}
            >
            <span style={{color: "var(--text-font-color4)"}}>RFM创建</span>
          </Form.Item>
          }
          {
            location.state.editData.type === 3 && 
            <Form.Item<FieldType> 
              label="分群人数" 
              className={styles.widthStyle}
            >
            <div><span style={{color: "var(--text-font-color7)"}}>{formatNumber(location.state.editData.groupNum)}</span>人</div>
          </Form.Item>
          }
        </Form>
      </div>
      <div className={styles.buttonBox}>
        <Button style={{marginRight: 8}} onClick={() => navigate(-1)}>取消</Button>
        {flag === "view" && checkPermissions(CROWD_KEY.UPDATA) && permissionLevel === 2 && <Button type="primary" onClick={() => setFlag("edit")} style={{marginRight: 8}}>编辑</Button>}
        {flag !== "view" && <Button type="primary" onClick={() => submit(false)}>确定</Button>}
      </div>
    </div>
  );
};

export default CreateUserGroupingChild;
